import axios from "../config.js/axios";

export default class setting {

    static listSetting(data) {
        return axios.get('setting/list', { params: data });
    }
    static detailSetting(slug) {
        return axios.get('setting/details/' + slug);
    }
}















