import { Col, Typography, Row, Collapse, Layout, Breadcrumb } from "antd"
import { PlusCircleOutlined, MinusCircleFilled } from '@ant-design/icons'
import serviceFaq from "../service/faq"
import { useEffect, useState } from "react"
import { Header } from "antd/es/layout/layout"
import { Link } from "react-router-dom"


const Questions = () => {
    const [data, setData] = useState([])

    const faqData = () => {
        serviceFaq.listFaq().then((res) => {

            setData(res.data.map((x) => {
                return {
                    title: x.title,
                    description: x.description
                }
            }))
        })
    }

    useEffect(() => {
        faqData()
    }, [])

    return <>
    <Row style={{ padding: "20px" }}>
                <Breadcrumb style={{ marginLeft: "20px" }}>
                    <Breadcrumb.Item>
                        <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
                            Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={"/questions"} style={{ fontSize: "500", fontWeight: "bolder" }}>
                            FAQ
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
        <Row>
            <Col xs={{ span: 0 }} sm={{ span: 24 }}>
                <div style={{ height: "180px", background: "transparent url('https://img.freepik.com/free-photo/gray-abstract-wireframe-technology-background_53876-101941.jpg" }}>
                    <Typography.Title style={{ padding: "34px", fontSize: "67px", color: "#6c4d98" }}>Frequently asked questions</Typography.Title>
                </div>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 0 }} >
                <Layout>
                    <Header style={{
                        color: '#391085',
                        fontSize: "22px",
                        fontWeight: "700",
                        height: 70,
                        paddingInline: 20,
                        lineHeight: '70px',
                        background: "transparent url('https://img.freepik.com/free-photo/gray-abstract-wireframe-technology-background_53876-101941.jpg",
                    }}>
                        Frequently Asked Questions
                    </Header>
                </Layout>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ marginBottom: 30 }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{
                            fontSize: "24px",
                            padding: '12px',
                            marginTop:'20px',
                            fontWeight:'bold'
                        }}>
                        Have Questions ? We're here to help
                         </span>
                    </div>
            </Col>
            <Col xs={{ span: 0 }} sm={{ span: 24 }} >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography.Title style={{ padding: "34px", fontSize: "54px" }}>Have Questions ? We're here to help</Typography.Title>
                </div>
            </Col>

            <Col  xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: "10px"}}  >

                <Collapse expandIconPosition="end" ghost size="small" expandIcon={({ isActive }) => isActive ? <MinusCircleFilled style={{ fontSize: "25px", color: "#6C4D98", alignSelf: "baseline",marginTop:'10px' }} /> : <PlusCircleOutlined style={{ fontSize: "25px", color: "#6C4D98", alignSelf: "baseline",marginTop:'10px' }} />}>
                    {data?.map((item, i) => (
                        <Collapse.Panel
                            key={i}
                            style={{ borderBottom: "1px solid #A2A2A2" }}
                            header={<h1 style={{ fontSize: "20px", }} extra={() => <PlusCircleOutlined />} >{item.title}</h1>}
                        >
                            <p style={{ color: "#A2A2A2", fontSize: "18px", marginTop: "-30px" }}>{item.description}</p>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Col>
            
            <Col  xs={{ span: 0 }} sm={{ span: 24 }} style={{ padding: "150px", paddingTop: "48px" }}  >
                <Collapse expandIconPosition="end" ghost size="large" expandIcon={({ isActive }) => isActive ? <MinusCircleFilled style={{ fontSize: "40px", color: "#6C4D98", alignSelf: "baseline" }} /> : <PlusCircleOutlined style={{ fontSize: "40px", color: "#6C4D98", alignSelf: "baseline" }} />}>
                    {data?.map((item, i) => (
                        <Collapse.Panel
                            key={i}
                            style={{ borderBottom: "solid #A2A2A2", }}
                            header={<h1 style={{ fontSize: "35px", }} extra={() => <PlusCircleOutlined />} >{item.title}</h1>}
                        >
                            <p style={{ color: "#A2A2A2", fontSize: "35px", marginTop: "-30px" }}>{item.description}</p>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Col>




        </Row>



    </>
}



export default Questions