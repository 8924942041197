/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/ProductCard";
import productService from "../../service/product";
import { message, Row, Col, Radio, Spin, Divider } from "antd";
import Offer from "./Offer";
import Nodata from "../../components/Nodata";
import Pagination from "../../components/Pagination";

export default function List() {
    const allSlugs = useParams();
    const [data, setData] = useState([]);
    const [ajaxRequesting, setAjaxRequesting] = useState(false);
    const [qData, setQData] = useState({ page: 1, limit: 20, sortBy: 'new-product' });

    const getList = function (qData) {
        setAjaxRequesting(true);
        productService.list(qData).then(res => {
            setData(res.data || []);
            setQData({ ...qData, limit: res.extra.limit, page: res.extra.page, total: res.extra.total });
        }).catch(err => {
            message.warning('Error in data loading! Try again!!');
        }).finally(() => {
            setAjaxRequesting(false);
        })
    }

    useEffect(() => {
        const newAllSlugs = Object.values(allSlugs);
        if (Object.keys(newAllSlugs).length) {
            getList({...qData, type: newAllSlugs[0], categorySlug: newAllSlugs.length > 1 ? newAllSlugs[newAllSlugs.length - 1] : null});
        }
    }, [qData.page, qData.limit, qData.sortBy, allSlugs]);

    // useEffect(() => {
    //     setQData({ ...qData, page: 1 })
    // }, [qData.filter]);

    return (
        <>
            <Row style={{ marginTop: 20 }}>
                {/* <Col style={{ padding: 0 }} xs={{ span: 0 }} sm={{ span: 24 }}> */}
                {/* <Row> */}
                <Col xs={{ span: 0 }} sm={{ span: 6 }}>
                    <Offer />
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 18 }}>
                    <Spin tip="Loading" size="large" spinning={ajaxRequesting}>
                        <div>
                            <Radio.Group value={qData.sortBy} buttonStyle="solid" size="large" onChange={e => { setQData({ ...qData, sortBy: e.target.value }) }}>
                                <Radio.Button value="new-product">New Product</Radio.Button>
                                {/* <Radio.Button value="most-popular">Most Popular</Radio.Button> */}
                                <Radio.Button value="price-l-h">Price Low to High</Radio.Button>
                                <Radio.Button value="price-h-l">Price High to Low</Radio.Button>
                            </Radio.Group>
                        </div>

                        {
                            data.length
                                ?
                                <Row >
                                    {data.map(v => (
                                        <>
                                            <Col xs={{ span: 0 }} sm={{ span: 8 }} key={v._id}> <ProductCard {...{ ...v, allSlugs }} /></Col>
                                        </>
                                    ))}
                                    <Col span={24}>
                                        <Divider />
                                        <Pagination {...{ qData, setQData }} />
                                        <Divider />
                                    </Col>
                                </Row>
                                : <Nodata />
                        }
                    </Spin>

                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <Spin tip="Loading" size="large" spinning={ajaxRequesting}>
                        {
                            data.length
                                ? <Row gutter={[20, 20]} style={{ marginLeft: 0, marginRight: 0 }} justify="space-between">
                                    <Col span={24}>
                                        <Radio.Group value={qData.sortBy} buttonStyle="solid" size="small" onChange={e => { setQData({ ...qData, sortBy: e.target.value }) }}>
                                            <Radio.Button style={{ fontSize: '12px' }} value="new-product">New Product</Radio.Button>
                                            {/* <Radio.Button style={{ fontSize: '12px' }} value="most-popular">Most Popular</Radio.Button> */}
                                            <Radio.Button style={{ fontSize: '12px' }} value="price-l-h">Price Low to High</Radio.Button>
                                            <Radio.Button style={{ fontSize: '12px' }} value="price-h-l">Price High to Low</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                    {data.map(v => (
                                        <>
                                            {/* <Col key={v._id}> <ProductCard {...{ ...v, allSlugs }} /></Col> */}
                                            <Col xs={{ span: 12 }} sm={{ span: 0 }} key={v._id}> <ProductCard {...{ ...v, allSlugs }} /></Col>
                                        </>
                                    ))}
                                    <Col span={24}>
                                        <Divider />
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <Pagination {...{ qData, setQData }} />
                                        </div>
                                        <Divider />
                                    </Col>
                                </Row>
                                : <Nodata />
                        }
                    </Spin>

                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 0 }}>
                    <Offer />
                </Col>
                {/* </Col> */}

            </Row>
        </>
    );
}