import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { Col, Row, Spin } from "antd";
import { useParams } from "react-router-dom";
import offerService from "../../service/offer"
import Nodata from "../../components/Nodata";

const OfferProducts = () => {
    const { slug } = useParams()
    const [ajxRequesting, setAxRequesting] = useState(false)
    const [data, setData] = useState([]);

    const getOfferProduct = () => {
        setAxRequesting(true)
        offerService.offerProduct(slug).then(res => {
            setData(res.data)
        }).catch(err => {
        }).finally(() => {
            setAxRequesting(false)
        })
    }

    useEffect(() => {
        getOfferProduct()
    }, [slug])

    return (
        <Row style={{ marginTop: 20 }}>
            <Col xs={{span:0}} sm={{span:24}}>
                <Spin tip="Loading" size="large" spinning={ajxRequesting}>
                    {
                        data.length
                            ?
                            <Row
							gutter={[20, 20]}
							style={{ marginLeft: 0, marginRight: 0 }}
							justify="space-evenly"
						>
                                {data.map(v => <Col span={6} key={v._id}> <ProductCard {...{ ...v }} /></Col>)}

                            </Row>
                            : <Nodata />
                    }
                </Spin>

            </Col>

            {/* mobile */}
            <Col xs={{span:24}} sm={{span:0}}>
                <Spin tip="Loading" size="large" spinning={ajxRequesting}>
                    {
                        data.length
                            ?
                            <Row
							gutter={[20, 20]}
							style={{ marginLeft: 0, marginRight: 0 }}
							justify="space-evenly"
						>
                                {data.map(v => <Col span={12} key={v._id}> <ProductCard {...{ ...v }} /></Col>)}

                            </Row>
                            : <Nodata />
                    }
                </Spin>

            </Col>
        </Row>
    )
};

export default OfferProducts;
