import axios from "../config.js/axios";
export default class service {

    static baseURL = 'coupon';

    static list(data) {
        return axios.get(this.baseURL + '/list', { params: data });
    }

    static details(id) {
        return axios.get(this.baseURL + '/details/' + id);
    }
    static validateCoupon(data) {
        return axios.post(this.baseURL + '/validate-coupon', data);
    }
}