import Banner from "./Banner";
import Blog from "./Blog";
import ProductList from "./Product";

export default function Home() {

    return (
        <>
            <Banner />
            <ProductList />
            <Blog />
        </>
    );
}