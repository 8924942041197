import { Button, Modal, Result } from 'antd';
import { Link } from 'react-router-dom';

export default function App() {

    return (
        <>
            <Modal title={null} open={true} footer={null} >
                <Result
                    status="success"
                    title="Order Placed Successfully"
                    extra={[
                        <Link to="/">
                            <Button type="primary" key="console" >
                                Back to Home Page
                            </Button>
                        </Link>
                    ]}
                />
            </Modal>
        </>
    );
};