import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import userService from "../../service/user";
// import Cookies from "js-cookie";

const firebaseConfig = {
    apiKey: "AIzaSyD8H5U29bxrAKpa6-A3-V9Zskeiph7DXLg",
    authDomain: "amplieto-df412.firebaseapp.com",
    projectId: "amplieto-df412",
    storageBucket: "amplieto-df412.appspot.com",
    messagingSenderId: "1064520127737",
    appId: "1:1064520127737:web:e7d4fca6743d23a217fe28",
	measurementId: "G-BD1VQM0REM"
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export async function signInWithGoogle() {
	try {
		const result = await signInWithPopup(auth, googleProvider);
		const data = {
			email: result.user.reloadUserInfo.email,
			name: result.user.reloadUserInfo.displayName,
			// avatar: result.user.reloadUserInfo.photoUrl,
		}

		userService.googleLogin(data).then(({ data }) => {
			// Cookies.set("auth_token", data.accessToken);
			// console.log(data, "Token");
            window.localStorage.setItem('authorization', data.accessToken);
			window.location.reload();
		}).catch(err=> {
			window.alert(err.message);
		})
	} catch (error) {
	}
};
