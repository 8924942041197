import axios from "../config.js/axios";

export default class home {

    static listBlog(data) {
        return axios.get('blog/list', { params: data });
    }
    static detailBlog(slug) {
        return axios.get('blog/details/' + slug);
    }
}