import { Row, Col, Image, Input, Button, Modal, Drawer, message } from "antd";
import Logo from "../../Assets/AMPLIETO-SVG-logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SearchOutlined, MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import User from "./User";
import Menu from "./Menu";


export default function TopHeader() {
    const location = useLocation();
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    useEffect(()=> {
        window.document.documentElement.scrollTop = 0;
    }, [location]);

    return (
        <>
            <Row justify="space-around" align="middle" >
                <>
                    <Col
                        xs={{ span: 0 }}
                        sm={{ span: 4 }}
                    >
                        <Link to="/">
                            <Image src={Logo}   style={{marginTop:'20px'}}  alt="amit-labs-logo" width="240px" height='80px' preview={false} />
                        </Link>
                    </Col>
                    <Col style={{marginLeft:"230px"}}
                        xs={{ span: 0 }}
                        sm={{ span: 10 }}
                    >
                        <SearchBox />
                    </Col>
                    <Col
                        xs={{ span: 0 }}
                        sm={{ span: 4 }}
                    >
                        <User />
                    </Col>
                </>
                <>
                    <Col
                        xs={{ span: 12 }}
                        sm={{ span: 0 }}
                    >
                        <Link to="/">
                            <Image src={Logo} alt="amit-labs-logo" width="150px"  height='50px' preview={false} />
                        </Link>
                    </Col>
                    <Col
                        xs={{ span: 2 }}
                        sm={{ span: 0 }}
                    >
                        <Button type="primary" icon={<SearchOutlined />} onClick={() => { setShowMobileSearch(true) }} />
                    </Col>
                    <Col
                        xs={{ span: 6 }}
                        sm={{ span: 0 }}
                    >
                        <User />
                    </Col>
                    <Col
                        xs={{ span: 2 }}
                        sm={{ span: 0 }}
                    >
                        <Button type="primary" icon={<MenuOutlined />} onClick={() => { setShowMenu(true) }} />
                        <MenuModal {...{ showMenu, setShowMenu }} />
                    </Col>
                </>
            </Row>
            <SearchModal {...{ showMobileSearch, setShowMobileSearch }} />
        </>
    );
};

function SearchModal({ showMobileSearch, setShowMobileSearch }) {
    return (
        <>
            <Modal closeIcon={<></>} open={showMobileSearch} footer={null} maskClosable={true} onCancel={() => { setShowMobileSearch(false) }}>
                <SearchBox />
            </Modal>
        </>
    );
}

function SearchBox() {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const handleSearch = (e)=>{
      
        if(e){

            navigate("/search/" + e);
            setSearch("")
        }
        else{
            message.error("Enter Product Name.")
        }
    }
    const handleChange =(e)=>{
        setSearch(e)
    }

    return <Input.Search placeholder="Search your Product" value={search} enterButton className="app-gradient" onChange={e=>{handleChange(e.target.value)}} onSearch={e => { handleSearch(e) }} />

}

function MenuModal({ showMenu, setShowMenu }) {
    return (
        <>
            <Drawer open={showMenu} footer={null} closable={true} onClose={() => { setShowMenu(false) }} width={300}>
                <Menu />
            </Drawer>
        </>
    );
}