import { Link } from "react-router-dom";
import { Row, Col, Typography, Button, Input, notification, Image } from "antd";
import { useState } from "react";
import { InstagramOutlined, FacebookOutlined, LinkedinOutlined, EnvironmentOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import service from "../service/setting";
import serviceSubscribe from "../service/subscribe";
import footerImage from "../Assets/Gem logo.svg"
import twitterImage from "../Assets/icons8-twitter.svg"


export default function Footer() {

    const [subscribeData, setSubscribeData] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const fetchMedia = () => {
        service.listSetting().then((res) => {
            setData({
                phone: res?.data?.filter(v => v.type === 'phone')?.map(v => v.data),
                address: res?.data?.filter(v => v.type === 'address')?.map(v => v.data),
                email: res?.data?.filter(v => v.type === 'email')?.map(v => v.data),
                instagramUrl: res?.data?.filter(v => v.type === 'instagramUrl')?.map(v => v.data),
                facebookUrl: res?.data?.filter(v => v.type === 'facebookUrl')?.map(v => v.data),
                linkeinUrl: res?.data?.filter(v => v.type === 'linkeinUrl')?.map(v => v.data),
                twitterUrl: res?.data?.filter(v => v.type === 'twitterUrl')?.map(v => v.data)
            });
        })
    }

    const subscribe = () => {

        setLoading(true)
        let obj = {
            email: subscribeData
        }
        serviceSubscribe.save(obj).then((res) => {
            notification.success({
                message: res.message
            })
            setSubscribeData("")
        }).catch(err => {

            notification.error({
                message: err.errors[0].msg,
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchMedia()
    }, [])

    return (

        <Row>
            <Col xs={{ span: 0 }} sm={{ span: 24 }}>

                <div>
                    <footer className='footer-wrapper'>

                        <Row justify="space-around" gutter={[20, 20]} style={{ paddingBottom: 20 }}>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 5 }}
                            >
                                <Typography.Title level={2}>Support</Typography.Title>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/customersupport"}>Customer Support</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/questions"}>FAQ</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/contact-us"}>Contact Us</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"info/certification"}>Certification</Link>
                                </Typography.Paragraph>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 5 }}
                            >
                                <Typography.Title level={2}>Company</Typography.Title>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/aboutus"}>About Us</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/newsblog"}> Blogs</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/careers"}>Careers</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/gallery"}>Gallery</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/feedback"}>Feedback</Link>
                                </Typography.Paragraph>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 5 }}
                                style={{ borderRight: " solid #dfd8d8", marginRight: "67px" }}
                            >
                                <Typography.Title level={2}>Social Media</Typography.Title>
                                <Typography.Paragraph level={2}>
                                    {
                                        data?.facebookUrl?.[0]
                                            ? <Link to={data?.facebookUrl?.[0]} target={"_blank"}>
                                                <FacebookOutlined style={{ fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                    {
                                        data?.instagramUrl?.[0]
                                            ? <Link to={data?.instagramUrl?.[0]} target={"_blank"}  >
                                                <InstagramOutlined style={{ fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                    {
                                        data?.linkeinUrl?.[0]
                                            ? <Link to={data?.linkeinUrl?.[0]} target={"_blank"}>
                                                <LinkedinOutlined style={{ fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                    {
                                        data?.twitterUrl?.[0]
                                            ? <Link to={data?.twitterUrl?.[0]} target={"_blank"}>
                                                <Image preview={false} src={twitterImage} width={41} height={41} style={{ marginTop:"-15px", fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                </Typography.Paragraph>

                                <Row gutter={[12, 12]}>
                                    <Col span={18}>
                                        <div style={{ marginBottom: "0px", fontSize: "20px", fontWeight: "480" }}> Subscribe Our News Letter</div>
                                    </Col>
                                    <Col span={18} >
                                        <Input size="large" value={subscribeData} type={"email"} onChange={(e) => setSubscribeData(e.target.value)} style={{ width: "282px" }} />

                                    </Col>
                                    <Col span={18}>
                                        <Button type="primary" block size="large" loading={loading} onClick={subscribe} style={{ width: "282px" }}>Subscribe</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 5 }}
                                style={{ marginRight: "88px" }}
                            >
                                <h2>Registered Office Address</h2>
                                {
                                    data?.address?.length
                                        ? <>
                                            {
                                                data?.address?.map((v, i) => (
                                                    <Row style={{ marginTop: "32px" }} key={i}>
                                                        <Col span={3}>
                                                            <EnvironmentOutlined style={{ fontSize: "21px" }} />
                                                        </Col>
                                                        <Col span={21} style={{ fontSize: "15px", fontWeight: "600" }}>{v}</Col>
                                                    </Row>
                                                ))
                                            }
                                        </>
                                        : null
                                }
                                {
                                    data?.phone?.length
                                        ? <>
                                            {
                                                data.phone?.map((v, i) => (
                                                    <Row style={{ marginTop: "25px" }} key={i}>
                                                        <Col span={3}>
                                                            <PhoneOutlined style={{ fontSize: "21px" }} />
                                                        </Col>
                                                        <Col span={21} style={{ fontSize: "15px", fontWeight: "600" }}>
                                                            {v}
                                                        </Col>
                                                    </Row>
                                                ))
                                            }
                                        </>
                                        : null
                                }
                                {
                                    data?.email?.length
                                        ? <>
                                            {
                                                data.email.map((v, i) => (
                                                    <Row style={{ marginTop: "25px" }} key={i}>
                                                        <Col span={3}>
                                                            <MailOutlined style={{ fontSize: "21px" }} />
                                                        </Col>
                                                        <Col span={21} style={{ fontSize: "15px", fontWeight: "600" }} >{v}</Col>
                                                    </Row>
                                                ))
                                            }
                                        </>
                                        : null
                                }
                            </Col>
                        </Row>

                    </footer>
                    <Row style={{ display: "flex", textAlignLast: "center", height: "92px", alignContent: "center", background: "#E2E2E2" }} >
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}
                            sm={{ span: 5 }}>

                            <Image src={footerImage} height={90} preview={false} />

                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}
                            sm={{ span: 5 }} style={{ alignSelf: "center" }}>

                            <div style={{ fontSize: "19px", fontWeight: "500" }}>
                                <Link to={"info/certification"}> Certifications & Services </Link>
                                | <Link to={"info/termcondition"}>Terms of Use</Link> |
                                <Link to={"info/privacy-policy"}>Privacy Policy</Link>

                            </div>
                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}
                            sm={{ span: 5 }}
                            style={{ alignSelf: "center" }}>

                            <div style={{ fontSize: "19px", fontWeight: "500" }}>
                                Copyrights © 2023 | All Rights Reserved
                            </div>
                        </Col>

                    </Row>

                </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                <div>
                    <footer className='footer-wrapper'>

                        <Row justify="space-around" gutter={[70, 20]} style={{ paddingBottom: 20 }}>
                            <Col
                                xs={{ span: 12 }}
                                sm={{ span: 5 }}
                            >
                                <span style={{fontWeight:500 , fontSize:'19px',}}>Support</span>
                                <Typography.Paragraph style={{marginTop:'20px'}} level={4}>
                                    <Link to={"/customersupport"}>Customer Support</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/questions"}>FAQ</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/contact-us"}>Contact Us</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"info/certification"}>Certification</Link>
                                </Typography.Paragraph>
                            </Col>
                            <Col
                                xs={{ span: 12 }}
                                sm={{ span: 5 }}
                            >
                               <span style={{fontWeight:500 , fontSize:'19px',}}>Company</span>
                                <Typography.Paragraph style={{marginTop:'20px'}} level={4}>
                                    <Link to={"/aboutus"}>About Us</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/newsblog"}> Blogs</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/careers"}>Careers</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/gallery"}>Gallery</Link>
                                </Typography.Paragraph>
                                <Typography.Paragraph level={4}>
                                    <Link to={"/feedback"}>Feedback</Link>
                                </Typography.Paragraph>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 5 }}

                            >
                                <Typography.Title level={2}>Social Media</Typography.Title>
                                <Typography.Paragraph level={2}>
                                    {
                                        data?.facebookUrl?.[0]
                                            ? <Link to={data?.facebookUrl?.[0]} target={"_blank"}>
                                                <FacebookOutlined style={{ fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                    {
                                        data?.instagramUrl?.[0]
                                            ? <Link to={data?.instagramUrl?.[0]} target={"_blank"}  >
                                                <InstagramOutlined style={{ fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                    {
                                        data?.linkeinUrl?.[0]
                                            ? <Link to={data?.linkeinUrl?.[0]} target={"_blank"}>
                                                <LinkedinOutlined style={{ fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                    {
                                        data?.twitterUrl?.[0]
                                            ? <Link to={data?.twitterUrl?.[0]} target={"_blank"}>
                                                <Image src={twitterImage} width={41} height={41}  style={{ marginTop:"-15px", fontSize: "29px", marginRight: "6px", background: "#78539C", color: "white", borderRadius: "6px", padding: "6px" }} />
                                            </Link>
                                            : null
                                    }
                                </Typography.Paragraph>

                                <Row gutter={[12, 12]}>
                                    <Col span={18}>
                                        <div style={{ marginBottom: "0px", fontSize: "20px", fontWeight: "480" }}> Subscribe Our News Letter</div>
                                    </Col>
                                    <Col span={18} >
                                        <Input size="large" value={subscribeData} type={"email"} onChange={(e) => setSubscribeData(e.target.value)} style={{ width: "282px" }} />

                                    </Col>
                                    <Col span={18}>
                                        <Button type="primary" block size="large" loading={loading} onClick={subscribe} style={{ width: "282px" }}>Subscribe</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 5 }}
                                style={{}}
                            >
                                <h2>Registered Office Address</h2>
                                {
                                    data?.address?.length
                                        ? <>
                                            {
                                                data?.address?.map((v, i) => (
                                                    <Row style={{ marginTop: "32px", color: "gray" }} key={i}>
                                                        <Col span={3}>
                                                            <EnvironmentOutlined style={{ fontSize: "21px" }} />
                                                        </Col>
                                                        <Col span={21} style={{ fontSize: "15px", fontWeight: "600" }}>{v}</Col>
                                                    </Row>
                                                ))
                                            }
                                        </>
                                        : null
                                }
                                {
                                    data?.phone?.length
                                        ? <>
                                            {
                                                data.phone?.map((v, i) => (
                                                    <Row style={{ marginTop: "25px", color: "gray" }} key={i}>
                                                        <Col span={3}>
                                                            <PhoneOutlined style={{ fontSize: "21px" }} />
                                                        </Col>
                                                        <Col span={21} style={{ fontSize: "15px", fontWeight: "600" }}>
                                                            {v}
                                                        </Col>
                                                    </Row>
                                                ))
                                            }
                                        </>
                                        : null
                                }
                                {
                                    data?.email?.length
                                        ? <>
                                            {
                                                data.email.map((v, i) => (
                                                    <Row style={{ marginTop: "25px", color: "gray" }} key={i}>
                                                        <Col span={3}>
                                                            <MailOutlined style={{ fontSize: "21px" }} />
                                                        </Col>
                                                        <Col span={21} style={{ fontSize: "15px", fontWeight: "600" }} >{v}</Col>
                                                    </Row>
                                                ))
                                            }
                                        </>
                                        : null
                                }
                            </Col>
                        </Row>

                    </footer>
                    <Row style={{ display: "flex", textAlignLast: "center", alignContent: "center", background: "#E2E2E2" }} >
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}
                            sm={{ span: 5 }}>

                            <Image src={footerImage} preview={false} />

                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}
                            sm={{ span: 5 }} style={{ alignSelf: "center" }}>

                            <div style={{ fontSize: "14px", fontWeight: "500", margin: '5px' }}>
                                <Link to={"info/certification"}> Certifications & Services </Link>
                                | <Link to={"info/termcondition"}>Terms of Use</Link> |
                                <Link to={"info/privacy-policy"}>Privacy Policy</Link>

                            </div>
                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}
                            sm={{ span: 5 }}
                            style={{ alignSelf: "center" }}>

                            <div style={{ fontSize: "14px", fontWeight: "500", margin: '6px' }}>
                                Copyrights © 2023 | All Rights Reserved
                            </div>
                        </Col>

                    </Row>

                </div>
            </Col>
        </Row>

    );
}





