import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import service from "../service/order";
import serviceSetting from "../service/setting";
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd';
import moment from "moment";

function MyPDF() {
    const [data, setData] = useState([])
    const [user, setUser] = useState({})
    const [dt, setDt] = useState([])
    const { id } = useParams();
    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()

    const fetchData = () => {
        service.detailOrder({ orderId: id })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => { })
            .finally(() => {
                // setLoading(false);
                { }
            })
    }
    const fetchUserData = () => {
        service
            .listOrder({ _id: id })
            .then((res) => {
                setUser(res.data[0]);
            })
            .catch((err) => { })
            .finally(() => {
                // setLoading(false);
                { }
            })
    }

    const companyDetail = () => {
        serviceSetting
            .listSetting()
            .then((res) => {
                setDt(res?.data)
            })
            .catch((err) => { })
            .finally(() => {
                // setLoading(false);
                { }
            })
    }

    useEffect(() => {
        fetchData()
        companyDetail()
        fetchUserData()
        // eslint-disable-next-line
    }, [])


    const downloadPDF = () => {
        setisLoading(true);
        const invoiceBox = document.querySelector('.box');

        html2canvas(invoiceBox).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4'); // Set orientation to portrait ('p') and use A4 size

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imageRatio = canvas.width / canvas.height;

            let pdfWidth = pageWidth;
            let pdfHeight = pdfWidth / imageRatio;

            // Adjust dimensions to prevent stretching
            if (pdfHeight > pageHeight) {
                pdfHeight = pageHeight;
                pdfWidth = pdfHeight * imageRatio;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('invoice.pdf');
        });

        setisLoading(false);
    };



    return (
        <>
            <div className='box' style={{ padding: "100px", borderBottom: "solid 40px #A0DAF7", paddingTop: "45px" }}>
                <img src="../1 (3).png" style={{ height: '160px', width: '100%' }} />
                <header style={{ textAlignLast: 'right', lineHeight: '10px', fontWeight: 600, width: '100%', fontSize: "20px", paddingRight: "70px" }}>
                    <h1 style={{ fontWeight: 'bolder' }}>AMPLIETO</h1>
                    <div>
                        <p>AMITT LAB EQUIPMENTS & SERVICE</p>
                        <p>111, BEST BUSINESS PARK, PLOT NO. P-2,</p>
                        <p>Netaji Subhash Place, North West Delhi, Delhi,</p>
                        <p>Delhi,110034</p>
                        <p>Delhi</p>
                        <p>India</p>
                        <p>
                            {
                                dt.map((v) => {
                                    if (v?.type === 'gstNo') {
                                        return `GST No : ${v?.data}`
                                    }
                                })
                            }
                        </p>
                    </div>
                </header>

                <hr />

                <div style={{ display: 'flex', flexDirection: 'row', lineHeight: '10px', justifyContent: "space-between" }}>
                    <div style={{ fontWeight: 'bold' }}>
                        <h1 style={{ fontWeight: 'bold' }}>Bill To:{user?.userDetails?.name || user?.userDetails?.phone}</h1>
                        <h1 style={{ fontWeight: 'bold', paddingBottom: '10px', fontSize:"20px" }}>India</h1>
                        <p>{user?.userDetails?.company}</p>
                        {/* <p>GSTIN O7AAOFA7381E1ZA</p> */}
                        <p>Address: {user?.addressDetails ? JSON.parse(user?.addressDetails).address : ""}</p>
                        <p>{user?.addressDetails ? JSON.parse(user?.addressDetails).state : ""},{user?.addressDetails ? JSON.parse(user?.addressDetails).pin : ""}</p>
                        <p>{user?.addressDetails ? JSON.parse(user?.addressDetails).city : ""}</p>
                        <p>India</p>
                        <p>Place of Supply :{user?.addressDetails ? JSON.parse(user?.addressDetails).city : ""}</p>
                    </div>

                    <div style={{ marginTop: '20px', paddingRight: "100px" }}>
                        <h1 style={{ fontWeight: 'bold',fontSize:"20px" }}>Invoice#</h1>
                        <h1 style={{ fontWeight: 'bold',fontSize:"20px", margin:"20px 0" }}>Invoice Date:{moment(user?.createdAt).format("dddd, MMMM Do YYYY")} </h1>
                        <h1 style={{ fontWeight: 'bold',fontSize:"20px" }}>Order Id:{user?.orderId}</h1>
                    </div>
                </div>

                <table id="invoice" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item Description</th>
                            <th>GST</th>
                            <th>HSN Code</th>
                            {/* <th>HSN/SAC</th> */}
                            <th>Qty</th>
                            {/* <th>Rate</th> */}

                            {/* <th>Cess</th>  */}
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((vv, i) => {
                            return <tr>
                                <td>{i + 1}</td>
                                <td>{vv?.productDetails?.name}</td>
                                <td>{vv?.productDetails.gst}%</td>
                                <td>{vv?.productDetails?.hsnCode}</td>
                                <td>{vv?.quantity}</td>
                                {/* <td>{vv?.price }</td> */}
                                {/* <td>0.00</td>
                                <td>0.00</td>
                                <td>00.00</td> */}
                                <td>₹{vv?.price}</td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <div style={{ fontWeight: 'bold', }}>It was great doing business with you.</div>

                <div style={{ width: '90%', lineHeight: '32px', fontWeight: 600, marginLeft: "100px" }}>
                    <div style={{ width: '22%', marginLeft: '76%', lineHeight: '32px', fontWeight: 600 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: '10%' }}>Sub Total</div>
                            <div>₹{user?.priceDetails?.price}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: '10%' }}>Coupon Discount</div>
                            <div>₹{user?.priceDetails?.couponDiscountPrice || 0}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: '10%' }}>Discount Amount</div>
                            <div>-₹{user?.priceDetails?.discountPrice ? user?.priceDetails?.discountPrice : 0}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: '10%' }}>GST</div>
                            <div>₹{user?.priceDetails?.gst || 0}</div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: '10%' }}>CGST ( 9%)</div>
                            <div>₹00.00</div>
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: 'solid', borderBottom: 'solid' }}>
                            <div style={{ marginRight: '10%' }}>TOTAL</div>
                            <div>₹{user?.priceDetails?.finalPrice}</div>
                        </div>
                    </div>
                </div>

                <div style={{ fontWeight: 600 }}>Terms & Conditions</div>
                <div>Please make the payment by the due date.</div>
                <footer>
                    <div style={{ borderBottom: 'solid', marginTop: '620px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '3px' }}>
                            <div style={{ fontWeight: 'bold', }}>
                                <p style={{ fontWeight: 'bolder', paddingBottom: '10px' }}>Declaration</p>
                                <p>We declare that this invoice shows the actual price of the</p>
                                <p>goods described and all goods are only for Research and</p>
                                <p>Development Purpose Only!</p>
                            </div>
                            <div>
                                <p style={{ fontWeight: 'bolder', paddingBottom: '10px' }}>Company's Bank Details</p>
                                <div style={{ fontWeight: 'bold', }}>
                                    <p>A/c Holder's Name: AMITT LAB EQUIPMENTS & SERVICE</p>
                                    <p>Bank Name : HDFC BANK LTD,</p>
                                    <p>A/c No. : 99900002121000</p>
                                    <p>Branch & IFS Code: Ansal Arcade Branch, Sector-18, Noida & HDFC0000088</p>
                                    <p>or AMITT LAB EQUIPMENTS & SERVICE</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Button onClick={() => navigate(`/orders`)}>Back</Button>
                <Button loading={isLoading} type='primary' onClick={downloadPDF} >Download PDF</Button>
            </div>
        </>

    )



}

export default MyPDF;



// <div>
//     <div class="invoice-box">
//         <table cellpadding="0" cellspacing="0">
//             <tr class="top">
//                 <td colspan="2">
//                     <table>
//                         <tr>
//                             <td class="title">
//                                 <img src={"../amplito.png"} style={{ width: "100%", maxWidth: "300px" }} />
//                             </td>
//                             <td>
//                                 OrderId: {user?.orderId}<br />
//                                 Created:{moment(user?.createdAt).format("dddd, MMMM Do YYYY")}<br />
//                             </td>
//                         </tr>
//                     </table>
//                 </td>
//             </tr>
//             <tr class="information">
//                 <td colspan="2">
//                     <table>
//                         <tr>
//                             <td>
//                                 {
//                                     dt.map((v) => {
//                                         if (v?.type === 'invoiceAddress') {
//                                             return v.data?.split(',')?.map((val) => {
//                                                 return <>{val} <br /></>
//                                             })
//                                         }
//                                     })
//                                 }
//                                 {
//                                     dt.map((v) => {
//                                         if (v?.type === 'gstNo') {
//                                             return `GST No : ${v?.data}`
//                                         }
//                                     })
//                                 }

//                             </td>
//                             <td>
//                                 {user?.userDetails?.name || ""}<br />
//                                 {user?.userDetails?.email || ""}<br />
//                                 {user?.userDetails?.phone || ""}<br />
//                                 Address: {user?.addressDetails ? JSON.parse(user?.addressDetails).landMark : ""}, {user?.addressDetails ? JSON.parse(user?.addressDetails).address : ""}  <br />
//                                 {user?.addressDetails ? JSON.parse(user?.addressDetails).city : ""}, {user?.addressDetails ? JSON.parse(user?.addressDetails).pin : ""}  <br />

//                             </td>
//                         </tr>
//                     </table>
//                 </td>
//             </tr>
//             <tr class="heading">
//                 <td>Items</td>
//                 <td>Price</td>
//             </tr>
//             {
//                 data?.map((vv) => (
//                     <tr class="item">
//                         <td>
//                             {`${vv?.productDetails?.name} x ${vv?.quantity}`}
//                         </td>
//                         <td>
//                             ₹{vv.price}
//                         </td>
//                     </tr>
//                 ))
//             }

//             <tr class="heading">
//                 <td>Price Details</td>
//                 <td>Price</td>
//             </tr>
//             <tr class="item">
//                 <td>Price </td>
//                 <td> ₹{user?.priceDetails?.price || 0}</td>
//             </tr>
//             <tr class="item">
//                 <td>Coupon Discount</td>
//                 <td>₹{user?.priceDetails?.couponDiscountPrice || 0}</td>
//             </tr>
//             <tr class="item">
//                 <td>Discount Amount</td>
//                 <td> -₹{user?.priceDetails?.discountPrice ? user?.priceDetails?.discountPrice : 0}</td>
//             </tr>
//             <tr class="total">
//                 <td></td>
//                 <td>Total: ₹{user.priceDetails?.finalPrice || 0}</td>
//             </tr>
//         </table>
//     </div>
//     <div style={{ maxWidth: '800px', margin: 'auto' }}>
//         <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
//             <Button onClick={() => navigate(`/orders`)}>Back</Button>
//             <Button loading={isLoading} type='primary' onClick={downloadPDF} >Download PDF</Button>
//         </div>
//     </div>
// </div>