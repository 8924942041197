import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import List from '../pages/List';
import ProductDetails from '../pages/ProductDetails';
import Profile from './TopHeader/Profile';
import ManageAddress from './TopHeader/ManageAddress';
import Coupon from './TopHeader/Coupon';

import NewsEvents from '../pages/NewsEvents';
import ShoppingCart from '../pages/order/ShoppingCart';
import PlaceOrder from '../pages/order/PlaceOrder';
import NewsBlog from '../pages/NewsBlog';
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/List/contactUs';
import Questions from '../pages/Questions';
import QuickOrder from '../pages/QuickOrder';
import OfferProducts from '../pages/List/OfferProducts';
import Careers from '../pages/List/Careers';
import Gallery from '../pages/List/gallery';
import SearchPage from '../pages/SearchPage';
import MyOrder from './TopHeader/MyOrder';

import CustomerSupport from '../pages/CustomerSupport';
import Wishlist from '../pages/Wishlist';
import WebInfo from '../components/WebInfo';
import { useSelector } from 'react-redux';
import Error404 from './Error404';
import Feedback from '../pages/Feedback';
import MyPDF from '../pages/MyPDF';

export default function RoutesPro() {
	const userInfo = useSelector(state => state.user);
	return (
		<>
			<Routes>
				<Route path='/' element={<Home />} />

				<Route path='/product/:slug' element={<ProductDetails />} />
				<Route path='/newsblog' element={<NewsBlog />} />
				<Route path='/questions' element={<Questions />} />
				<Route path='/c/:type?/:slug1?/:slug2?/:slug3?/:slug4?/:slug5' element={<List />} />
				<Route path='/product/:type/:slug' element={<ProductDetails />} />
				{
					userInfo.isLoggedin
						? <>
							<Route path='/profile' element={<Profile />} />
							<Route path='/address' element={<ManageAddress />} />
							<Route path='/coupon' element={<Coupon />} />
							<Route path='/shoppingcart' element={<ShoppingCart />} />
							<Route path='/placeorder' element={<PlaceOrder />} />
							<Route path='/myorder' element={<MyOrder/>} />
						</>
						: null
				}
				<Route path='/blog/:slug' element={<NewsEvents />} />
				<Route path='/offer/:slug' element={<OfferProducts />} />
				<Route path='/careers' element={<Careers />} />
				<Route path='/contact-us' element={<ContactUs />} />
				<Route path='/aboutus' element={<AboutUs />} />
				<Route path='/gallery' element={<Gallery />} />
				<Route path='/quickorder' element={<QuickOrder />} />
				<Route path='/search/:searchKey?' element={<SearchPage />} />
				<Route path='/myorder' element={<MyOrder/>} />
				<Route path='/wishlist' element={<Wishlist />} />
				<Route path='/customersupport' element={<CustomerSupport/>} />
				<Route path='info/:key' element={<WebInfo />} />
				<Route path='/feedback' element={<Feedback />} />
				<Route path='/invoice/:id' element={<MyPDF />} />
				<Route path='*' element={<Error404 />} />
			</Routes>
		</>
	);
}