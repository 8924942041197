import React, { useEffect, useState } from "react";
import service from "../service/wishlist";
import {
	message,
	Row,
	Col,
	Divider,
	Breadcrumb,
	Typography,
	Layout,
	theme,
	Card,
	Space,
	Collapse,
	Avatar,
} from "antd";
import { DeleteFilled, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import Side from "../components/Side";
import Nodata from "../components/Nodata";
import { Link } from "react-router-dom";

const { Content } = Layout;

const Wishlist = () => {
	const [ajaxRequesting, setAjaxRequesting] = useState(false);
	const [data, setData] = useState();
	const navigate = useNavigate()

	const list = () => {
		service
			.list()
			.then((res) => {
				setData(res.data)
			})
			.catch((err) => {
				message.warning("Error in data loading! Try again!!");
			})
			.finally(() => {
				setAjaxRequesting(false);
			});
	};

	useEffect(() => {
		list();
	}, []);


	const handleRemove = (data) => {
		service.remove({ ...data }).then((res) => (
			list()
		))
	}

	const {
		token: { colorBgContainer },
	} = theme.useToken();

	const handleMenu = (key) => {
		
		key ? navigate(key === '/account-setting' ? '/profile' :  key ==='mystuff' ? '/wishlist' : '/' + key) : navigate('/wishlist')
	}

	return (
		<>
			<Breadcrumb style={{ margin: '20px' }}>
				<Breadcrumb.Item>Home</Breadcrumb.Item>
				<Breadcrumb.Item>Wishlist</Breadcrumb.Item>
			</Breadcrumb>
			<Divider />

			<Row style={{ padding: '8px' }} justify="space-around">
				<Col xs={{ span: 0 }} sm={{ span: 20 }}>
					<Row gutter={[80]}>
						<Col span={8}><Side /></Col>
						<Col span={16}>
							<Card
								hoverable
								style={{
									boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
									border: "none",
									margin: "20 0",
								}}
							>


								{
									data?.length > 0
										? <Row>
											<Typography.Title
												level={2}
												style={{ marginTop: 0, marginBottom: 0, marginLeft: 10 }}
											>
												My Wishlist
											</Typography.Title>
											<Divider />
										</Row>
										: <Typography.Title level={1} style={{ marginTop: 0, marginBottom: 20, marginLeft: -100, fontSize: "xxx-large" }}>
											<Nodata text="Your wishlist is empty" />
										</Typography.Title>
								}

								{
									data?.map((val, i) => (
										<>
											<Row key={i}>
												<Col span={18}>
													<Link to={"/product/" + val.ProductDetails.slug}>
														<Row>
															<Col span={12}>
																<img
																	alt="example"
																	style={{ height: "100px", maxWidth: "100px" }}
																	src={val.ProductDetails.thumbImg?.url}
																/>
															</Col>
															<Col span={12}>
																<h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 500 }}>
																	{val.ProductDetails?.name}
																</h2>
																<span style={{ color: 'grey' }}>({val.ProductDetails.subName})</span>
																<h2 style={{ marginTop: 0, marginBottom: 0 }}>₹ {val.ProductDetails?.startingPrice}</h2>
															</Col>
														</Row>
													</Link>
												</Col>
												<Col span={2}>

													<DeleteFilled onClick={() => handleRemove({ productId: val.ProductDetails._id })} style={{ fontSize: '1.6rem', color: 'grey', marginTop: '10px', cursor: 'pointer' }} />

												</Col>
											</Row>
											<Divider />
										</>

									))
								}


							</Card>
						</Col>
					</Row>
				</Col>

				{/* mobile responsive */}
				<Col xs={{ span: 24 }} sm={{ span: 0 }}>
					<Row style={{ width: '100%', background: 'transparent linear-gradient(268deg, #3E3185 0%, #774F9A 26%, #6B2F85 74%, #393185 100%) 0% 0% no-repeat padding-box' }}>
						<Col span={6} style={{ paddingLeft: '20px', paddingTop: '10px' }}>
							<Avatar size={60} icon={<UserOutlined />} />
						</Col>
						<Col span={18}>
							<Typography.Title level={5} style={{ color: 'white', marginTop: 8 }}>Hello</Typography.Title>
							<Typography.Title level={4} style={{ color: 'white', marginTop: 8 }}>Amit Lab Equipment</Typography.Title>
						</Col>
					</Row>

					<Collapse expandIconPosition={'end'} activeKey={['/mystuff']} accordion onChange={handleMenu}>
						<Collapse.Panel
							header={<div>
								<ShoppingCartOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Order </span>
							</div>}
							key="/myorder">
							<p>hello world3</p>
						</Collapse.Panel>
						<Collapse.Panel header={<div>
							<UserOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>Account Setting </span>
						</div>} key="/account-setting">
							<Collapse bordered={false} expandIconPosition={'end'} activeKey={['/profile']} accordion onChange={(key) => {
								navigate(key ? '/' + key : '/profile')
							}}>
								<Collapse.Panel header={
									<span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Profile Information</span>
								} key="/profile">
									<p>hello</p>
								</Collapse.Panel>
								<Collapse.Panel
									header={<span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Manage Address</span>}
									key="/address">
									<p>hello world2</p>
								</Collapse.Panel>
							</Collapse>
						</Collapse.Panel>

						<Collapse.Panel header={<div>
							<ShoppingCartOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Stuff </span>
						</div>} key="/mystuff">
							<Collapse bordered={false} expandIconPosition={'end'} activeKey={['/wishlist']} accordion onChange={(key) => {
								navigate(key ? '/' + key : '/wishlist')
							}}>
								<Collapse.Panel header={
									<span style={{ marginLeft: '60px', fontSize: '16px', fontWeight: 500 }}>My Wishlist</span>
								} key="/wishlist">
									<div style={{ marginTop: '20px' }}>


										{
											data?.map((val, i) => (
												<>
													<Row key={i}>
														<Col span={22}>
															<Link to={"/product/" + val.ProductDetails.slug}>
																<Row>
																	<Col span={10}>
																		<img
																			alt="example"
																			style={{ height: "90px", maxWidth: "90px" }}
																			src={val.ProductDetails.thumbImg?.url}
																		/>
																	</Col>
																	<Col span={14}>
																		<Space direction="vertical" size={2}>
																			<span style={{ fontWeight: 500, fontSize: '18px', }}>
																				{val.ProductDetails?.name}
																			</span>
																			<span style={{ color: 'grey' }}>({val.ProductDetails.subName})</span>
																			<h2 style={{ marginTop: 0, marginBottom: 0 }}>₹ {val.ProductDetails?.startingPrice ? val.ProductDetails?.startingPrice : 0} </h2>
																		</Space>
																	</Col>

																</Row>
															</Link>
														</Col>
														<Col span={2}>

															<DeleteFilled onClick={() => handleRemove({ productId: val.ProductDetails._id })} style={{ fontSize: '1.4rem', color: 'grey', marginTop: '10px', cursor: 'pointer' }} />

														</Col>
													</Row>
													<Divider />
												</>
											))
										}
									</div>
								</Collapse.Panel>
							</Collapse>
						</Collapse.Panel>
					</Collapse>
				</Col>
			</Row>
		</>
	);
};

export default Wishlist;
