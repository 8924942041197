import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Card, Typography } from "antd";
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { TwitterShareButton } from "react-share";
const NewsCard = ({ date, img, title, content, slug }) => {
  //     const  Trimtext = (text) => {
  // if(text)
  //     }
  return (
    <Card
      hoverable
      style={{
        maxWidth: "420px",
        height: "660px",
        background: "rgb(120 68 146)",
        border: "none",
        margin: "0px 10px"
      }}
      cover={
        <Link to={"/blog/" + slug}>
          <img
            alt="example"
            style={{ width: "100%", objectFit: "cover", height: "320px", position: 'relative' }}
            src={img}
          />
        </Link>
      }
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title level={3} style={{ color: "white" }}>
          {title?.length > 50 ? <span>{title.slice(0, 50)}...</span> : <span>{title}</span>}
        </Typography.Title>
        <TwitterShareButton title={title} url={title}>
          <ShareAltOutlined
            style={{
              color: "white",
              float: "right",
              marginLeft: "60px",
              fontSize: "30px",
              textAlign: "initial",
            }}
          />
        </TwitterShareButton>
      </div>
      <div style={{ display: "flex", justifyContent: "start" }}>
        <span style={{ color: "white" }}>
          {moment(date).format("dddd, MMMM Do YYYY")}
        </span>
      </div>

      {/* {content.substring(0, 240)}... */}
      {/* {content?.length > 150 ? <span>{content.slice(0,150)}...</span> : <span>{title}</span>} */}
      {content ? (
        <div style={{ color: "white", fontWeight: "lighter" }} className="html-text-content" dangerouslySetInnerHTML={{ __html: content.split(" ").slice(0, 20).join(" ") + (content.split(" ").length > 10 ? "..." : "") }} />
      ) : (
        <div>{title}</div>
      )}



      <div style={{ display: "flex", justifyContent: "end" }}>
        <Link to={"/blog/" + slug}>
          {" "}
          <Button style={{ color: "purple", position: "absolute", bottom: "20px", right: "50px" }}>Read More</Button>
        </Link>
      </div>
    </Card>
  );
};

export default NewsCard;
