import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import wishlistService from "../service/wishlist";
import { notification } from "antd";


export const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        value: [],
        status: 'idle'
    },
    reducers: {
        addProductIds: (state, { payload }) => {
            if (!state?.value?.find(v => v === payload)) {
                state.value = [...state.value, payload];
                save({ productIds: state.value }).then(res => {
                    notification.success({ message: res.message });
                }).catch(err => {
                    notification.error({message: err.message});
                })
            }
        },
        removeProductId: (state, { payload }) => {
            state.value = [...state.value?.filter(v => v !== payload)];
            save({ productIds: state.value }).then(res => {
                notification.success({ message: res.message });
            }).catch(err => {
                notification.error({message: err.message});
            })
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchWishlist.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchWishlist.fulfilled, (state, { payload }) => {
                state.status = 'idle';
                state.value = payload || [];
            })
            .addCase(fetchWishlist.rejected, (state) => {
                state.status = 'error';
                state.value = [];
            })
    },
});

export const fetchWishlist = createAsyncThunk("wishlist/fetchWishlist", async (qData = { isAll: 1 }) => (await wishlistService.list(qData))?.data?.map(v => v?.ProductDetails?._id));

export const save = async (data, type) => {
    return wishlistService.save({ ...data, type });
};

export const { addProductIds, removeProductId } = wishlistSlice.actions;

export default wishlistSlice.reducer;