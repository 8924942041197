/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { Row, Col, Image, Space } from "antd";
import chemicalLogo from "../Assets/chemical-logo.jpg";
import LifeScience from "../Assets/life-science-logo.jpg";
import Gases from "../Assets/gas-logo.jpg";
import LabInstrument from "../Assets/lab-instrument-logo.jpg";
import Labware from "../Assets/lab-ware-logo.jpg";
import categoryImageService from "../service/category-image";
export default function ImageNav() {
    const [data, setData] = useState([])
    useEffect(() => {
        categoryImageService.list()
            .then(res => setData(res.data))
            .catch(err => { })
    }, [])

    return (
        <>
            <Row style={{ marginBottom: "15px",height:'70px' }}>
                <Col xs={{ span: 0 }} sm={{ span: 24 }}>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={12} className="image-nav" >

                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    );
}
