import { UploadOutlined } from '@ant-design/icons'
import React from 'react'
import fileService from "../service/file";
import { Upload, message } from "antd";

export default function UploadFile({ fileList, setFileList, message="Please Upload Your CV/Resume", disabled=false }) {
    const { Dragger } = Upload;
    const props = {
        name: 'file',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
        },
    };
    const uploadFile = async options => {
        const { onSuccess, onError, file, onProgress } = options;
        const fmData = new FormData();
        const config = { onUploadProgress: event => { onProgress({ percent: (event.loaded / event.total) * 100 }); } };
        fmData.append("file", file);
        try {
            fileService.save(fmData, config).then(res => {
                onSuccess("Ok");
                setFileList([...fileList, { uid: res.data.uid, url: res.data.url, name: res.data.name }]);
            })
        } catch (err) {
            onError({ err });
        }
    }
    const handleOnChange = ({ file, fl }) => {
        if (typeof fl !== typeof []) {
            fl = [];
        }
        setFileList(fl);
    };
    return <Dragger {...props} onChange={handleOnChange} customRequest={uploadFile} disabled={disabled}>
        <p className="ant-upload-drag-icon" style={{ color: "gray" }}>
            <UploadOutlined style={{color:"gray"}} />
        </p>
        <p className="ant-upload-text">{message}</p>
    </Dragger>
}
