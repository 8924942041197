import axios from "../config.js/axios";
export default class product {

    static list(data) {
        return axios.get('product/list', { params: data });
    }
    static recommandedProduct(data) {
        return axios.get('product/recommanded', {params: data});
    }
    static details(data) {
        return axios.get('../admin/ecomm/product/list', { params: data });
    }
}