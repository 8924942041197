import { createSlice } from "@reduxjs/toolkit";

export const blogSlice = createSlice({
    name: 'blog',
    initialState: {
        blogState: []
    },
    reducers: {

        startData: (state, { payload }) => {
            state.blogState = payload
        },

        like: (state, { payload }) => {
            state.blogState.push(payload)
        },
        disLike: (state, { payload }) => {
            let data = state.blogState
            for (let i = 0; i < data.length; i++) {
                if (data[i] === payload) {
                    state.blogState.splice(i, 1)
                }
            }
        }
    }
});

export const blogAction = blogSlice.actions;

export default blogSlice.reducer;