import { Button, Empty } from 'antd';
export default function Nodata({ image = Empty.PRESENTED_IMAGE_SIMPLE, text = 'Data not found' }) {
    return (
        <Empty
            image={image}
            description={
                <span>
                    {text}
                </span>
            }
        />
    );
}
