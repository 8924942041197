import { Col, Row, Carousel } from "antd";
import React, { useEffect, useState } from "react";
import blogService from '../../service/blog';
import NewsCard from "../../components/NewsCard";

export default function Blog() {
    const [data, setData] = useState([]);

    function list() {
        blogService.listBlog().then(res => {
            setData(res.data?.map(v => ({ ...v, key: v._id })));
        }).catch(err => {
        })
    }

    useEffect(() => {
        list()
    }, [])
    console.log(data,"MAhdb")

    return (

        <>
            {
                data.length > 0
                    ? <Row className="app-gradient">
                        <Col xs={{ span: 0 }} sm={{ span: 24 }} style={{ textAlign: 'center', marginTop: '20px' }}>
                            <h1 style={{ color: 'white', fontSize: "44px" }}>Blogs</h1>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ textAlign: 'center', padding: '20px' }}>
                            <h1 style={{ color: 'white', fontSize: "28px", }}>Blogs</h1>
                        </Col>
                        <Col
                            xs={{ span: 0 }}
                            sm={{ span: 24 }}
                            style={{ padding: "70px 210px 163px 163px", }}
                        >
                            <Carousel
                                autoplay={false}
                                arrows={false}
                                nextArrow={<span></span>}
                                prevArrow={<span></span>}
                                slidesToShow={3}
                                style={{ height: 700 }}
                                responsive={[]}
                                

                            >
                                {
                                    data.map((v, i) => (
                                        <NewsCard
                                            key={i}
                                            date={v?.date}
                                            img={v?.thumnailImage?.url}
                                            title={v?.title}
                                            content={v?.description}
                                            slug={v?.slug}
                                        />
                                    ))
                                }

                            </Carousel>
                        </Col>



                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 0 }}
                        >
                            <Carousel
                                dots={false}
                                autoplay={false}
                                arrows={true}
                                nextArrow={<span></span>}
                                prevArrow={<span></span>}
                                slidesToShow={1}
                                style={{ height: 720, padding: '0px 10px 0px' }}
                                responsive={[]}

                            >
                                {
                                    data.map((v, i) => (

                                        <NewsCard
                                            key={i}
                                            date={v?.date}
                                            img={v?.thumnailImage?.url}
                                            title={v?.title}
                                            content={v?.description}
                                            slug={v?.slug}
                                        />
                                    ))
                                }
                            </Carousel>
                        </Col>
                    </Row>
                    : null
            }

        </>
    )
};