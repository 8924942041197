import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import { Breadcrumb, Divider, Typography, Card, Row, Col, Input, Button, Modal, Form, Select, message, Tag, Dropdown, Spin, Collapse } from 'antd';
import { UserOutlined, ShoppingCartOutlined, MoreOutlined } from "@ant-design/icons";

import { Layout, theme } from "antd";
import Side from "../../components/Side";
import service from "../../service/user"
import { useNavigate } from "react-router-dom";

const { Content } = Layout;


export default function ManageAddress() {
    const [address, setAddress] = useState([]);
    const [ajaxRequesting, setAjaxRequesting] = useState(false);
    const addressModelRef = useRef();
    const navigate = useNavigate()

    const list = () => {
        setAjaxRequesting(true);
        service.listAddress().then(res => {
            setAddress(res.data)
        }).finally(() => {
            setAjaxRequesting(false);
        })
    }
    useEffect(() => {
        list();
    }, [])

    const { token: { colorBgContainer } } = theme.useToken();

    const handleMenu = (key) => {
        // navigate(key ? '/'+ key : '/address' ) 
        console.log("thois is key",key)
        key ? navigate(key === '/account-setting' ? '/address' :key === '/mystuff' ? '/wishlist': '/' + key) : navigate('/address')
    }

    return (
        <>
            <Breadcrumb style={{ margin: "20px" }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Profile</Breadcrumb.Item>
            </Breadcrumb>
            <Divider />

            <Row style={{ padding: '8px' }} justify="space-around">
                <Col xs={{ span: 0 }} sm={{ span: 20 }}>
                    <Row gutter={[80]}>
                        <Col span={8}><Side /></Col>

                        <Col span={16}>
                            <Typography.Title level={2} style={{ marginTop: 0, marginBottom: 20, marginLeft: 10 }}>Manage Address</Typography.Title>
                            <Row gutter={[0, 30]}>
                                <Col onClick={() => addressModelRef.current.openForm()} style={{ border: '1px solid lightgrey', padding: '20px', cursor: 'pointer' }} span={16}>
                                    <span style={{ color: 'purple', fontWeight: 500, fontSize: '20px', paddingLeft: '15px' }}>+ Add New Address</span>
                                </Col>
                                <Col span={16}>
                                    <Spin spinning={ajaxRequesting}>
                                        {
                                            address.map((val, i) => (
                                                <Card key={i} hoverable>
                                                    <Row justify="space-between" >
                                                        <Col>
                                                            <Tag>{val.tag?.toUpperCase()}</Tag>
                                                        </Col>
                                                        <Col>
                                                            <Dropdown
                                                                menu={{
                                                                    items: [
                                                                        {
                                                                            label: "Edit",
                                                                            key: 'edit',
                                                                            onClick: () => { addressModelRef.current.openForm(val) }
                                                                        },
                                                                        {
                                                                            label: "Delete",
                                                                            key: 'delete',
                                                                            onClick: () => { service.deleteAddress(val._id).then(res => { list() }) }
                                                                        }
                                                                    ]
                                                                }}
                                                            >
                                                                <Button type="text" icon={<MoreOutlined />} style={{ padding: 0 }} />
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Typography.Title level={4} style={{ marginTop: 20, marginBottom: 20, marginLeft: 0, color: "black" }}><b>{val?.name}  +91 {val?.phone} </b></Typography.Title>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 20, marginLeft: 0, color: "gray" }}>111, 1st Floor, {val.address},{val.city} ,{val.state}- {val.pin}</Typography.Title>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            ))
                                        }
                                    </Spin>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                </Col>

                {/* mobile view  */}
                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <Collapse expandIconPosition={'end'} activeKey={['/account-setting']} accordion onChange={handleMenu}>
                        <Collapse.Panel
                            header={<div>
                                <ShoppingCartOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Order </span>
                            </div>}
                            key="/myorder">
                            <p>hello world3</p>
                        </Collapse.Panel>
                        <Collapse.Panel header={<div>
                            <UserOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>Account Setting </span>
                        </div>} key="/account-setting">
                            <Collapse bordered={false} expandIconPosition={'end'} activeKey={['/address']} accordion onChange={(key) => {
                                navigate(key ? '/' + key : '/address')
                            }}>
                                <Collapse.Panel header={
                                    <span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Profile Information</span>
                                } key="/profile">
                                    <p>hello world2</p>
                                </Collapse.Panel>
                                <Collapse.Panel
                                    header={<span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Manage Address</span>} key="/address">
                                    <Row>
                                        <Col span={24} onClick={() => addressModelRef.current.openForm()} style={{ padding: '5px',marginBottom:'20px', border: '1px solid lightgrey', textAlign: 'center', cursor: 'pointer' }}>
                                            <span style={{ color: 'purple', fontWeight: 500, fontSize: '16px' }}>+ Add New Address</span>
                                        </Col>
                                        <Col span={24}>
                                            <Spin spinning={ajaxRequesting}>
                                                {
                                                    address.map((val, i) => (
                                                        <Card key={i} hoverable bodyStyle={{padding:15}} style={{marginBottom:'20px'}}>
                                                            <Row justify="space-between" >
                                                                <Col>
                                                                    <Tag>{val.tag?.toUpperCase()}</Tag>
                                                                </Col>
                                                                <Col>
                                                                    <Dropdown
                                                                        menu={{
                                                                            items: [
                                                                                {
                                                                                    label: "Edit",
                                                                                    key: 'edit',
                                                                                    onClick: () => { addressModelRef.current.openForm(val) }
                                                                                },
                                                                                {
                                                                                    label: "Delete",
                                                                                    key: 'delete',
                                                                                    onClick: () => { service.deleteAddress(val._id).then(res => { list() }) }
                                                                                }
                                                                            ]
                                                                        }}
                                                                    >
                                                                        <Button type="text" icon={<MoreOutlined />} style={{ padding: 0 }} />
                                                                    </Dropdown>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Typography.Title level={5} style={{ marginTop: 20, marginBottom: 10, marginLeft: 0, color: "black" }}><b>{val?.name}  +91 {val?.phone} </b></Typography.Title>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <span style={{ color: "gray",fontWeight:500 }}>111, 1st Floor, {val.address},{val.city} ,{val.state}- {val.pin}</span>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    ))
                                                }
                                            </Spin>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                                <Collapse.Panel header={<div>
                            <UserOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Stuff </span>
                        </div>} key="/wishlist">
                            <Collapse bordered={false} expandIconPosition={'end'} accordion onChange={(key) => {
                                navigate( '/wishlist')
                            }}>
                                <Collapse.Panel header={
                                    <span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>My Wishlist</span>
                                } key="/wishlist">
                                    <p>hello world2</p>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>

                    </Collapse>
                </Col>
            </Row>
            <AddressModal ref={addressModelRef} {...{ list }} />
        </>
    )
}

const AddressModal = forwardRef((props, ref) => {
    const { list } = props;

    const [showAddressModal, setShowAddressModal] = useState(false);
    const [data, setData] = useState({});


    useImperativeHandle(ref, () => ({
        openForm(dt) {
            setShowAddressModal(true);
            setData(
                dt
                    ? { ...dt }
                    : {}
            );

        }
    }));

    const handleChange = (value) => {
        setData({ ...data, ...value });
    }
    const handleSave = () => {
        service.saveAddress(data).then((res) => {
            message.info(res.message);
            setData({})
            list();
            setShowAddressModal(false);
        }).catch(err => {
            if (typeof err.message === 'object') {
                let dt = err.message[Object.keys(err.message)[0]];
                message.error(dt);
            } else {
                message.error(err.message);
            }
        })
    }
    return (

        <Modal open={showAddressModal} width={700} onCancel={() => setShowAddressModal(false)}
            title={data._id ? 'Edit Address' : 'Add Address'}
            footer={[
                <Button key="cancel" onClick={() => { setShowAddressModal(false); }}>Cancel</Button>,
                <Button key="save" type="primary" onClick={handleSave} >Save</Button>,
            ]}
            closeIcon={<></>}
        >
            <Col span={24}>
                <Form layout="vertical">
                    <Row gutter={[12, 2]}>

                        <Col span={12}>
                            <Form.Item label="Name" required>
                                <Input placeholder='Name' value={data.name} onChange={e => { handleChange({ name: e.target.value }) }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Phone" required>
                                <Input placeholder='Phone' value={data.phone} onChange={e => { handleChange({ phone: e.target.value }) }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="City" required>
                                <Input placeholder='City' value={data.city} onChange={e => { handleChange({ city: e.target.value }) }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="State" required>
                                <Input placeholder='State' value={data.state} onChange={e => { handleChange({ state: e.target.value }) }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="PinCode" required>
                                <Input placeholder='Pincode' value={data.pin} onChange={e => { handleChange({ pin: e.target.value }) }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Tag" required>
                                <Select value={data.tag} onChange={e => { handleChange({ tag: e }) }} >
                                    <Select.Option value={'home'}>Home</Select.Option>
                                    <Select.Option value={'office'}>Office</Select.Option>
                                    <Select.Option value={'other'}>Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Address" required>
                                <Input.TextArea placeholder="Address" value={data.address} onChange={e => { handleChange({ address: e.target.value }) }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Modal>
    )
});