import axios from "../config.js/axios";

export default class wishlist {

    static save(data) {
        return axios.post('wishlist/save', data);
    }
    static remove(data) {
        return axios.post('wishlist/remove', data);
    }
    static list() {
        return axios.get('wishlist/list');
    }
    static wishlistProVarient() {
        return axios.get('wishlist/list-wishlistProdvarient');
    }
}