import React, { useEffect, useState } from "react";
import { Carousel, Row, Col, Image, Card } from "antd";
import homeService from "../../service/home";
import { Link } from "react-router-dom";

export default function HomePage() {
  const [data, setData] = useState([]);
  const list = () => {
    homeService.listProduct({ isAll: 1 }).then((res) => {
      setData(
        res.data?.map((v) => ({
          productDetails: v.productDetails,
          leftOfferDetails: v.leftOfferDetails,
          rightOfferDetails: v.rightOfferDetails,
        }))
      );
    });
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <>
      {data?.map((v) => (
        <Row justify1="space-between" style={{ marginTop: 20 }} key={v._id}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Row justify="space-around">
              {v?.leftOfferDetails ? (
                <Col>
                  {/* <Link to={"/offer/" + v?.leftOfferDetails.slug}> */}
                    <Image
                      preview={false}
                      src={
                        v?.leftOfferDetails?.img ||
                        "https://images.unsplash.com/photo-1595500382155-e7cd8180c301"
                      }
                      alt=""
                      style={{ width: "300px", height: "300px" }}
                    />
                  {/* </Link> */}
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col
            xs={{ span: 0 }}
            sm={{ span: 24 }}
            md={{ span: 14 }}
            lg={{ span: 14 }}
          >
            <Carousel
              autoplay
              arrows
              nextArrow={<span></span>}
              prevArrow={<span></span>}
              slidesToShow={3}
              className="home-page-carasoul"
              responsive={[]}
            >
              {v?.productDetails?.map((vv) => (
                <Link to={"product/" + vv.slug} key={vv._id}>
                  <Card bodyStyle={{ padding: 0 }} bordered={false}>
                    <Image
                      preview={false}
                      src={vv.thumbImgUrl}
                      alt="img"
                      style={{ width: "300px", height: "300px" }}
                    />
                    <h3 style={{ padding: 0, marginTop: 0 }}>{vv.name}</h3>
                  </Card>
                </Link>
              ))}
            </Carousel>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 0 }}
            md={{ span: 0 }}
            lg={{ span: 0 }}
          >
            <Carousel
              autoplay
              arrows={false}
              nextArrow={<span></span>}
              prevArrow={<span></span>}
              slidesToShow={1}
              className="home-page-carasoul"
              style={{ marginTop: 29 }}
              responsive={[]}
            >
              {v?.productDetails?.map((vv) => (

                  <Link to={"product/" + vv.slug} key={vv._id}>
                    <Card bodyStyle={{ padding: 0 }} bordered={false}>
                      <Image
                        preview={false}
                        src={
                      vv?.thumbImgUrl ||
                      "https://www.pacificfoodmachinery.com.au/media/catalog/product/placeholder/default/no-product-image-400x400_6.png"
                    }
                        alt=""
                        style={{ width: "300px", height: "300px" }}
                      />
                      <h3 style={{ padding: 0, marginTop: 0 }}>{vv.name}</h3>
                    </Card>
                  </Link>
              ))}
            </Carousel>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Row justify="space-around">
              {v?.rightOfferDetails ? (
                <Col>
                  <Link to={"/offer/" + v?.rightOfferDetails?.slug}>
                    <Image
                      preview={false}
                      src={
                        v?.rightOfferDetails?.img ||
                        "https://images.unsplash.com/photo-1595500382155-e7cd8180c301"
                      }
                      alt=""
                      style={{ width: "300px", height: "300px" }}
                    />
                  </Link>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      ))}
    </>
  );
}
