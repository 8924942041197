import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Col, Divider, Input, Layout, Row, Space, message } from "antd";
import { Header } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import customerService from '../service/customerSupport'
import home from "../service/application";

const CustomerSupport = () => {

    const [data, setData] = useState({__html: 'Order created.'})
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [msg, setMsg] = useState("");

    const handleSave = () => {
        let obj = {
            name, email, phone, company, message: msg
        }
        customerService.save(obj).then((res => {
            message.info("Application Submitted Successfully !!")
            setName("")
            setEmail("")
            setPhone("")
            setMsg("")
            setCompany("")
        })).catch(err => {
            if (typeof err.message === 'object') {
                let dt = err.message[Object.keys(err.message)[0]];
                message.info(dt);
            } else {
                message.info(err.message);
            }
        })
    }

    useEffect(() => {
      const getTitle = ()=>{
        home.list("customer-support")
        .then(res => setData({__html: res.data}))
        .catch(err => message.error(err.message))
      }

      getTitle();
    }, [])
    

    return <>
        <Row>
            <Breadcrumb style={{ padding: '15px' }}>
                <Breadcrumb.Item>
                    <Link to={"/"} style={{ fontSize: "500" }}>
                        Home
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={"/customersupport"} style={{ fontSize: "500" }}>
                        Customer Support
                    </Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Divider style={{ marginTop: 0 }} />
            <Col xs={{ span: 0 }} sm={{ span: 24 }}>

                <Col span={12} style={{ display: 'flex', margin: 'auto' }} >

                    <div style={{ textAlign: 'center' }}>
                        <span style={{
                            fontSize: "40px",
                            fontWeight: 500,
                        }}><span style={{color:"#6C4D98", fontWeight:"700"}}>We Have </span>Of Ways Of Customer Support </span>
                        <div dangerouslySetInnerHTML={data}/>
                        <Input placeholder='Name*' value={name} style={{ marginBottom: '15px',marginTop:'30px' }} size='large' required onChange={(e) => setName(e.target.value)} />

                        <Input placeholder='Email Address*' style={{ marginBottom: '15px' }} type="email" value={email} size='large' required onChange={(e) => setEmail(e.target.value)} />

                        <Input placeholder='Contact*' size='large' style={{ marginBottom: '15px' }} type="number" value={phone} required onChange={(e) => setPhone(e.target.value)} />

                        <Input placeholder='Company*' size='large' style={{ marginBottom: '15px' }} value={company} required onChange={(e) => setCompany(e.target.value)} />

                        <Input.TextArea placeholder='Message*' style={{ marginBottom: '15px' }} value={msg} size='large' required onChange={(e) => setMsg(e.target.value)} />

                        <Button onClick={handleSave} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "45px", width: "100%", marginBottom: 50 }}>Submit</Button>
                    </div>
                </Col>
                <Col style={{ padding: '20px' }} span={12}>


                </Col>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                <Layout>
                    <Header style={{
                        color: '#391085',
                        fontSize: "22px",
                        fontWeight: "700",
                        height: 70,
                        paddingInline: 20,
                        lineHeight: '70px',
                        backgroundColor: '#f0f0f0',
                    }}>
                        Customer Support
                    </Header>
                </Layout>
                <Col span={24} style={{ padding: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{
                            fontSize: "22px",
                            fontWeight: 500,
                            textAlign: 'center'
                        }}>We Have Of Ways Of Customer Support </span>
                    </div>

                </Col>
                <Col style={{ padding: '20px' }} span={24}>

                    <Input placeholder='Name*' value={name} style={{ marginBottom: '15px' }} size='large' required onChange={(e) => setName(e.target.value)} />

                    <Input placeholder='Email Address*' style={{ marginBottom: '15px' }} type="email" value={email} size='large' required onChange={(e) => setEmail(e.target.value)} />

                    <Input placeholder='Contact*' size='large' style={{ marginBottom: '15px' }} type="number" value={phone} required onChange={(e) => setPhone(e.target.value)} />

                    <Input placeholder='Company*' size='large' style={{ marginBottom: '15px' }} value={company} required onChange={(e) => setCompany(e.target.value)} />

                    <Input.TextArea placeholder='Message*' style={{ marginBottom: '15px' }} value={msg} size='large' required onChange={(e) => setMsg(e.target.value)} />

                    <Button onClick={handleSave} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "38px", width: "100%", marginBottom: 50 }}>Submit</Button>
                </Col>
            </Col>
        </Row>
    </>;
};

export default CustomerSupport;
