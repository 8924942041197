import React, { useState, useEffect } from "react";
import { Button, Col, Divider, Input, Row, Typography, Modal, message, Checkbox, Space } from "antd";
import Search from "antd/es/input/Search";
import jobServices from "../../service/job";
import service from "../../service/career";
import { useNavigate } from "react-router-dom";
import UploadFile from "../../components/UploadFile";
const Careers = () => {
    const [data, setData] = useState("")
    const [qdata, setQdata] = useState("")
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [address, setAddress] = useState("");
    const [mess, setMess] = useState("");
    const [check, setCheck] = useState(false);
    const [ids, setIds] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [careerData, setCareerData] = useState({})
    const navigate = useNavigate()

    const [job, setJob] = useState([])

    const sendDetail = () => {
        let obj = {
            jobId: ids,
            name: name,
            email: email,
            phone: number,
            message: mess,
            cv: fileList?.[0]
        }
        // navigate("/")

        jobServices.apply(obj).then(res => {
            message.success(res.message)
            setName("")
            setNumber("")
            setEmail("")
            setAddress("")
            setMess("")
            setCheck(false)
            setFileList([])
            handleCancel1()
        }).catch(err => {
            if (typeof err.message === 'object') {
                let dt = err.message[Object.keys(err.message)[0]];
                message.info(dt);
            } else {
                message.info(err.message);
            }
        })

    }

    const getCareer = () => {
        service.list()
            .then(res => {
                setCareerData(res.data?.[0]);
            })
    }
    useEffect(() => {
    }, [fileList]);

    const getDetails = (id) => {
        jobServices.details(id).then(res => {
            setData(res.data)
            setIds(res.data._id)
        }).catch(err => { })
    }

    const list = (dt) => {
        jobServices.list(dt).then(res => {
            setJob(res.data)
        })
    }

    useEffect(() => {
        list({ isAll: 1 });
        getDetails()
    }, [ids]);

    useEffect(() => {
        getCareer();
    }, [])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const showModal = (id) => {
        setIds(id)
        setIsModalOpen(true);
        getDetails(id)
    };
    const showModal1 = () => {
        setIsModalOpen1(true);
    };

    const handleCancel1 = () => {
        setIsModalOpen1(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleSearchData = (val) => {
        if (val.length != 0) {
            setQdata(val)
        } else {
            list({ isAll: 1 });
        }
    };

    const handleSearch = () => {
        list({ key: qdata })
    }

    return (
        <div>
            <Modal open={isModalOpen1} width={500} onCancel={handleCancel1} footer={null}>
                <Col xs={{ span: 0 }} sm={{ span: 24 }} style={{ textAlign: 'center' }}>
                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0, color: "black" }}><b>APPLY FOR JOB</b></Typography.Title>
                    <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0, color: "#463185" }}><b>Profile Position</b></Typography.Title>
                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 50, color: "black" }}>At Amit Lab Equipment</Typography.Title>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ textAlign: 'center' }}>
                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0, color: "black" }}><b>APPLY FOR JOB Profile Position</b></Typography.Title>
                    <Typography.Title level={5} style={{ marginTop: 0, color: "grey", fontWeight: 300, fontSize: '14px' }}>At Amit Lab Equipment</Typography.Title>
                    <Divider style={{ background: 'grey', width: '100%', marginTop: 10 }} />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <Space direction='vertical' size={20}>

                        <Input placeholder='Name of the applicant*' value={name} size='large' required onChange={(e) => setName(e.target.value)} />

                        <Input placeholder='Email Address*' value={email} size='large' required onChange={(e) => setEmail(e.target.value)} />

                        <Input placeholder='Contact*' size='large' value={number} required onChange={(e) => setNumber(e.target.value)} />

                        <Input placeholder='Address*' size='large' value={address} required onChange={(e) => setNumber(e.target.value)} />

                        <Input.TextArea placeholder='Message*' value={mess} size='large' required onChange={(e) => setMess(e.target.value)} />

                        <UploadFile {...{ fileList, setFileList, count: 1 }} value={fileList} />

                        <Checkbox style={{ marginTop: 30 }}  > I consent to receiving from the Company e-mail information about new job offers, our events, trainings and other ongoing activities.</Checkbox>

                        <Typography.Title level={5} style={{ marginTop: 20, marginBottom: 10, marginLeft: 25, color: "black" }}>If you are unable to submit your details, then please share your recently updated resume at amplieto@gmail.com</Typography.Title>

                        <Button onClick={sendDetail} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "38px", width: "100%", marginBottom: 50 }}>Submit</Button>
                    </Space>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 24 }}>

                    <Input placeholder='Your Name*' value={name} size='larger' required style={{ border: 0, borderBottom: 1, borderBottomStyle: "solid", background: "white", borderBlockColor: "#f5f5f5" }} onChange={(e) => setName(e.target.value)} />

                    <Input placeholder='Your Email Address*' value={email} size='larger' required style={{ border: 0, borderBottom: 1, borderBottomStyle: "solid", background: "white", borderBlockColor: "#f5f5f5" }} onChange={(e) => setEmail(e.target.value)} />

                    <Input placeholder='Phone*' size='larger' value={number} required style={{ border: 0, borderBottom: 1, borderBottomStyle: "solid", background: "white", borderBlockColor: "#f5f5f5" }} onChange={(e) => setNumber(e.target.value)} />

                    <Input placeholder='Message*' value={mess} size='larger' required style={{ border: 0, borderBottom: 1, borderBottomStyle: "solid", background: "white", marginBottom: 50, borderBlockColor: "#f5f5f5" }} onChange={(e) => setMess(e.target.value)} />

                    <UploadFile {...{ fileList, setFileList, count: 1 }} value={fileList} />

                    <Checkbox style={{ marginTop: 30 }} checked={check} onChange={(e) => setCheck(e.target.checked)} >I consent to receiving from the Company e-mail information about new job offers, our events, trainings and other ongoing activities.</Checkbox>

                    <Typography.Title level={5} style={{ marginTop: 20, marginBottom: 10, marginLeft: 25, color: "black" }}>If you are unable to submit your details, then please share your recently updated resume at amplieto@gmail.com</Typography.Title>

                    <Button onClick={sendDetail} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "50px", width: "100px", marginTop: 50, marginBottom: 50 }}>Submit</Button>
                </Col>
            </Modal>
            <Modal open={isModalOpen} width={1000} onCancel={handleCancel} footer={null}>
                <Row>
                    <Col xs={{ span: 0 }} sm={{ span: 12 }}>
                        <Typography.Title level={3} style={{ marginTop: 50, marginBottom: 0, color: "gray" }}><b>{data.name}</b></Typography.Title>
                        <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0, color: "gray" }}>{data.location}</Typography.Title>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                        <Typography.Title level={4} style={{ marginTop: 30, marginBottom: 0, }}><b>{data.name}</b></Typography.Title>
                        <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>Location : {data.location}</Typography.Title>
                    </Col>

                    <Col xs={{ span: 0 }} sm={{ span: 12 }}>
                        <Button onClick={() => showModal1(ids)} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "50px", width: "100px", marginLeft: 260, marginTop: 50 }}>Apply</Button>
                    </Col>
                </Row>
                <Col xs={{ span: 0 }} sm={{ span: 24 }}>
                    <p style={{ color: "#595959", fontSize: '16px', }}><b>{data.description}</b></p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <p style={{ color: "#595959", fontSize: '14px', }}>{data.description}</p>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 24 }}>
                    <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 25, color: "purple" }}><b>SKILL REQUIRED :-</b> {data.skill}</Typography.Title>

                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0, color: "gray" }}>Location: {data.location}</Typography.Title>
                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 40, color: "gray" }}>Experience: {data.experience}</Typography.Title>

                    <Button onClick={() => showModal1(ids)} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "50px", width: "100px", marginBottom: 80 }}>Apply</Button>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 20 }}><b>SKILL REQUIRED :</b> {data.skill}</Typography.Title>

                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0, }}>Location: {data.location}</Typography.Title>
                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 30 }}>Experience: {data.experience}</Typography.Title>

                    <Button onClick={() => showModal1(ids)} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "40px", width: "100%", marginBottom: 10 }}>Apply</Button>
                </Col>

            </Modal>
            {/* <Typography.Title level={3} style={{ marginTop: 0, marginLeft: 1600, marginBottom: 50 }}>Search Your Job Role</Typography.Title> */}
            <Row xs={{ span: 24 }} sm={{ span: 0 }} style={{ marginTop: 50 }}>
                <Col xs={{ span: 24 }} sm={{ span: 0 }} >
                    <img style={{ width: '100%' }} src={careerData?.image?.url} alt="Lab Instrument" />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ paddingLeft: '30px', paddingRight: '30px', paddingTop: '30px' }} >
                    {
                        careerData?.data?.map(x => (
                            <>
                                <p style={{ fontSize: 30, marginTop: -5, fontWeight: 'bold',paddingTop: '50px' }}>{x?.title}</p>
                                <span style={{ fontSize: 15, fontWeight: 500 }}>{x?.description}</span>
                            </>
                        ))
                    }

                </Col>

            </Row>
            <Row xs={{ span: 0 }} sm={{ span: 24 }} style={{ marginTop: 50 }}>

                <Col xs={{ span: 0 }} sm={{ span: 12}} style={{paddingLeft: '30px' }}>
                    {
                        careerData?.data?.map(x => (
                            <>
                                <p style={{ fontSize: 30, marginTop: -5, fontWeight: 'bold',paddingTop: '50px' }}>{x?.title}</p>
                                <span style={{ fontSize: 15, fontWeight: 500 }}>{x?.description}</span>
                            </>
                        ))
                    }
                </Col>
                <Col style={{ padding: 20 }} xs={{ span: 0 }} sm={{ span: 12 }}>
                    <img src={careerData?.image?.url} alt="Lab Instrument" style={{ height: "600px", width: "100%" }} />
                </Col>
            </Row>


            <Row >
                <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: '30px' }}>
                    <span style={{ fontSize: '20px', fontWeight: 500 }}>Search Your Job Role</span>
                    <Divider style={{ marginTop: '10px' }} />
                    <Input placeholder="Search Your Job Role" size='large' onChange={(e) => handleSearchData(e.target.value)} enterButton="Search" style={{ background: "#e8e8e8", border: '0px' }} />
                    <Button style={{ width: '100%', marginTop: '18px' }} size='large' type="primary" onClick={() => handleSearch()} >
                        Search
                    </Button>
                    {/* <div>
                        <Typography style={{ color: 'gray', fontFamily: 'sans-serif', fontSize: '18px', paddingTop: '20px', }}>Current Opening</Typography>
                    </div> */}
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 24 }} style={{ paddingLeft: '100px', paddingRight: '100px' }}>
                    <Typography.Title level={2} style={{ marginBottom: 0, marginTop: 50 }}>Search Your Job Role</Typography.Title>
                    <Divider />
                    <Search onChange={(e) => handleSearchData(e.target.value)} onSearch={() => handleSearch()} placeholder="Search Your Job Role" size='large' enterButton="Search" style={{ width: "100%", background: "grey" }} />
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    {
                        job?.map((val, i) => (
                            <>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                                    <Space size={0} direction="vertical">
                                        {/* <Col span={8}> */}
                                        <span style={{ marginTop: 0, marginBottom: '0px', color: "gray" }}>Profile : {val.name}</span>
                                        {/* </Col>
                            <Col span={8}> */}
                                        <span style={{ marginTop: '-10px', marginBottom: 0, color: "gray" }}>Location : {val.location}</span>
                                        {/* </Col> */}
                                    </Space>
                                    <Button onClick={() => showModal(val._id)} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", }}>Apply</Button>
                                </div>
                                <Divider />

                            </>
                        ))}
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 24 }} >
                    {
                        job?.map((val, i) => (
                            <div key={i} style={{ paddingLeft: '100px', paddingRight: '100px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>

                                    <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 0, color: "gray", flex: 1 }}>{val.name}</Typography.Title>


                                    <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 0, marginRight: -50, color: "gray", flex: 1 }}>{val.location}</Typography.Title>


                                    <Button onClick={() => showModal(val._id)} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", height: "50px", width: "100px", }}>Apply</Button>

                                </div>
                                <Divider style={{ paddingLeft: '100px', paddingRight: '100px' }} />
                            </div>
                        ))}
                </Col>
            </Row>


        </div>
    )
};

export default Careers;