import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import cartService from "../service/cart";
export const cartSlice = createSlice({
	name: "cart",
	initialState: {
		status: 'idle',
		value: [],
	},
	reducers: {
		increment: (state, { payload }) => {
			if (state?.value?.find((v) => v.productVariantId === payload)) {
				state.value = state.value.map((item) => {
					if (item.productVariantId === payload) item.quantity++;
					// message.success("Product Added To Cart.")
					return item;
				});
			} else {
				state.value = [...state.value, ({ productVariantId: payload, quantity: 1 })];
			}
		},
		decrement: (state, { payload }) => {
			if (state?.value?.find((v) => v.productVariantId === payload)) {
				state.value = state.value.map((item) => {
					if (item.productVariantId === payload) {
						if (item.quantity >= 1) {
							--item.quantity;
						}
					}
					return item;
				});
			} else {
				state.value = [...state.value, ({ productVariantId: payload.payload, quantity: 1 })];
			}
		},
		incrementWithSave: (state, { payload }) => {
			if (state?.value?.find((v) => v.productVariantId === payload.productVariantId)) {
				console.log("hello 1")
				state.value = state.value.map((item) => {
					if (item.productVariantId === payload.productVariantId) {
						addToCart({ ...item, quantity:  payload.quantity ?? item.quantity+1}, payload.type).then(res => {
							payload.dispatch(fetchCart({ type: payload.type }));
						}).catch(err => {
							message.error(err.message);
						});
					};
					return item;
				});
			} else {
				state.value = [...state.value, ({ productVariantId: payload.productVariantId, quantity: localStorage.getItem('authorization') ?  1 : 0  })];
				state.value = state.value.map((item) => {
					if (item.productVariantId === payload.productVariantId) {
						addToCart({ ...item, quantity: payload.quantity ?? item.quantity }, payload.type).then(res => {
							payload.dispatch(fetchCart({ type: payload.type }));
						}).catch(err => {
							message.error(err.message);
						});
					};
					return item;
				});
			}
		},
		decrementWithSave: (state, { payload }) => {
			if (state?.value?.find((v) => v.productVariantId === payload.productVariantId)) {
				state.value = state.value.map((item) => {
					if (item.productVariantId === payload.productVariantId) {
						if (item.quantity >= 1) {
							addToCart({ ...item, quantity: item.quantity - 1 }, payload.type).then(res => {
								payload.dispatch(fetchCart({ type: payload.type }));
							}).catch(err => {
								message.error(err.message);
							});
						}
					}
					return item;
				});
			} else {
				// state.value = [...state.value, ({ productVariantId: payload.productVariantId, quantity: 1 })];
			}
		},
		removeWithSave: (state, { payload }) => {
			if (state?.value?.find((v) => v.productVariantId === payload.productVariantId)) {
				state.value = state.value.filter((item) => item.productVariantId !== payload.productVariantId);
				removeFromCart(payload._id).then(res => {
					payload.dispatch(fetchCart({ type: payload.type }));
				}).catch(err => {
					message.error(err.message);
				});
			} else {
				state.value = [...state.value, ({ productVariantId: payload.payload, quantity: 1 })];
			}
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchCart.pending, (state) => {
				state.status = 'pending';
			})
			.addCase(fetchCart.fulfilled, (state, { payload }) => {
				state.status = 'idle';
				state.value = payload || [];
			})
			.addCase(fetchCart.rejected, (state) => {
				state.status = 'error';
				state.value = [];
			})
	},
});


export const fetchCart = createAsyncThunk("cart/fetchCart", async (qData = { ignoreCartType: 1 }) => (await cartService.list(qData))?.data);

export const addToCart = async (data, type) => {
	return cartService.save({ ...data, type }).then(() => {
		notification.success({
			message: <div style={{fontSize:"25px"}}>Cart update</div>,
		})
	})
};
export const removeFromCart = async (id) => {
	return cartService.remove({ ids: id }).then(() => {
		notification.success({
			message: <div style={{fontSize:"25px"}}>Item removed</div>
		})
	})
};
export const { increment, decrement, cartData, getQuantity, incrementWithSave, decrementWithSave, removeWithSave } = cartSlice.actions;

export default cartSlice.reducer;
