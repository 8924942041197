import { Carousel, Image, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import homeService from "../../service/home";

export default function Banner() {
    const [bannerImgs, setBannerImgs] = useState([]);

    const listBannerImgs = () => {
        homeService.listBanner({ isAll: 1 }).then(res => {
            setBannerImgs(res.data?.map(v => ({img: v.imgUrl, url: v?.url || "https://amplieto.com/c/chemicals/analytical-chemistry"})) || [])
        });
    }

    useEffect(() => {
        listBannerImgs();
    }, []);

    return (
        <>
            <Row>
                <Col
                    xs={{ span: 0 }}
                    sm={{ span: 24 }}
                >
                    <Carousel
                        autoplay
                        arrows={true}
                        nextArrow={<span></span>}
                        prevArrow={<span></span>}
                        slidesToShow={1}
                        className="home-page-carasoul"
                        responsive={[]}
                        contentStyle={{
                            objectFit: "cover",
                            height: "100vw",
                            width: "240px",
                            background: 'white'
                        }}
                    >
                        {bannerImgs?.map((item, i) => (
                            <a href={item.url} target='_blank' rel="noreferrer">
                                <div key={i} >
                                    <Image preview={false} src={item?.img} alt="img" />
                                </div>
                            </a>
                        ))}
                    </Carousel>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 0 }}
                >
                    <Carousel
                        autoplay
                        arrows={false}
                        nextArrow={<span></span>}
                        prevArrow={<span></span>}
                        slidesToShow={1}
                        className="home-page-carasoul"
                        responsive={[]}
                        contentStyle={{
                            objectFit: "cover",
                            height: "100vw",
                            width: "240px",
                            background: 'white'
                        }}
                    >
                        {bannerImgs?.map((item, i) => (
                            <div key={i} >
                                <Image preview={false} src={item} alt="img" />
                            </div>
                        ))}
                    </Carousel>
                </Col>
            </Row>


        </>
    )
}
