import { useEffect, useState, Fragment } from "react";
import service from '../../service/offer'
import { Link } from "react-router-dom";
import Typography from "antd/es/typography/Typography";
import { Row } from "antd";

export default function Offer({ type }) {

    const [offer, setOffer] = useState([])

    const list = () => {
        service.list({ limit: 2 }).then(res => {
            setOffer(res.data)
        });
    }

    useEffect(() => {
        list()
    }, [])

    return (
        <>
            <div style={{ paddingLeft: '40px' }}>
                {
                    offer?.map((v, i) => (
                        <Fragment key={i}>
                            <div>
                                {/* <Row style={{display:'flex' , flexWrap:'nowrap'}}> */}
                                    <h4 style={{flex:1,width:'200px'}}>{v.title}</h4>
                                {/* </Row> */}
                                {/* <Row>
                                    <Typography.Title level={5}>{v.title}</Typography.Title>
                                </Row> */}
                                
                                <Link to={'/offer/' + v.slug}>
                                    <img src={v?.img?.url} style={{ maxWidth: '16vw' }} alt="offers" />
                                </Link>
                            </div>
                        </Fragment>
                    ))
                }
            </div>
        </>
    );
}