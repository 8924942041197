import React, { useEffect, useState, Fragment } from "react";
import { Breadcrumb, Divider, Typography, Row, Col } from 'antd';
import { theme } from "antd";
import Side from "../../components/Side";
import service from "../../service/coupon"
import CouponCard from "../../components/CouponCard";

const Coupon = () => {
    const [availableCoupon, setAvailableCoupon] = useState([])

    const list = () => {
        service.list({ isExpired: 0 }).then(res => {
            setAvailableCoupon(res.data)
        });
    }

    useEffect(() => {
        list();
    }, []);

    return (
        <>
            <Breadcrumb style={{ margin: "20px" }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Profile</Breadcrumb.Item>
            </Breadcrumb>
            <Divider />

            <Row style={{ padding: '8px' }} justify="space-around">
                <Col xs={{ span: 0 }} sm={{ span: 20 }}>
                    <Row gutter={[80]}>
                        <Col span={8}><Side /></Col>
                        <Col span={16}>
                            {
                                availableCoupon.length > 0
                                    ? <Row gutter={[30, 30]}>
                                        <Col span={24}>
                                            <Typography.Title level={2} style={{ marginTop: 20, marginBottom: 20 }}>Avaliable Coupons</Typography.Title>
                                        </Col>
                                        <Col span={24}>
                                            {
                                                availableCoupon?.map((val, i) => (
                                                    <Fragment key={i}><CouponCard key={i} {...{ val }} /></Fragment>
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                    : <Typography.Title type="secondary" level={2} style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>No Coupons Avaliable </Typography.Title>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Coupon