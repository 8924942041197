import Footer from "../components/Footer";
import TopHeader from "./TopHeader";
import CategoryNav from "./CategoryNav";
import Routes from './Routes';
import ImageNav from "./ImageNav";

export default function App() {
    return (
        <>
            <TopHeader />
            <ImageNav/>
            <CategoryNav />
            <Routes />
            <Footer />
        </>
    );
}