import React, { useState,useEffect } from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Space,
    Layout,
    message
} from "antd";
import { UserOutlined, PhoneFilled, MailFilled, ShopFilled, EnvironmentOutlined, PushpinFilled, FileTextFilled, WechatFilled } from '@ant-design/icons';
import contactService from '../../service/contact';

import { Link, Navigate } from "react-router-dom";
import setting from "../../service/setting";
const { Header } = Layout;


const headerStyle = {
    color: '#391085',
    fontSize: "xx-large",
    fontWeight: "700",
    height: 120,
    paddingInline: 50,
    lineHeight: '120px',
    backgroundColor: '#f0f0f0',
};

const heading = {
    color: '#391085',
    fontSize: "xxx-large",
    fontWeight: "700",
    height: 120,
    paddingInline: 10,
    lineHeight: '120px',
    backgroundColor: "white"
}

const heading1 = {
    fontSize: "xxx-large",
    height: 120,
    paddingInline: 10,
    lineHeight: '120px',
    backgroundColor: "white"
}



const ContactUs = () => {
    const [size, setSize] = useState('large');
    const [name, setName] = useState("")
    const [number, setNumber] = useState("")
    const [email, setEmail] = useState("")
    const [organization, setOrganization] = useState("")
    const [location, setLocation] = useState("")
    const [code, setCode] = useState("")
    const [messages, setMessage] = useState("")
    const [data, setData] = useState([])
    const sendContact = () => {

        let obj = {
            name: name,
            number: number,
            email: email,
            organizationName: organization,
            location: location,
            postCode: code,
            message: messages
        }
        contactService.contacting(obj).then((res => {
            message.success(res.message)
            onReset()
            // Navigate("/Contact Us")
        })).catch(err => {
            if (typeof err.message === 'object') {
                let dt = err.message[Object.keys(err.message)[0]];
                message.info(dt);
            } else {
                message.info(err.message);
            }
        })
    }

    const onReset = () => {
        setName("")
        setNumber("")
        setEmail("")
        setOrganization("")
        setLocation("")
        setCode("")
        setMessage("")
    }

    useEffect(() => {
        const getContactInfo = () => {
            setting.listSetting()
                .then(res => setData(res.data))
                .catch(err => message.error(err.message))
        }

        getContactInfo();
    }, [])

    return (
        <React.Fragment>
            <Row style={{ padding: "20px" }}>
                <Breadcrumb style={{ marginLeft: "20px" }}>
                    <Breadcrumb.Item>
                        <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
                            Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={"/contact-us"} style={{ fontSize: "500", fontWeight: "bolder" }}>
                            Contact Us
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            {/* <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
                <Layout>
                    <Header style={headerStyle}>
                        Contact Us
                    </Header>
                </Layout>
            </Space> */}
            <Col xs={{ span: 24 }} sm={{ span: 0 }} >
                <Layout>
                    <Header style={{
                        color: '#391085',
                        fontSize: "22px",
                        fontWeight: "700",
                        height: 70,
                        paddingInline: 20,
                        lineHeight: '70px',
                        backgroundColor: '#f0f0f0',
                    }}>
                        Contact Us
                    </Header>
                </Layout>
            </Col>



            <Row xs={{ span: 0 }} sm={{ span: 15 }} >

                <Col xs={{ span: 0 }} sm={{ span: 15 }} style={{ paddingLeft: 100, paddingRight: 50, marginBottom: 80 }}>
                    <div xs={{ span: 0 }} sm={{ span: 24 }} style={{ display: "flex", justifyContent: "center" }}>
                        <span style={heading}>
                            We have
                        </span>
                        <span style={heading1}>
                            Ways Of Contact
                        </span>
                    </div>
                    <Col style={{ display: "flex", }}>
                        <span style={{ fontSize: "xx-large", height: 120, paddingInline: 5, lineHeight: '120px', }}>
                            Submit
                        </span>
                        <span style={{ fontSize: "xx-large", height: 120, paddingInline: 5, lineHeight: '120px', fontWeight: "700", }}>
                            Your Request
                        </span>
                    </Col>
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Enter Name" prefix={<UserOutlined style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} value={name} size="large" style={{ border: "none", borderBottom: "solid", borderBlockColor: "#f0f0f0" }} onChange={(e) => setName(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Contact Number" prefix={<PhoneFilled style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} value={number} size="large" style={{ border: "none", borderBottom: "solid", borderBlockColor: "#f0f0f0" }} onChange={(e) => setNumber(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", justifyContent: "space-around" }}>

                        <Input placeholder="Email" prefix={<MailFilled style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} value={email} size="large" style={{ border: "none", borderBottom: "solid", borderBlockColor: "#f0f0f0" }} onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Organization Name" prefix={<ShopFilled style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} value={organization} size="large" style={{ border: "none", borderBottom: "solid", borderBlockColor: "#f0f0f0" }} onChange={(e) => setOrganization(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Location" prefix={<EnvironmentOutlined style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} value={location} size="large" style={{ border: "none", borderBottom: "solid", borderBlockColor: "#f0f0f0" }} onChange={(e) => setLocation(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Post Code" prefix={<PushpinFilled style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} value={code} size="large" style={{ border: "none", borderBottom: "solid", borderBlockColor: "#f0f0f0" }} onChange={(e) => setCode(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input.TextArea placeholder="Details of Your Querry" prefix={<FileTextFilled style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} style={{ backgroundColor: '#f0f0f0' }} value={messages} onChange={(e) => setMessage(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: 'flex', justifyContent: 'end' }}>
                        <Space >
                            <Button size={size} onClick={() => onReset()}>
                                Reset
                            </Button>

                            <Button type="primary" onClick={sendContact} size={size}>
                                Submit
                            </Button>
                        </Space>
                    </Col>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 1 }}></Col>
                <Col xs={{ span: 0 }} sm={{ span: 7 }} style={{ backgroundColor: "#f0f0f0", marginBottom: 80 }}>
                    <Col style={{ display: "flex", marginLeft: 50 }}>
                        <span style={{ fontSize: "xx-large", paddingInline: 5, lineHeight: '120px', }}>
                            Touch
                        </span>
                        <span style={{ fontSize: "xx-large", paddingInline: 5, lineHeight: '120px', fontWeight: "700", }}>
                            With Us
                        </span>
                    </Col>

                    <Col style={{ display: "flex", marginLeft: 50 }}>
                        <span style={{ fontSize: "x-large", height: 120, lineHeight: '120px', fontWeight: "700", }}><EnvironmentOutlined /></span>
                        <span style={{ fontSize: "xx-large", height: 120, lineHeight: '120px', fontWeight: "700", marginLeft: 20 }}>Address</span>
                    </Col>
                    {data.filter(v => v.type === "address").map(vv => (
                        <Col style={{ display: "flex", marginLeft: 70 }}>
                            <p style={{ marginTop: -20 }}><b>{vv?.data}</b></p>
                        </Col>
                    ))}
                    <Col style={{ display: "flex", marginLeft: 50 }}>
                        <span style={{ fontSize: "x-large", height: 120, lineHeight: '120px', fontWeight: "700", }}><PhoneFilled /></span>
                        <span style={{ fontSize: "xx-large", height: 120, lineHeight: '120px', fontWeight: "700", marginLeft: 20 }}>Call us</span>
                    </Col>
                    {data.filter(v => v.type === "phone").map(vv => (
                        <Col style={{ display: "flex", marginLeft: 70 }}>
                            <p style={{ marginTop: -20 }}><b>{vv?.data}</b></p>
                        </Col>
                    ))}
                    <Col style={{ display: "flex", marginLeft: 50 }}>
                        <span style={{ fontSize: "x-large", height: 120, lineHeight: '120px', fontWeight: "700", }}><MailFilled /></span>
                        <span style={{ fontSize: "xx-large", height: 120, lineHeight: '120px', fontWeight: "700", marginLeft: 20 }}>Drop an Email</span>
                    </Col>
                    {data.filter(v => v.type === "email").map(vv => (
                        <Col style={{ display: "flex", marginLeft: 70 }}>
                            <p style={{ marginTop: -20 }}><b>{vv?.data}</b></p>
                        </Col>
                    ))}
                    <Col style={{ display: "flex", marginLeft: 50 }}>
                        <span style={{ fontSize: "x-large", height: 120, lineHeight: '120px', fontWeight: "700", }}><WechatFilled /></span>
                        <span style={{ fontSize: "xx-large", height: 120, lineHeight: '120px', fontWeight: "700", marginLeft: 20 }}>Whatsapp Us</span>
                    </Col>
                    {data.filter(v => v.type === "whatsapp").map(vv => (
                        <Col style={{ display: "flex", marginLeft: 70 }}>
                            <p style={{ marginTop: -20 }}><b>{vv?.data}</b></p>
                        </Col>
                    ))}
                </Col>

            </Row>
            <Row style={{ padding: 30 }} xs={{ span: 24 }} sm={{ span: 0 }}>

                <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ marginBottom: 30 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <span style={{
                            fontSize: "22px",
                            paddingLeft: '12px',
                        }}>
                            <span style={{
                                color: '#391085',
                                fontWeight: "700",
                            }}>
                                We have </span> Ways Of Contact</span>
                    </div>
                    <span style={{ fontSize: "20px", height: 120, paddingInline: 5, lineHeight: '50px' }}>
                        Submit
                    </span>
                    <span style={{ fontSize: "20px", height: 120, paddingInline: 5, lineHeight: '50px', fontWeight: "700", }}>
                        Your Request
                    </span>

                    <Col style={{ display: "flex", }}>
                        <Input placeholder="Enter Name" addonBefore={<UserOutlined style={{ color: 'purple', fontSize: '1.2rem' }} />} value={name} onChange={(e) => setName(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Contact Number" addonBefore={<PhoneFilled style={{ color: 'purple', fontSize: '1.2rem' }} />} value={number} onChange={(e) => setNumber(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", justifyContent: "space-around" }}>

                        <Input placeholder="Email" addonBefore={<MailFilled style={{ color: 'purple', fontSize: '1.2rem', }} />} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Organization Name" addonBefore={<ShopFilled style={{ color: 'purple', fontSize: '1.2rem', }} />} value={organization} onChange={(e) => setOrganization(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Location" addonBefore={<EnvironmentOutlined style={{ color: 'purple', fontSize: '1.2rem', }} />} value={location} onChange={(e) => setLocation(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input placeholder="Post Code" addonBefore={<PushpinFilled style={{ color: 'purple', fontSize: '1.2rem' }} />} value={code} onChange={(e) => setCode(e.target.value)} />
                    </Col>
                    <br />
                    <Col style={{ display: "flex", }}>

                        <Input.TextArea addonBefore={<FileTextFilled style={{ color: 'purple', fontSize: '1.5rem', marginRight: '10px' }} />} placeholder="Details of Your Querry" onChange={(e) => setMessage(e.target.value)} />
                    </Col>
                    <br />
                    <Button style={{ width: '100%' }} type="primary" onClick={sendContact} size={size}>
                        Submit
                    </Button>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ backgroundColor: "#f0f0f0", marginBottom: 80 }}>
                    <Col style={{ display: "flex", paddingLeft: '18px', paddingTop: '24px', marginBottom: '10px' }}>
                        <span style={{ fontSize: "26px", paddingInline: 5, color: 'purple' }}>
                            Touch
                        </span>
                        <span style={{ fontSize: "26px", paddingInline: 5, fontWeight: "700", }}>
                            With Us
                        </span>
                    </Col>
                    <br />

                    <Col style={{ display: "flex", marginLeft: 20, }}>
                        <span style={{ fontSize: "24px", fontWeight: "700", }}><EnvironmentOutlined /></span>
                        <span style={{ fontSize: "24px", fontWeight: "600", marginLeft: 20 }}>Address</span>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 60 }}>
                        <p style={{ marginTop: 5 }}><b>18, Vaishali Enclave, Pitampura,</b></p>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 85 }}>
                        <p style={{ marginTop: -10 }}><b>New Delhi- 110034</b></p>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 20 }}>
                        <span style={{ fontSize: "24px", height: 50, lineHeight: '40px', fontWeight: "600", }}><PhoneFilled /></span>
                        <span style={{ fontSize: "24px", height: 50, lineHeight: '40px', fontWeight: "600", marginLeft: 20 }}>Call Arramton</span>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 60 }}>
                        <p style={{ marginTop: -10 }}><b>+91 00000-00000, +91 00000-00000, </b></p>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 20 }}>
                        <span style={{ fontSize: "24px", height: 50, lineHeight: '40px', fontWeight: "600", }}><MailFilled /></span>
                        <span style={{ fontSize: "24px", height: 50, lineHeight: '40px', fontWeight: "600", marginLeft: 20 }}>Drop an Email</span>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 60 }}>
                        <p style={{ marginTop: -10 }}><b>info@yourdomain.com</b></p>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 20 }}>
                        <span style={{ fontSize: "24px", height: 50, lineHeight: '40px', fontWeight: "600", }}><WechatFilled /></span>
                        <span style={{ fontSize: "24px", height: 50, lineHeight: '40px', fontWeight: "600", marginLeft: 20 }}>Whatsapp Arramton</span>
                    </Col>
                    <Col style={{ display: "flex", marginLeft: 75, marginBottom: 20 }}>
                        <p style={{ marginTop: -10 }}><b>+91 00000-00000</b></p>
                    </Col>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ContactUs;