/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { increment, decrement } from "../redux/cart";
import { fetchCart, addToCart, incrementWithSave } from "../redux/cart";
import { Spin, message } from "antd";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';

import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Divider,
	Row,
	Space,
	Carousel,
	Image,
	Input,
	Select,
	Descriptions
} from "antd";
import { useRoutes } from "react-router-dom";
import service from "../service/product";
import chemicalService from "../service/chemicalAttribute";
import varientService from "../service/product_varient";
import { Link } from "react-router-dom";
import { ShoppingCartOutlined } from "@ant-design/icons";



export default function ProductDetails() {
	const navigate = useNavigate();
	const { slug, slug5 } = useParams();
	const fff = useParams();
	const cartData = useSelector(state => state.cart.value || []);
	const [qData, setQData] = useState({});
	const [productData, setProductData] = useState({});
	const [activeImg, setActiveImg] = useState({});
	const [productVarient, setProductVarient] = useState([]);
	const [recommandedProduct, setRecommandedProduct] = useState([]);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false)



	function productDetails() {
		/* API call for */
		setLoader(true)
		service
			.details(qData)
			.then((res) => {
				window.document.documentElement.scrollTop = 0;
				setProductData(res.data[0]);
				console.log(res.data[0])
				varientService
					.list({ productId: res.data[0]._id })
					.then((res) => setProductVarient(res.data))
					.catch((err) => { });
			})
			.catch((err) => { }).finally(() => {
				setLoader(false)
			})
	}



	function ProductRecommened() {
		/* API call for */
		service.recommandedProduct(qData)
			.then((res) => {
				setRecommandedProduct(res.data);
			})
			.catch((err) => { });
	}

	useEffect(() => {
		if (qData.slug) {
			productDetails();
			ProductRecommened();
		}
	}, [qData.slug]);

	useEffect(() => {
		setQData({ slug: slug });
	}, [slug]);

	useEffect(() => {
		dispatch(fetchCart());
	}, []);


	const tableRow = {
		border: "1px solid #dddddd",
		textAlign: "center",
		padding: "8px",
	};

	useEffect(() => {
		if (productData?.imgs?.length) {
			setActiveImg(productData.imgs[0]);
		}
	}, [productData])

	return (
		<>
			<Row style={{ padding: "20px" }}>
				<Breadcrumb style={{ marginLeft: "20px" }}>
					<Breadcrumb.Item>
						<Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
							Home
						</Link>
					</Breadcrumb.Item>
					{
						productData?.catType &&
						<Breadcrumb.Item>
							<Link to={`/c/${productData?.catType}`} style={{ fontSize: "500", fontWeight: "bolder" }}>
								{productData?.catType}
							</Link>
						</Breadcrumb.Item>
					}

					<Breadcrumb.Item>
						<Link
							to={""}
							style={{ color: "black", fontSize: "500", fontWeight: "bolder" }}
						>
							{slug}
						</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
			</Row>
			{
				loader ? <div className="center-screen"><Spin /></div> : productData?.varientType === 'onlyWeight' ?
					<Row >
						<Col xs={{ span: 24 }} sm={{ span: 0 }}>
							<PhoneViewWeightProductDetail activeImg={activeImg} setActiveImg={setActiveImg} productVarient={productVarient} productData={productData} cartData={cartData} dispatch={dispatch} />

						</Col>
						<Col xs={{ span: 0 }} sm={{ span: 24 }}>
							<WeightProductDetail activeImg={activeImg} setActiveImg={setActiveImg} productVarient={productVarient} productData={productData} cartData={cartData} dispatch={dispatch} />
						</Col>

					</Row>
					:
					<Row >
						<Col xs={{ span: 24 }} sm={{ span: 0 }}>
							<PhoneViewNormalProductDetail cartData={cartData} activeImg={activeImg} setActiveImg={setActiveImg} productData={productData} productVarient={productVarient} dispatch={dispatch} />

						</Col>
						<Col xs={{ span: 0 }} sm={{ span: 24 }}>
							<NormalProductDetail cartData={cartData} activeImg={activeImg} setActiveImg={setActiveImg} productData={productData} productVarient={productVarient} dispatch={dispatch} />
						</Col>
					</Row>


			}

			{/* <div>
				<div style={{ paddingRight: "80px", paddingLeft: "80px" }}>
					<h2 style={{ color: "#5f5f5f" }}>Recommended Product </h2>
					<Divider />
				</div>
				<Row>
					<Col span={24}>
						<Row
							gutter={24}
							style={{
								paddingRight: "80px",
								paddingLeft: "80px",
								paddingBottom: "30px",
							}}
						>
							{
								recommandedProduct?.length && recommandedProduct?.map((val, i) => (
									<Col span={4} key={i}>
										<Link to={"/product/" + val.slug}>
											<Card
												key={i}
												cover={
													<img alt="example" src={val?.thumbImgUrl} height={200} />
												}
												hoverable
												style={{
													background: "white",
													border: "none",
													boxShadow: "15px 15px 30px lightgrey",
													height: 400
												}}
											>
												<Space
													style={{ textAlign: "center", width: "100%" }}
													direction="vertical"
												>
													<span style={{ fontSize: "20px", fontWeight: 700 }}>
														{val?.name}
													</span>
													<span style={{ fontSize: "18px", fontWeight: 500 }}>
														{val?.subName}
													</span>
													<span
														style={{
															fontSize: "24px",
															fontWeight: 700,
															color: "#5f5f5f",
														}}
													>
														₹{val?.startingPrice}{" "}
													</span>
												</Space>
											</Card>
										</Link>
									</Col>
								))
							}
						</Row>
					</Col>
				</Row>
			</div> */}

		</>
	);
}

const PhoneViewNormalProductDetail = ({ productData, productVarient, activeImg, setActiveImg, cartData, dispatch }) => {
	const userInfo = useSelector(state => state.user);
	const [chemicalAttribute, setChemicalAttribute] = useState({});

	const tableRow = {
		border: "1px solid #dddddd",
		textAlign: "center",
		padding: "8px",
	};
	function chemicalAttributeDetails() {
		/* API call for */
		chemicalService
			.details(productData?._id)
			.then((res) => {
				setChemicalAttribute(res.data || {})
			})
			.catch((err) => { });
	}
	useEffect(() => {
		if (productData?.catType) {
			chemicalAttributeDetails()

		}
	}, [])

	return (
		<>

			<Row>
				<Col span={24} style={{ padding: 15, display: "flex", justifyContent: 'center' }}>
					<Image style={{ height: "280px", maxWidth: '100%' }} preview src={activeImg?.url} alt="img" />
				</Col>
				<Col span={24}>

					<Carousel
						autoplay={false}
						// className="product-detail-slider-dashboard"
						// nextArrow={<span />}
						// prevArrow={<span />}
						// arrows={true}
						infinite={false}
						dots={false}
						// slidesToShow={(productData.imgs?.length - 1)}
						slidesToShow={3}
						dotPosition={"bottom"}
					// arrowPosition={"bottom"}
					>
						{
							productData.imgs?.map(v => (
								<div key={v.uid} onClick={() => { setActiveImg(v) }}>
									<div style={{ padding: 5, margin: 5, border: '1px solid grey', width: '100px', height: '99px', borderRadius: 4, cursor: 'pointer' }}>
										<Image width={100} height={100} preview={false} src={v.url} alt="img" />
									</div>
								</div>
							))
						}
					</Carousel>
				</Col>
			</Row>
			<Col span={24} style={{ background: "rgb(245 245 245)" }}>
				<div style={{ background: 'rgb(245 245 245)', padding: '25px 20px 25px 18px' }}>
					<Space direction="vertical">
						<Space direction="vertical">
							<span style={{ fontSize: "19px", fontWeight: "bold" }}>
								{productData.name} {productData?.metaTitle}
							</span>
							<span style={{ fontSize: "14px", fontWeight: 500 }}>
								{productData?.metaTag}
							</span>
						</Space>


						{
							userInfo.isLoggedin ?
								<Space direction="horizontal" size={20}>

									<span style={{ fontSize: "18px", fontWeight: "bold" }}>Price :</span>
									<span
										style={{
											fontSize: "20px",
											fontWeight: "bold",
											color: "purple",
										}}
									>
										₹{productData?.startingPrice}
									</span>
								</Space> : ""
						}
						{/* <span
									style={{
										fontSize: "20px",
										fontWeight: "bold",
										color: "grey",
									}}
								>
									{" "}
									<del>₹{productData.price}</del>{" "}
								</span> */}

						<span style={{ fontSize: "14px", fontWeight: 600 }}>
							{productData.metaDescription}
						</span>
						{/* <div style={{ display: 'flex', padding: 0, margin: 0, }} > */}
						{
							productData?.catType ?
								<Descriptions column={2}>
									{chemicalAttribute?.grade && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Grade</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.grade}</span> </Descriptions.Item>}
									{chemicalAttribute?.CAS && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>CAS</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.CAS}</span> </Descriptions.Item>}
									{chemicalAttribute?.storeCondition && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Storage Condition</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.storeCondition}</span> </Descriptions.Item>}
									{chemicalAttribute?.GHS && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>GHS</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.GHS}</span> </Descriptions.Item>}
									{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Molecular Weight</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.molecularWeight}</span> </Descriptions.Item>}
									{chemicalAttribute?.manufacturer && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Manufacturer</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.manufacturer}</span> </Descriptions.Item>}
								</Descriptions> : ""
						}
						{
							productData?.catType ?
								<Descriptions>
									{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Specification Assay</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.specificationAssay}</span> </Descriptions.Item>}

								</Descriptions> : ""
						}
						{/* </div> */}
					</Space>
				</div>
			</Col>

			<Col span={24}>
				<Card style={{ border: 'none' }}>
					<Space direction="vertical" size={30}>
						<span style={{ fontSize: '24px', fontWeight: 'bold' }}>Product Description</span>
						<span style={{ fontSize: '16px', fontWeight: 600, color: 'grey' }}>{productData?.subDescription} </span>
						<div style={{  fontWeight: 600, color: 'grey' }} className="html-text-content" dangerouslySetInnerHTML={{ __html: productData?.description }} />

					</Space>
				</Card>
			</Col>
			<div style={{ paddingLeft: '20px' }}>
				<h2 style={{ marginTop: "20px", fontSize: "20px" }}>
					Select packaging size
				</h2>
			</div>
			<Divider style={{ marginTop: '10px', marginBottom: '0px' }} />
			<Row>
				<Col span={24} style={{ padding: '20px' }}>
					{
						productVarient.map((item, idx) => (
							<Card
								key={idx}
								style={{
									background: "rgb(245 245 245)",
									border: "none",
									marginBottom: '20px'
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', }}>
									<span style={{ fontWeight: 'bolder', fontSize: '16px' }}>ProductID</span>
									<span style={{ fontWeight: 500 }}> {item.catId}</span>
								</div>
								<Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

								<div style={{ display: 'flex', justifyContent: 'space-between', }}>
									<span style={{ fontWeight: 'bolder', fontSize: '16px' }}>Package Volume</span>
									<span style={{ fontWeight: 500 }}> {item.volume}</span>
								</div>
								<Divider style={{ marginTop: '10px', marginBottom: '10px' }} />



								{
									userInfo.isLoggedin && <>
										<div style={{ display: 'flex', justifyContent: 'space-between', }}>
											<span style={{ fontWeight: 'bolder', fontSize: '17px' }}>Price</span>
											<span style={{ fontWeight: 500 }}> {item.price}</span>
										</div>
										<Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
									</>
								}

								<div style={{ display: 'flex', justifyContent: 'space-between', }}>
									<Space direction="horizontal">
										<Button
											size="small"
											onClick={() => {
												dispatch(decrement(item._id))
											}}
										>-</Button>
										<Button
											size="small"
										>
											{cartData?.find(v => v.productVariantId === item._id)?.quantity || 0}
										</Button>
										<Button size="small"
											onClick={() => dispatch(increment(item._id))}
										>+</Button>
									</Space>
									<Button
										style={{
											background:
												"linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)",
											color: "white",
											fontWeight: "500",
										}}
										onClick={() => {
											dispatch(
												incrementWithSave({
													productVariantId: (cartData?.find(v => v.productVariantId === item._id))?.productVariantId,
													quantity: (cartData?.find(v => v.productVariantId === item._id)?.quantity || 0),
													dispatch,
													type: productData?.isEUD ? 'EUDcart' : 'myCart'
												})
											);
											// message.success("Product Added To Cart.")
										}}
									>
										Add to Cart
									</Button>
								</div>
							</Card>
						))
					}

				</Col>
				{
					productData?.catType === 'labware' && productData?.descriptionImg &&
					<Col span={24} style={{ padding: '20px' }}>
						<Image src={productData?.descriptionImg?.url} alt="Our Picture"
							style={{
								height: 180,
								width: '100%'

							}}
						/>
					</Col>
				}
				<Col span={24} style={{ padding: '20px' }}>
					{
						productData?.catType ?

							<Descriptions
								title={Object.keys(chemicalAttribute).length !== 0 ? <h3> Product Specifications </h3> : ''}
								bordered
							>
								{chemicalAttribute?.outline && <Descriptions.Item label={<b>Outline</b>}>{chemicalAttribute?.outline}</Descriptions.Item>}
								{chemicalAttribute?.grade && <Descriptions.Item label={<b>Grade</b>}>{chemicalAttribute?.grade}</Descriptions.Item>}
								{chemicalAttribute?.manufacturer && <Descriptions.Item label={<b>Manufacturer</b>}>{chemicalAttribute?.manufacturer}</Descriptions.Item>}
								{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<b>Specification Assay</b>}>{chemicalAttribute?.specificationAssay}</Descriptions.Item>}
								{chemicalAttribute?.storeCondition && <Descriptions.Item label={<b>Store Condition</b>}>{chemicalAttribute?.storeCondition}</Descriptions.Item>}
								{chemicalAttribute?.modecularFormula && <Descriptions.Item label={<b>Molecular Formula</b>}>{chemicalAttribute?.modecularFormula}</Descriptions.Item>}
								{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<b>Molecular Weight</b>}>{chemicalAttribute?.molecularWeight}</Descriptions.Item>}
								{chemicalAttribute?.GHS && <Descriptions.Item label={<b>GHS</b>}>{chemicalAttribute?.GHS}</Descriptions.Item>}
								{chemicalAttribute?.CAS && <Descriptions.Item label={<b>CAS</b>}>{chemicalAttribute?.CAS}</Descriptions.Item>}
								{chemicalAttribute?.barcoderNumber && <Descriptions.Item label={<b>BarCode Number</b>}>{chemicalAttribute?.barcoderNumber}</Descriptions.Item>}
								{chemicalAttribute?.precautionsForUse && <Descriptions.Item label={<b>Precautions For Use</b>}>{chemicalAttribute?.precautionsForUse}</Descriptions.Item>}
								{chemicalAttribute?.appearance && <Descriptions.Item label={<b>Appearance</b>}>{chemicalAttribute?.appearance}</Descriptions.Item>}
								{chemicalAttribute?.meltingPoint && <Descriptions.Item label={<b>Melting Point</b>}>{chemicalAttribute?.meltingPoint}</Descriptions.Item>}
								{chemicalAttribute?.activity && <Descriptions.Item label={<b>Activity</b>}>{chemicalAttribute?.activity}</Descriptions.Item>}
								{chemicalAttribute?.boilingPoint && <Descriptions.Item label={<b>Boiling Point</b>}>{chemicalAttribute?.boilingPoint}</Descriptions.Item>}
								{chemicalAttribute?.freezingPoint && <Descriptions.Item label={<b>Freezing Point</b>}>{chemicalAttribute?.freezingPoint}</Descriptions.Item>}
								{chemicalAttribute?.density && <Descriptions.Item label={<b>Density</b>}>{chemicalAttribute?.density}</Descriptions.Item>}
								{chemicalAttribute?.features && <Descriptions.Item label={<b>Features</b>}>{chemicalAttribute?.features}</Descriptions.Item>}
								{chemicalAttribute?.purpose && <Descriptions.Item label={<b>Purpose</b>}>{chemicalAttribute?.purpose}</Descriptions.Item>}
								{chemicalAttribute?.quantity && <Descriptions.Item label={<b>Quantity</b>}>{chemicalAttribute?.quantity}</Descriptions.Item>}
								{chemicalAttribute?.purity && <Descriptions.Item label={<b>Purity</b>}>{chemicalAttribute?.purity}</Descriptions.Item>}
								{chemicalAttribute?.flashPoint && <Descriptions.Item label={<b>Flash Point</b>}>{chemicalAttribute?.flashPoint}</Descriptions.Item>}
								{chemicalAttribute?.ignitionPoint && <Descriptions.Item label={<b>Ignition Point</b>}>{chemicalAttribute?.ignitionPoint}</Descriptions.Item>}
								{chemicalAttribute?.refractiveIndex && <Descriptions.Item label={<b>Refractive Index</b>}>{chemicalAttribute?.refractiveIndex}</Descriptions.Item>}
								{chemicalAttribute?.solubility && <Descriptions.Item label={<b>Solubility</b>}>{chemicalAttribute?.solubility}</Descriptions.Item>}
								{chemicalAttribute?.toxicity && <Descriptions.Item label={<b>Toxicity</b>}>{chemicalAttribute?.toxicity}</Descriptions.Item>}
								{chemicalAttribute?.origin && <Descriptions.Item label={<b>Origin</b>}>{chemicalAttribute?.origin}</Descriptions.Item>}
								{chemicalAttribute?.specificRotation && <Descriptions.Item label={<b>Specific Rotation</b>}>{chemicalAttribute?.specificRotation}</Descriptions.Item>}
								{chemicalAttribute?.labeling && <Descriptions.Item label={<b>Labeling</b>}>{chemicalAttribute?.labeling}</Descriptions.Item>}
								{chemicalAttribute?.alias && <Descriptions.Item label={<b>Alias</b>}>{chemicalAttribute?.alias}</Descriptions.Item>}
								{chemicalAttribute?.ph && <Descriptions.Item label={<b>PH</b>}>{chemicalAttribute?.ph}</Descriptions.Item>}
								{chemicalAttribute?.concentration && <Descriptions.Item label={<b>Concentration</b>}>{chemicalAttribute?.concentration}</Descriptions.Item>}
								{chemicalAttribute?.specificGravity && <Descriptions.Item label={<b>Specific Gravity</b>}>{chemicalAttribute?.specificGravity}</Descriptions.Item>}
								{chemicalAttribute?.composition && <Descriptions.Item label={<b>Composition</b>}>{chemicalAttribute?.composition}</Descriptions.Item>}
								{chemicalAttribute?.property && <Descriptions.Item label={<b>Property</b>}>{chemicalAttribute?.property}</Descriptions.Item>}
								{chemicalAttribute?.host && <Descriptions.Item label={<b>Host</b>}>{chemicalAttribute?.host}</Descriptions.Item>}
								{chemicalAttribute?.classification && <Descriptions.Item label={<b>Classification</b>}>{chemicalAttribute?.classification}</Descriptions.Item>}
								{chemicalAttribute?.antigen && <Descriptions.Item label={<b>Antigen</b>}>{chemicalAttribute?.antigen}</Descriptions.Item>}
								{chemicalAttribute?.antigenAlias && <Descriptions.Item label={<b>Antigen Alias</b>}>{chemicalAttribute?.antigenAlias}</Descriptions.Item>}
								{chemicalAttribute?.cloneNumber && <Descriptions.Item label={<b>Clone Number</b>}>{chemicalAttribute?.cloneNumber}</Descriptions.Item>}
								{chemicalAttribute?.subclass && <Descriptions.Item label={<b>Subclass</b>}>{chemicalAttribute?.subclass}</Descriptions.Item>}
								{chemicalAttribute?.crossReactivity && <Descriptions.Item label={<b>Cross Reactivity</b>}>{chemicalAttribute?.crossReactivity}</Descriptions.Item>}
								{chemicalAttribute?.specificity && <Descriptions.Item label={<b>Specificity</b>}>{chemicalAttribute?.specificity}</Descriptions.Item>}
								{chemicalAttribute?.preparation && <Descriptions.Item label={<b>Preparation</b>}>{chemicalAttribute?.preparation}</Descriptions.Item>}
								{chemicalAttribute?.instructions && <Descriptions.Item label={<b>Instructions</b>}>{chemicalAttribute?.instructions}</Descriptions.Item>}
								{chemicalAttribute?.formulation && <Descriptions.Item label={<b>Formulation</b>}>{chemicalAttribute?.formulation}</Descriptions.Item>}
								{chemicalAttribute?.label && <Descriptions.Item label={<b>Label</b>}>{chemicalAttribute?.label}</Descriptions.Item>}
								{chemicalAttribute?.ELISA && <Descriptions.Item label={<b>ELISA</b>}>{chemicalAttribute?.ELISA}</Descriptions.Item>}
								{chemicalAttribute?.westernBlot && <Descriptions.Item label={<b>Western Blot</b>}>{chemicalAttribute?.westernBlot}</Descriptions.Item>}
								{chemicalAttribute?.flowCytometry && <Descriptions.Item label={<b>Flow Cytometry</b>}>{chemicalAttribute?.flowCytometry}</Descriptions.Item>}
								{chemicalAttribute?.immunoprecipitation && <Descriptions.Item label={<b>Immuno Precipitation</b>}>{chemicalAttribute?.immunoprecipitation}</Descriptions.Item>}
								{chemicalAttribute?.reactivity && <Descriptions.Item label={<b>Reactivity</b>}>{chemicalAttribute?.reactivity}</Descriptions.Item>}
								{chemicalAttribute?.isotype && <Descriptions.Item label={<b>Isotype</b>}>{chemicalAttribute?.isotype}</Descriptions.Item>}
								{chemicalAttribute?.buffer && <Descriptions.Item label={<b>Buffer</b>}>{chemicalAttribute?.buffer}</Descriptions.Item>}
								{chemicalAttribute?.reference && <Descriptions.Item label={<b>Reference</b>}>{chemicalAttribute?.reference}</Descriptions.Item>}

							</Descriptions> : ""
					}

				</Col>
			</Row>
		</>
	)
}
const NormalProductDetail = ({ productData, productVarient, activeImg, setActiveImg, cartData, dispatch }) => {
	const [chemicalAttribute, setChemicalAttribute] = useState({});

	const userInfo = useSelector(state => state.user);
	const tableRow = {
		border: "1px solid #dddddd",
		textAlign: "center",
		padding: "8px",
	};
	function chemicalAttributeDetails() {
		/* API call for */
		chemicalService
			.details(productData?._id)
			.then((res) => {
				setChemicalAttribute(res.data || {})
			})
			.catch((err) => { });
	}
	useEffect(() => {
		if (productData?.catType) {
			chemicalAttributeDetails()

		}
	}, [])


	return (
		<>

			<Row>
				<Col span={10}>
					<Card
						style={{
							borderTop: "1px solid lightgrey",
							borderBottom: "none",
						}}
					>
						<Row style={{ marginTop: 20 }}>
							<Col span={6} style={{ maxHeight: '350px' }}>
								<Carousel
									autoplay={false}
									className="product-detail-slider-dashboard"

									nextArrow={<span />}
									prevArrow={<span />}
									arrows={true}
									infinite={false}
									dots={false}
									// slidesToShow={(productData.imgs?.length - 1)}
									slidesToShow={3}
									dotPosition={"left"}
									arrowPosition={"top"}
								>
									{
										productData.imgs?.map(v => (
											<div key={v.uid} onClick={() => { setActiveImg(v) }}>
												<div style={{ padding: 5, margin: 5, border: '1px solid grey', width: '100px', borderRadius: 4, cursor: 'pointer' }}>
													<Image width={100} height={100} preview={false} src={v.url} alt="img" />
												</div>
											</div>
										))
									}
								</Carousel>
							</Col>
							<Col span={18} style={{ padding: 20, margin: "auto", display: "flex" }}>

								<Image style={{ height: "400px", margin: "auto" }} preview src={activeImg?.url} alt="img" />
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={14} height="400" style={{ background: "rgb(245 245 245)" }}>
					<Card
						style={{
							background: "rgb(245 245 245)",
							paddingLeft: "35px",
							paddingTop: "50px",
							paddingBottom: "30px",
							// paddingRight: "100px",
							border: "none",
						}}
					>
						<Space direction="vertical">
							<Space direction="vertical">
								<span style={{ fontSize: "24px", fontWeight: "bold" }}>
									{productData.name} {productData?.metaTitle}
								</span>
								<span style={{ fontSize: "16px", fontWeight: 500 }}>
									{productData?.metaTag}
								</span>
							</Space>


							{/* {
								userInfo.isLoggedin ?
									<Space direction="horizontal" size={20}>

										<span style={{ fontSize: "20px", fontWeight: "bold" }}>Price</span>
										<span
											style={{
												fontSize: "26px",
												fontWeight: "bold",
												color: "purple",
											}}
										>
											₹{productData?.startingPrice}
										</span>
									</Space> : ""
							} */}
							{/* <span
									style={{
										fontSize: "20px",
										fontWeight: "bold",
										color: "grey",
									}}
								>
									{" "}
									<del>₹{productData.price}</del>{" "}
								</span> */}

							<span style={{ fontSize: "16px", fontWeight: 600 }}>
								{productData.metaDescription}
							</span>
							{
								productData?.catType ?
									<Descriptions column={{
										xxl: 4,
										xl: 3,
										lg: 2,
										md: 1,
										sm: 1,
									}}>
										{chemicalAttribute?.grade && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Grade</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.grade}</span> </Descriptions.Item>}
										{/* {chemicalAttribute?.specificationAssay && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Specification Assay</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.specificationAssay}</span> </Descriptions.Item>} */}
										{chemicalAttribute?.CAS && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>CAS</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.CAS}</span> </Descriptions.Item>}
										{chemicalAttribute?.GHS && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>GHS</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.GHS}</span> </Descriptions.Item>}
										{chemicalAttribute?.manufacturer && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Manufacturer</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.manufacturer}</span> </Descriptions.Item>}
										{chemicalAttribute?.storeCondition && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Storage Condition</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.storeCondition}</span> </Descriptions.Item>}
										{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Molecular Weight</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.molecularWeight}</span> </Descriptions.Item>}
									</Descriptions> : ""
							}
							{
								productData?.catType ?
									<Descriptions >
										{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Specification Assay</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.specificationAssay}</span> </Descriptions.Item>}
									</Descriptions> : ""
							}
						</Space>
					</Card>
				</Col>
			</Row>
			<Row justify="end">
				<Col span={10}>
					<Card style={{ border: 'none' }}>
						<Space direction="vertical" size={30}>
							<div style={{ fontSize: '24px', fontWeight: 'bold' }}>Product Description</div>
							<div style={{ fontSize: '16px', fontWeight: 600, color: 'grey' }}>{productData?.subDescription}</div>
							
								{/* {productData?.description ? parse(productData?.description) : ""}\ */}
								<div style={{   fontWeight: 600, color: 'grey' }} className="html-text-content" dangerouslySetInnerHTML={{ __html: productData?.description }} />
							

						</Space>
					</Card>
				</Col>

				<Col span={14}>
					<h2 style={{ marginTop: "40px", fontSize: "25px" }}>
						Select packaging size
					</h2>
					<div style={{ paddingRight: "40px", paddingBottom: "40px" }}>
						<Divider />
						<table style={{ width: "100%", borderCollapse: "collapse" }}>
							<thead
								style={{
									background:
										"linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)",
								}}
							>
								<tr
									style={{
										height: "50px",
										fontSize: "18px",
										color: "white",
										border: "2px solid purple",
										borderTopLeftRadius: "10px",
									}}
								>
									<th>ProductID</th>
									<th>Package Size</th>
									{
										userInfo.isLoggedin && <th>Price</th>
									}

									<th>Action</th>
								</tr>
							</thead>
							<tbody style={{ fontSize: "16px" }}>
								{productVarient.map((item, idx) => {
									return (
										<tr key={idx}>
											<td style={tableRow}>{item.catId}</td>
											<td style={tableRow}>{item?.volume}</td>
											{/* <td style={tableRow}><b>₹{item.withDiscountPrice?.toFixed(2)}</b> <del>₹{item.price?.toFixed(2)}</del></td> */}
											{
												userInfo.isLoggedin && <td style={tableRow}><b>₹{item.price?.toFixed(2)}</b></td>
											}

											<td style={tableRow}>
												<Space direction="horizontal">
													<Button
														onClick={() => {
															dispatch(decrement(item._id))
														}}
													>-</Button>
													<Button>
														{cartData?.find(v => v.productVariantId === item._id)?.quantity || 0}
													</Button>
													<Button
														onClick={() => dispatch(increment(item._id))}
													>+</Button>
													<Button
														style={{
															background:
																"linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)",
															color: "white",
															fontWeight: "500",
														}}
														onClick={() => {
															dispatch(
																incrementWithSave({
																	productVariantId: (cartData?.find(v => v.productVariantId === item._id))?.productVariantId,
																	quantity: (cartData?.find(v => v.productVariantId === item._id)?.quantity || 0),
																	dispatch,
																	type: productData?.isEUD ? 'EUDcart' : 'myCart'
																})
															);

															// message.success("Product Added To Cart.")
														}}
													>
														<ShoppingCartOutlined /> Add
													</Button>
												</Space>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</Col>
			</Row>
			{
				productData?.catType === 'labware' && productData?.descriptionImg &&
				<Row >
					<Col span={10}></Col>
					<Col span={14} style={{ paddingRight: '40px' }}>
						<img src={productData?.descriptionImg?.url} alt="" style={{ height: '350px', width: '100%' }} />
					</Col>
				</Row>
			}

			<Row style={{ marginBottom: '20px', padding: '40px' }}>
				<Col span={24}>
					{

						productData?.catType ?
							<Descriptions
								column={{
									xxl: 4,
									xl: 3,
									lg: 2,
									md: 1,
									sm: 1,
								}}
								title={Object.keys(chemicalAttribute).length !== 0 ? <h3> Product Specifications </h3> : ''}
								bordered
							>
								{chemicalAttribute?.outline && <Descriptions.Item span={{
									xxl: 4,
									xl: 3,
									lg: 2,
									md: 1,
									sm: 1,
								}} label={<b>Outline</b>}>{chemicalAttribute?.outline}</Descriptions.Item>}
								{chemicalAttribute?.grade && <Descriptions.Item label={<b>Grade</b>}>{chemicalAttribute?.grade}</Descriptions.Item>}
								{chemicalAttribute?.manufacturer && <Descriptions.Item label={<b>Manufacturer</b>}>{chemicalAttribute?.manufacturer}</Descriptions.Item>}
								{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<b>Specification Assay</b>}>{chemicalAttribute?.specificationAssay}</Descriptions.Item>}
								{chemicalAttribute?.storeCondition && <Descriptions.Item label={<b>Store Condition</b>}>{chemicalAttribute?.storeCondition}</Descriptions.Item>}
								{chemicalAttribute?.modecularFormula && <Descriptions.Item label={<b>Molecular Formula</b>}>{chemicalAttribute?.modecularFormula}</Descriptions.Item>}
								{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<b>Molecular Weight</b>}>{chemicalAttribute?.molecularWeight}</Descriptions.Item>}
								{chemicalAttribute?.GHS && <Descriptions.Item label={<b>GHS</b>}>{chemicalAttribute?.GHS}</Descriptions.Item>}
								{chemicalAttribute?.CAS && <Descriptions.Item label={<b>CAS</b>}>{chemicalAttribute?.CAS}</Descriptions.Item>}
								{chemicalAttribute?.barcoderNumber && <Descriptions.Item label={<b>BarCode Number</b>}>{chemicalAttribute?.barcoderNumber}</Descriptions.Item>}
								{chemicalAttribute?.precautionsForUse && <Descriptions.Item label={<b>Precautions For Use</b>}>{chemicalAttribute?.precautionsForUse}</Descriptions.Item>}
								{chemicalAttribute?.appearance && <Descriptions.Item label={<b>Appearance</b>}>{chemicalAttribute?.appearance}</Descriptions.Item>}
								{chemicalAttribute?.meltingPoint && <Descriptions.Item label={<b>Melting Point</b>}>{chemicalAttribute?.meltingPoint}</Descriptions.Item>}
								{chemicalAttribute?.activity && <Descriptions.Item label={<b>Activity</b>}>{chemicalAttribute?.activity}</Descriptions.Item>}
								{chemicalAttribute?.boilingPoint && <Descriptions.Item label={<b>Boiling Point</b>}>{chemicalAttribute?.boilingPoint}</Descriptions.Item>}
								{chemicalAttribute?.freezingPoint && <Descriptions.Item label={<b>Freezing Point</b>}>{chemicalAttribute?.freezingPoint}</Descriptions.Item>}
								{chemicalAttribute?.density && <Descriptions.Item label={<b>Density</b>}>{chemicalAttribute?.density}</Descriptions.Item>}
								{chemicalAttribute?.features && <Descriptions.Item label={<b>Features</b>}>{chemicalAttribute?.features}</Descriptions.Item>}
								{chemicalAttribute?.purpose && <Descriptions.Item label={<b>Purpose</b>}>{chemicalAttribute?.purpose}</Descriptions.Item>}
								{chemicalAttribute?.quantity && <Descriptions.Item label={<b>Quantity</b>}>{chemicalAttribute?.quantity}</Descriptions.Item>}
								{chemicalAttribute?.purity && <Descriptions.Item label={<b>Purity</b>}>{chemicalAttribute?.purity}</Descriptions.Item>}
								{chemicalAttribute?.flashPoint && <Descriptions.Item label={<b>Flash Point</b>}>{chemicalAttribute?.flashPoint}</Descriptions.Item>}
								{chemicalAttribute?.ignitionPoint && <Descriptions.Item label={<b>Ignition Point</b>}>{chemicalAttribute?.ignitionPoint}</Descriptions.Item>}
								{chemicalAttribute?.refractiveIndex && <Descriptions.Item label={<b>Refractive Index</b>}>{chemicalAttribute?.refractiveIndex}</Descriptions.Item>}
								{chemicalAttribute?.solubility && <Descriptions.Item label={<b>Solubility</b>}>{chemicalAttribute?.solubility}</Descriptions.Item>}
								{chemicalAttribute?.toxicity && <Descriptions.Item label={<b>Toxicity</b>}>{chemicalAttribute?.toxicity}</Descriptions.Item>}
								{chemicalAttribute?.origin && <Descriptions.Item label={<b>Origin</b>}>{chemicalAttribute?.origin}</Descriptions.Item>}
								{chemicalAttribute?.specificRotation && <Descriptions.Item label={<b>Specific Rotation</b>}>{chemicalAttribute?.specificRotation}</Descriptions.Item>}
								{chemicalAttribute?.labeling && <Descriptions.Item label={<b>Labeling</b>}>{chemicalAttribute?.labeling}</Descriptions.Item>}
								{chemicalAttribute?.alias && <Descriptions.Item label={<b>Alias</b>}>{chemicalAttribute?.alias}</Descriptions.Item>}
								{chemicalAttribute?.ph && <Descriptions.Item label={<b>PH</b>}>{chemicalAttribute?.ph}</Descriptions.Item>}
								{chemicalAttribute?.concentration && <Descriptions.Item label={<b>Concentration</b>}>{chemicalAttribute?.concentration}</Descriptions.Item>}
								{chemicalAttribute?.specificGravity && <Descriptions.Item label={<b>Specific Gravity</b>}>{chemicalAttribute?.specificGravity}</Descriptions.Item>}
								{chemicalAttribute?.composition && <Descriptions.Item label={<b>Composition</b>}>{chemicalAttribute?.composition}</Descriptions.Item>}
								{chemicalAttribute?.property && <Descriptions.Item label={<b>Property</b>}>{chemicalAttribute?.property}</Descriptions.Item>}
								{chemicalAttribute?.host && <Descriptions.Item label={<b>Host</b>}>{chemicalAttribute?.host}</Descriptions.Item>}
								{chemicalAttribute?.classification && <Descriptions.Item label={<b>Classification</b>}>{chemicalAttribute?.classification}</Descriptions.Item>}
								{chemicalAttribute?.antigen && <Descriptions.Item label={<b>Antigen</b>}>{chemicalAttribute?.antigen}</Descriptions.Item>}
								{chemicalAttribute?.antigenAlias && <Descriptions.Item label={<b>Antigen Alias</b>}>{chemicalAttribute?.antigenAlias}</Descriptions.Item>}
								{chemicalAttribute?.cloneNumber && <Descriptions.Item label={<b>Clone Number</b>}>{chemicalAttribute?.cloneNumber}</Descriptions.Item>}
								{chemicalAttribute?.subclass && <Descriptions.Item label={<b>Subclass</b>}>{chemicalAttribute?.subclass}</Descriptions.Item>}
								{chemicalAttribute?.crossReactivity && <Descriptions.Item label={<b>Cross Reactivity</b>}>{chemicalAttribute?.crossReactivity}</Descriptions.Item>}
								{chemicalAttribute?.specificity && <Descriptions.Item label={<b>Specificity</b>}>{chemicalAttribute?.specificity}</Descriptions.Item>}
								{chemicalAttribute?.preparation && <Descriptions.Item label={<b>Preparation</b>}>{chemicalAttribute?.preparation}</Descriptions.Item>}
								{chemicalAttribute?.instructions && <Descriptions.Item label={<b>Instructions</b>}>{chemicalAttribute?.instructions}</Descriptions.Item>}
								{chemicalAttribute?.formulation && <Descriptions.Item label={<b>Formulation</b>}>{chemicalAttribute?.formulation}</Descriptions.Item>}
								{chemicalAttribute?.label && <Descriptions.Item label={<b>Label</b>}>{chemicalAttribute?.label}</Descriptions.Item>}
								{chemicalAttribute?.ELISA && <Descriptions.Item label={<b>ELISA</b>}>{chemicalAttribute?.ELISA}</Descriptions.Item>}
								{chemicalAttribute?.westernBlot && <Descriptions.Item label={<b>Western Blot</b>}>{chemicalAttribute?.westernBlot}</Descriptions.Item>}
								{chemicalAttribute?.flowCytometry && <Descriptions.Item label={<b>Flow Cytometry</b>}>{chemicalAttribute?.flowCytometry}</Descriptions.Item>}
								{chemicalAttribute?.immunoprecipitation && <Descriptions.Item label={<b>Immuno Precipitation</b>}>{chemicalAttribute?.immunoprecipitation}</Descriptions.Item>}
								{chemicalAttribute?.reactivity && <Descriptions.Item label={<b>Reactivity</b>}>{chemicalAttribute?.reactivity}</Descriptions.Item>}
								{chemicalAttribute?.isotype && <Descriptions.Item label={<b>Isotype</b>}>{chemicalAttribute?.isotype}</Descriptions.Item>}
								{chemicalAttribute?.buffer && <Descriptions.Item label={<b>Buffer</b>}>{chemicalAttribute?.buffer}</Descriptions.Item>}
								{chemicalAttribute?.reference && <Descriptions.Item label={<b>Reference</b>}>{chemicalAttribute?.reference}</Descriptions.Item>}

							</Descriptions> : ""
					}

				</Col>
			</Row>
		</>
	)
}

const WeightProductDetail = ({ productData, activeImg, setActiveImg, productVarient, cartData, dispatch }) => {
	const userInfo = useSelector(state => state.user);
	const [varient, setVarient] = useState({});
	const [chemicalAttribute, setChemicalAttribute] = useState({});
	const [color, setColor] = useState('');

	const handleVarient = (val) => {
		setVarient(val)
		setColor(val._id)
	}



	function chemicalAttributeDetails() {
		/* API call for */
		chemicalService
			.details(productData?._id)
			.then((res) => {
				setChemicalAttribute(res.data || {})
			})
			.catch((err) => { });
	}
	useEffect(() => {

		setVarient(productVarient[0])
		setColor(productVarient[0]?._id)
	}, [productVarient])

	useEffect(() => {
		if (productData?.catType) {
			chemicalAttributeDetails()
		}
	}, [])



	return (
		<>
			<Row>
				<Col span={10}>
					<Card
						style={{
							borderTop: "1px solid lightgrey",
							borderBottom: "none",
						}}
					>
						<Row style={{ marginTop: 20 }}>
							<Col span={6} style={{ maxHeight: '350px' }}>
								<Carousel
									autoplay={false}
									className="product-detail-slider-dashboard"
									dots={false}
									nextArrow={<span />}
									prevArrow={<span />}
									arrows={true}
									infinite={false}
									// slidesToShow={(productData.imgs?.length - 1)}
									slidesToShow={3}
									dotPosition={"left"}
									arrowPosition={"top"}
								>
									{
										productData.imgs?.map(v => (
											<div key={v?.uid} onClick={() => { setActiveImg(v) }}>
												<div style={{ padding: 5, margin: 5, border: '1px solid grey', width: '100px', borderRadius: 4, cursor: 'pointer' }}>
													<Image width={100} height={100} preview={false} src={v?.url} alt="img" />
												</div>
											</div>
										))
									}
								</Carousel>
							</Col>
							<Col span={18} style={{ padding: 20, margin: "auto", display: "flex" }}>
								<Image style={{ height: "400px", margin: "auto" }} preview src={activeImg?.url} alt="img" />
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={14}>
					<Card style={{ background: 'rgb(245 245 245)', paddingLeft: '35px', paddingTop: '50px', paddingBottom: '30px', border: 'none', maxHeight: '700px' }}>
						<Space direction="vertical">
							<Space direction="vertical">
								<span style={{ fontSize: '24px', fontWeight: 'bold' }}>
									{productData.name}
								</span>
								<span style={{ fontSize: '16px', fontWeight: 500 }}>
									{productData?.metaTag}
								</span>
							</Space>
							{
								userInfo.isLoggedin ?
									<Space direction="horizontal" size={20}>
										<span style={{ fontSize: '20px', fontWeight: 'bold' }}>Price</span>
										<span style={{ fontSize: '26px', fontWeight: 'bold', color: 'purple' }}>
											{/* ₹{productData.startingPrice} */}
											₹{
												productVarient?.find(v => v._id === varient?._id)?.price || 0
											}
										</span>
									</Space> : ""
							}

							<span style={{ fontSize: '16px', fontWeight: 600, }}>
								{productData.metaDescription}
							</span>
							{
								productData?.catType ?
									<Descriptions column={{
										xxl: 4,
										xl: 3,
										lg: 2,
										md: 1,
										sm: 1,
									}}>
										{chemicalAttribute?.grade && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Grade</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.grade}</span> </Descriptions.Item>}
										{chemicalAttribute?.CAS && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>CAS</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.CAS}</span> </Descriptions.Item>}
										{chemicalAttribute?.GHS && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>GHS</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.GHS}</span> </Descriptions.Item>}
										{chemicalAttribute?.manufacturer && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Manufacturer</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.manufacturer}</span> </Descriptions.Item>}
										{chemicalAttribute?.storeCondition && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Storage Condition</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.storeCondition}</span> </Descriptions.Item>}
										{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Molecular Weight</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.molecularWeight}</span> </Descriptions.Item>}
									</Descriptions> : ""
							}
							{
								productData?.catType ?
									<Descriptions >
										{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<span style={{ fontWeight: 500, fontSize: '16px' }}>Specification Assay</span>}> <span style={{ fontWeight: 400, fontSize: '14px', marginTop: '2px' }}>{chemicalAttribute?.specificationAssay}</span> </Descriptions.Item>}
									</Descriptions> : ""
							}
							<Space style={{ marginTop: "20px" }} direction="horizontal">
								<Button style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
									onClick={() => dispatch(decrement(varient._id))}
								>-</Button>
								<Button>
									{cartData?.find(v => v.productVariantId === varient?._id)?.quantity || 0}
								</Button>
								<Button onClick={() => dispatch(increment(varient._id))} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}>
									+</Button>

							</Space>
							<Button
								onClick={() => {

									dispatch(
										incrementWithSave({
											productVariantId: (cartData?.find(v => v.productVariantId === varient._id))?.productVariantId,
											quantity: (cartData?.find(v => v.productVariantId === varient?._id)?.quantity || 0),
											dispatch,
											type: productData?.isEUD ? 'EUDcart' : 'myCart'
										})
									);
									// message.success("Product Added To Cart.")
								}}
								style={{
									background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight:
										"500", width: 140, height: '42px'
								}}>

								<ShoppingCartOutlined /> Add to Cart</Button>
							<span style={{ fontSize: '19px', fontWeight: 'bold' }}>Select QTY :</span>
							<Space direction="horizontal" style={{ display: "flex", flexWrap: "wrap" }}>
								{
									productVarient?.map((val, i) => (
										<ColorButton
											key={i}
											onClick={() => handleVarient(val)}
											qty={val?.volume}
											select={color === val._id ? true : false}
										/>
									))
								}
							</Space>
						</Space>
					</Card>
				</Col>
			</Row>
			<div style={{ paddingRight: '80px', paddingLeft: '80px', paddingBottom: '20px' }}>
				<h2> Product Description</h2>
				<Divider />
			</div>
			<Row style={{ paddingRight: '60px', marginTop: 0, paddingLeft: '60px', paddingBottom: '20px', }}>
				<Col span={16}>
					<Card style={{ border: 'none' }}>
						<Space direction="vertical" size={30}>
							<span style={{ fontSize: '24px', fontWeight: 'bold' }}>{productData?.name} {productData?.subName}</span>

							<div style={{  fontWeight: 600, color: 'grey' }} className="html-text-content" dangerouslySetInnerHTML={{ __html: productData?.description }} />

							<span style={{ fontSize: '16px', fontWeight: 600, color: 'grey' }}>
								{productData?.subDescription}
							</span>


						</Space>
					</Card>
				</Col>
				<Col span={8}>
					<Card style={{ border: 'none' }}>
						<img src={productData?.thumbImg?.url} alt="" style={{ width: '100%', height: '100%' }} />
					</Card>

				</Col>
			</Row>
			<Row style={{ marginBottom: '20px', padding: '40px' }}>
				<Col span={24}>
					{
						productData?.catType ?

							<Descriptions
								title={Object.keys(chemicalAttribute).length !== 0 ? <h3> Product Specifications </h3> : ''}
								bordered
								column={{
									xxl: 4,
									xl: 3,
									lg: 2,
									md: 1,
									sm: 1,
								}}
							>
								{chemicalAttribute?.outline && <Descriptions.Item span={3} label={<b>Outline</b>}>{chemicalAttribute?.outline}</Descriptions.Item>}
								{chemicalAttribute?.grade && <Descriptions.Item label={<b>Grade</b>}>{chemicalAttribute?.grade}</Descriptions.Item>}
								{chemicalAttribute?.manufacturer && <Descriptions.Item label={<b>Manufacturer</b>}>{chemicalAttribute?.manufacturer}</Descriptions.Item>}
								{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<b>Specification Assay</b>}>{chemicalAttribute?.specificationAssay}</Descriptions.Item>}
								{chemicalAttribute?.storeCondition && <Descriptions.Item label={<b>Store Condition</b>}>{chemicalAttribute?.storeCondition}</Descriptions.Item>}
								{chemicalAttribute?.modecularFormula && <Descriptions.Item label={<b>Molecular Formula</b>}>{chemicalAttribute?.modecularFormula}</Descriptions.Item>}
								{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<b>Molecular Weight</b>}>{chemicalAttribute?.molecularWeight}</Descriptions.Item>}
								{chemicalAttribute?.GHS && <Descriptions.Item label={<b>GHS</b>}>{chemicalAttribute?.GHS}</Descriptions.Item>}
								{chemicalAttribute?.CAS && <Descriptions.Item label={<b>CAS</b>}>{chemicalAttribute?.CAS}</Descriptions.Item>}
								{chemicalAttribute?.barcoderNumber && <Descriptions.Item label={<b>BarCode Number</b>}>{chemicalAttribute?.barcoderNumber}</Descriptions.Item>}
								{chemicalAttribute?.precautionsForUse && <Descriptions.Item label={<b>Precautions For Use</b>}>{chemicalAttribute?.precautionsForUse}</Descriptions.Item>}
								{chemicalAttribute?.appearance && <Descriptions.Item label={<b>Appearance</b>}>{chemicalAttribute?.appearance}</Descriptions.Item>}
								{chemicalAttribute?.meltingPoint && <Descriptions.Item label={<b>Melting Point</b>}>{chemicalAttribute?.meltingPoint}</Descriptions.Item>}
								{chemicalAttribute?.activity && <Descriptions.Item label={<b>Activity</b>}>{chemicalAttribute?.activity}</Descriptions.Item>}
								{chemicalAttribute?.boilingPoint && <Descriptions.Item label={<b>Boiling Point</b>}>{chemicalAttribute?.boilingPoint}</Descriptions.Item>}
								{chemicalAttribute?.freezingPoint && <Descriptions.Item label={<b>Freezing Point</b>}>{chemicalAttribute?.freezingPoint}</Descriptions.Item>}
								{chemicalAttribute?.density && <Descriptions.Item label={<b>Density</b>}>{chemicalAttribute?.density}</Descriptions.Item>}
								{chemicalAttribute?.features && <Descriptions.Item label={<b>Features</b>}>{chemicalAttribute?.features}</Descriptions.Item>}
								{chemicalAttribute?.purpose && <Descriptions.Item label={<b>Purpose</b>}>{chemicalAttribute?.purpose}</Descriptions.Item>}
								{chemicalAttribute?.quantity && <Descriptions.Item label={<b>Quantity</b>}>{chemicalAttribute?.quantity}</Descriptions.Item>}
								{chemicalAttribute?.purity && <Descriptions.Item label={<b>Purity</b>}>{chemicalAttribute?.purity}</Descriptions.Item>}
								{chemicalAttribute?.flashPoint && <Descriptions.Item label={<b>Flash Point</b>}>{chemicalAttribute?.flashPoint}</Descriptions.Item>}
								{chemicalAttribute?.ignitionPoint && <Descriptions.Item label={<b>Ignition Point</b>}>{chemicalAttribute?.ignitionPoint}</Descriptions.Item>}
								{chemicalAttribute?.refractiveIndex && <Descriptions.Item label={<b>Refractive Index</b>}>{chemicalAttribute?.refractiveIndex}</Descriptions.Item>}
								{chemicalAttribute?.solubility && <Descriptions.Item label={<b>Solubility</b>}>{chemicalAttribute?.solubility}</Descriptions.Item>}
								{chemicalAttribute?.toxicity && <Descriptions.Item label={<b>Toxicity</b>}>{chemicalAttribute?.toxicity}</Descriptions.Item>}
								{chemicalAttribute?.origin && <Descriptions.Item label={<b>Origin</b>}>{chemicalAttribute?.origin}</Descriptions.Item>}
								{chemicalAttribute?.specificRotation && <Descriptions.Item label={<b>Specific Rotation</b>}>{chemicalAttribute?.specificRotation}</Descriptions.Item>}
								{chemicalAttribute?.labeling && <Descriptions.Item label={<b>Labeling</b>}>{chemicalAttribute?.labeling}</Descriptions.Item>}
								{chemicalAttribute?.alias && <Descriptions.Item label={<b>Alias</b>}>{chemicalAttribute?.alias}</Descriptions.Item>}
								{chemicalAttribute?.ph && <Descriptions.Item label={<b>PH</b>}>{chemicalAttribute?.ph}</Descriptions.Item>}
								{chemicalAttribute?.concentration && <Descriptions.Item label={<b>Concentration</b>}>{chemicalAttribute?.concentration}</Descriptions.Item>}
								{chemicalAttribute?.specificGravity && <Descriptions.Item label={<b>Specific Gravity</b>}>{chemicalAttribute?.specificGravity}</Descriptions.Item>}
								{chemicalAttribute?.composition && <Descriptions.Item label={<b>Composition</b>}>{chemicalAttribute?.composition}</Descriptions.Item>}
								{chemicalAttribute?.property && <Descriptions.Item label={<b>Property</b>}>{chemicalAttribute?.property}</Descriptions.Item>}
								{chemicalAttribute?.host && <Descriptions.Item label={<b>Host</b>}>{chemicalAttribute?.host}</Descriptions.Item>}
								{chemicalAttribute?.classification && <Descriptions.Item label={<b>Classification</b>}>{chemicalAttribute?.classification}</Descriptions.Item>}
								{chemicalAttribute?.antigen && <Descriptions.Item label={<b>Antigen</b>}>{chemicalAttribute?.antigen}</Descriptions.Item>}
								{chemicalAttribute?.antigenAlias && <Descriptions.Item label={<b>Antigen Alias</b>}>{chemicalAttribute?.antigenAlias}</Descriptions.Item>}
								{chemicalAttribute?.cloneNumber && <Descriptions.Item label={<b>Clone Number</b>}>{chemicalAttribute?.cloneNumber}</Descriptions.Item>}
								{chemicalAttribute?.subclass && <Descriptions.Item label={<b>Subclass</b>}>{chemicalAttribute?.subclass}</Descriptions.Item>}
								{chemicalAttribute?.crossReactivity && <Descriptions.Item label={<b>Cross Reactivity</b>}>{chemicalAttribute?.crossReactivity}</Descriptions.Item>}
								{chemicalAttribute?.specificity && <Descriptions.Item label={<b>Specificity</b>}>{chemicalAttribute?.specificity}</Descriptions.Item>}
								{chemicalAttribute?.preparation && <Descriptions.Item label={<b>Preparation</b>}>{chemicalAttribute?.preparation}</Descriptions.Item>}
								{chemicalAttribute?.instructions && <Descriptions.Item label={<b>Instructions</b>}>{chemicalAttribute?.instructions}</Descriptions.Item>}
								{chemicalAttribute?.formulation && <Descriptions.Item label={<b>Formulation</b>}>{chemicalAttribute?.formulation}</Descriptions.Item>}
								{chemicalAttribute?.label && <Descriptions.Item label={<b>Label</b>}>{chemicalAttribute?.label}</Descriptions.Item>}
								{chemicalAttribute?.ELISA && <Descriptions.Item label={<b>ELISA</b>}>{chemicalAttribute?.ELISA}</Descriptions.Item>}
								{chemicalAttribute?.westernBlot && <Descriptions.Item label={<b>Western Blot</b>}>{chemicalAttribute?.westernBlot}</Descriptions.Item>}
								{chemicalAttribute?.flowCytometry && <Descriptions.Item label={<b>Flow Cytometry</b>}>{chemicalAttribute?.flowCytometry}</Descriptions.Item>}
								{chemicalAttribute?.immunoprecipitation && <Descriptions.Item label={<b>Immuno Precipitation</b>}>{chemicalAttribute?.immunoprecipitation}</Descriptions.Item>}
								{chemicalAttribute?.reactivity && <Descriptions.Item label={<b>Reactivity</b>}>{chemicalAttribute?.reactivity}</Descriptions.Item>}
								{chemicalAttribute?.isotype && <Descriptions.Item label={<b>Isotype</b>}>{chemicalAttribute?.isotype}</Descriptions.Item>}
								{chemicalAttribute?.buffer && <Descriptions.Item label={<b>Buffer</b>}>{chemicalAttribute?.buffer}</Descriptions.Item>}
								{chemicalAttribute?.reference && <Descriptions.Item label={<b>Reference</b>}>{chemicalAttribute?.reference}</Descriptions.Item>}

							</Descriptions> : ""
					}

				</Col>
			</Row>
		</>
	)
}

const ColorButton = ({ onClick, qty, select }) => {
	const style = {
		button: {
			background: select ? "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)" : " linear-gradient(94deg, rgba(223,223,223,1) 0%, rgba(223,223,223,1) 100%)",
			color: select ? 'white' : "black",
			fontWeight: "500",
			// width: 90,
			height: '34px'
		}
	}
	return (
		<Button onClick={onClick} style={style.button}>{qty}</Button>
	)
}

const PhoneViewWeightProductDetail = ({ productData, activeImg, setActiveImg, productVarient, cartData, dispatch }) => {
	const userInfo = useSelector(state => state.user);
	const [varient, setVarient] = useState({});
	const [chemicalAttribute, setChemicalAttribute] = useState({});
	const handleVarient = (val) => {
		setVarient(val)
	}

	useEffect(() => {
		setVarient(productVarient[0]?._id)
	}, [productVarient])

	function chemicalAttributeDetails() {
		/* API call for */
		chemicalService
			.details(productData?._id)
			.then((res) => {
				setChemicalAttribute(res.data || {})
			})
			.catch((err) => { });
	}

	useEffect(() => {
		if (productData?.catType) {
			chemicalAttributeDetails()

		}
	}, [])

	return (
		<>
			<Row>
				<Col span={24} style={{ padding: 15, justifyContent: 'center', display: "flex" }}>
					<Image style={{ height: "280px", maxWidth: '100%' }} preview src={activeImg?.url} alt="img" />
				</Col>
				<Col span={24}>

					<Carousel
						autoplay={false}
						infinite={false}
						dots={false}
						// slidesToShow={(productData.imgs?.length - 1)}
						slidesToShow={3}
						dotPosition={"bottom"}
						arrowPosition={"bottom"}
					>
						{
							productData.imgs?.map(v => (
								<div key={v.uid} onClick={() => { setActiveImg(v) }}>
									<div style={{ padding: 5, margin: 5, border: '1px solid grey', width: '100px', borderRadius: 4, cursor: 'pointer' }}>
										<Image width={100} height={100} preview={false} src={v.url} alt="img" />
									</div>
								</div>
							))
						}
					</Carousel>
				</Col>
			</Row>
			<Col span={24}>
				{/* <div style={{ background: 'rgb(245 245 245)', border: 'none',  maxHeight: '700px' }}> */}
				<div style={{ background: 'rgb(245 245 245)', padding: '25px 20px 25px 18px' }}>
					<Space direction="vertical">
						<span style={{ fontSize: '21px', fontWeight: 'bold' }}>
							{productData.name} {productData?.metaTitle}
						</span>
						<span style={{ fontSize: '12px', fontWeight: 500 }}>
							{productData?.metaTag}
						</span>
					</Space>
					<div>
						{
							userInfo.isLoggedin ?


								<Space direction="horizontal" size={20}>
									<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Price</span>
									<span style={{ fontSize: '24px', fontWeight: 'bold', color: 'purple' }}>
										{/* ₹{productData.startingPrice} */}
										₹{
											productVarient?.find(v => v?._id === varient)?.price || 0
										}
									</span>
								</Space> : ""
						}

					</div>
					<p style={{ fontSize: '14px', fontWeight: 600, }}>
						{productData.metaDescription}
					</p>
					{/* <div style={{ display: 'flex', padding: 0, margin: 0, }} > */}
					{
						productData?.catType ?
							<Descriptions column={2}>
								{chemicalAttribute?.grade && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Grade</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.grade}</span> </Descriptions.Item>}
								{chemicalAttribute?.CAS && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>CAS</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.CAS}</span> </Descriptions.Item>}
								{chemicalAttribute?.storeCondition && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Storage Condition</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.storeCondition}</span> </Descriptions.Item>}
								{chemicalAttribute?.GHS && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>GHS</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.GHS}</span> </Descriptions.Item>}
								{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Molecular Weight</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.molecularWeight}</span> </Descriptions.Item>}
								{chemicalAttribute?.manufacturer && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Manufacturer</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.manufacturer}</span> </Descriptions.Item>}
							</Descriptions> : ""
					}
					{
						productData?.catType ?
							<Descriptions>
								{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<span style={{ fontWeight: 'bold', fontSize: '12px' }}>Specification Assay</span>}> <span style={{ fontWeight: 400, fontSize: '12px', marginTop: '2px' }}>{chemicalAttribute?.specificationAssay}</span> </Descriptions.Item>}

							</Descriptions> : ""
					}
					{/* </div> */}
					<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Space direction="vertical">
							<span style={{ fontSize: '12px', fontWeight: 'bold', }}>Select QTY :</span>
							<Space direction="horizontal">
								<Button style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
									onClick={() => dispatch(decrement(varient))}
								>-</Button>
								<Button>
									{cartData?.find(v => v?.productVariantId === varient)?.quantity || 0}
								</Button>
								<Button onClick={() => dispatch(increment(varient))} style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}>
									+</Button>
							</Space>
						</Space>

						<Space direction="vertical">
							<span style={{ fontSize: '12px', fontWeight: 'bold' }}>Select Pack :</span>
							<Space direction="horizontal">
								<Select
									value={productVarient?.find(v => v?._id === varient)?.volume}
									onChange={(val) => (handleVarient(val))}
									options={productVarient?.map((v) => ({
										value: v?._id,
										label: v?.volume,
									}))}
								></Select>
							</Space>
						</Space>
					</div>

					<Button
						onClick={() => {

							dispatch(
								incrementWithSave({
									productVariantId: (cartData?.find(v => v.productVariantId === varient))?.productVariantId,
									quantity: (cartData?.find(v => v.productVariantId === varient)?.quantity || 0),
									dispatch,
									type: productData?.isEUD ? 'EUDcart' : 'myCart'
								})
							);
							// message.success("Product Added to cart.");

						}}
						style={{
							background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight:
								"500", width: '100%', height: '35px', marginTop: '10px'
						}}>

						<ShoppingCartOutlined /> Add to Cart</Button>

				</div>
			</Col>

			<div style={{ paddingLeft: '20px', paddingRight: '18px' }}>
				<h2> Product Description</h2>
				<Divider style={{ margin: 0 }} />
			</div>
			<Row style={{ marginTop: 0, padding: '36px 24px 26px 24px' }}>

				<Col span={24}>
					{/* <Card style={{ border: 'none' }}> */}
					<Space direction="vertical" size={30}>
						<span style={{ fontSize: '18px', fontWeight: 400 }}>Product Name & Product Title</span>

						<div style={{  fontWeight: 600, color: 'grey' }} className="html-text-content" dangerouslySetInnerHTML={{ __html: productData?.description }} />

						<span style={{ fontSize: '14px', fontWeight: 600, color: 'grey' }}>
							{productData?.subDescription}
						</span>
					</Space>
					{/* </Card> */}
				</Col>
				<Col span={24}>
					<Card style={{ border: 'none', paddingLeft: '50px', paddingRight: '50px' }}>
						<img src={productData?.thumbImg?.url} alt="" style={{ width: '80%', height: '80%' }} />
					</Card>

				</Col>
				<Col span={24}>
					{
						productData?.catType ?

							<Descriptions
								title={Object.keys(chemicalAttribute).length !== 0 ? <h3> Product Specifications </h3> : ''}
								bordered
							>
								{chemicalAttribute?.outline && <Descriptions.Item label={<b>Outline</b>}>{chemicalAttribute?.outline}</Descriptions.Item>}
								{chemicalAttribute?.grade && <Descriptions.Item label={<b>Grade</b>}>{chemicalAttribute?.grade}</Descriptions.Item>}
								{chemicalAttribute?.manufacturer && <Descriptions.Item label={<b>Manufacturer</b>}>{chemicalAttribute?.manufacturer}</Descriptions.Item>}
								{chemicalAttribute?.specificationAssay && <Descriptions.Item label={<b>Specification Assay</b>}>{chemicalAttribute?.specificationAssay}</Descriptions.Item>}
								{chemicalAttribute?.storeCondition && <Descriptions.Item label={<b>Store Condition</b>}>{chemicalAttribute?.storeCondition}</Descriptions.Item>}
								{chemicalAttribute?.modecularFormula && <Descriptions.Item label={<b>Molecular Formula</b>}>{chemicalAttribute?.modecularFormula}</Descriptions.Item>}
								{chemicalAttribute?.molecularWeight && <Descriptions.Item label={<b>Molecular Weight</b>}>{chemicalAttribute?.molecularWeight}</Descriptions.Item>}
								{chemicalAttribute?.GHS && <Descriptions.Item label={<b>GHS</b>}>{chemicalAttribute?.GHS}</Descriptions.Item>}
								{chemicalAttribute?.CAS && <Descriptions.Item label={<b>CAS</b>}>{chemicalAttribute?.CAS}</Descriptions.Item>}
								{chemicalAttribute?.barcoderNumber && <Descriptions.Item label={<b>BarCode Number</b>}>{chemicalAttribute?.barcoderNumber}</Descriptions.Item>}
								{chemicalAttribute?.precautionsForUse && <Descriptions.Item label={<b>Precautions For Use</b>}>{chemicalAttribute?.precautionsForUse}</Descriptions.Item>}
								{chemicalAttribute?.appearance && <Descriptions.Item label={<b>Appearance</b>}>{chemicalAttribute?.appearance}</Descriptions.Item>}
								{chemicalAttribute?.meltingPoint && <Descriptions.Item label={<b>Melting Point</b>}>{chemicalAttribute?.meltingPoint}</Descriptions.Item>}
								{chemicalAttribute?.activity && <Descriptions.Item label={<b>Activity</b>}>{chemicalAttribute?.activity}</Descriptions.Item>}
								{chemicalAttribute?.boilingPoint && <Descriptions.Item label={<b>Boiling Point</b>}>{chemicalAttribute?.boilingPoint}</Descriptions.Item>}
								{chemicalAttribute?.freezingPoint && <Descriptions.Item label={<b>Freezing Point</b>}>{chemicalAttribute?.freezingPoint}</Descriptions.Item>}
								{chemicalAttribute?.density && <Descriptions.Item label={<b>Density</b>}>{chemicalAttribute?.density}</Descriptions.Item>}
								{chemicalAttribute?.features && <Descriptions.Item label={<b>Features</b>}>{chemicalAttribute?.features}</Descriptions.Item>}
								{chemicalAttribute?.purpose && <Descriptions.Item label={<b>Purpose</b>}>{chemicalAttribute?.purpose}</Descriptions.Item>}
								{chemicalAttribute?.quantity && <Descriptions.Item label={<b>Quantity</b>}>{chemicalAttribute?.quantity}</Descriptions.Item>}
								{chemicalAttribute?.purity && <Descriptions.Item label={<b>Purity</b>}>{chemicalAttribute?.purity}</Descriptions.Item>}
								{chemicalAttribute?.flashPoint && <Descriptions.Item label={<b>Flash Point</b>}>{chemicalAttribute?.flashPoint}</Descriptions.Item>}
								{chemicalAttribute?.ignitionPoint && <Descriptions.Item label={<b>Ignition Point</b>}>{chemicalAttribute?.ignitionPoint}</Descriptions.Item>}
								{chemicalAttribute?.refractiveIndex && <Descriptions.Item label={<b>Refractive Index</b>}>{chemicalAttribute?.refractiveIndex}</Descriptions.Item>}
								{chemicalAttribute?.solubility && <Descriptions.Item label={<b>Solubility</b>}>{chemicalAttribute?.solubility}</Descriptions.Item>}
								{chemicalAttribute?.toxicity && <Descriptions.Item label={<b>Toxicity</b>}>{chemicalAttribute?.toxicity}</Descriptions.Item>}
								{chemicalAttribute?.origin && <Descriptions.Item label={<b>Origin</b>}>{chemicalAttribute?.origin}</Descriptions.Item>}
								{chemicalAttribute?.specificRotation && <Descriptions.Item label={<b>Specific Rotation</b>}>{chemicalAttribute?.specificRotation}</Descriptions.Item>}
								{chemicalAttribute?.labeling && <Descriptions.Item label={<b>Labeling</b>}>{chemicalAttribute?.labeling}</Descriptions.Item>}
								{chemicalAttribute?.alias && <Descriptions.Item label={<b>Alias</b>}>{chemicalAttribute?.alias}</Descriptions.Item>}
								{chemicalAttribute?.ph && <Descriptions.Item label={<b>PH</b>}>{chemicalAttribute?.ph}</Descriptions.Item>}
								{chemicalAttribute?.concentration && <Descriptions.Item label={<b>Concentration</b>}>{chemicalAttribute?.concentration}</Descriptions.Item>}
								{chemicalAttribute?.specificGravity && <Descriptions.Item label={<b>Specific Gravity</b>}>{chemicalAttribute?.specificGravity}</Descriptions.Item>}
								{chemicalAttribute?.composition && <Descriptions.Item label={<b>Composition</b>}>{chemicalAttribute?.composition}</Descriptions.Item>}
								{chemicalAttribute?.property && <Descriptions.Item label={<b>Property</b>}>{chemicalAttribute?.property}</Descriptions.Item>}
								{chemicalAttribute?.host && <Descriptions.Item label={<b>Host</b>}>{chemicalAttribute?.host}</Descriptions.Item>}
								{chemicalAttribute?.classification && <Descriptions.Item label={<b>Classification</b>}>{chemicalAttribute?.classification}</Descriptions.Item>}
								{chemicalAttribute?.antigen && <Descriptions.Item label={<b>Antigen</b>}>{chemicalAttribute?.antigen}</Descriptions.Item>}
								{chemicalAttribute?.antigenAlias && <Descriptions.Item label={<b>Antigen Alias</b>}>{chemicalAttribute?.antigenAlias}</Descriptions.Item>}
								{chemicalAttribute?.cloneNumber && <Descriptions.Item label={<b>Clone Number</b>}>{chemicalAttribute?.cloneNumber}</Descriptions.Item>}
								{chemicalAttribute?.subclass && <Descriptions.Item label={<b>Subclass</b>}>{chemicalAttribute?.subclass}</Descriptions.Item>}
								{chemicalAttribute?.crossReactivity && <Descriptions.Item label={<b>Cross Reactivity</b>}>{chemicalAttribute?.crossReactivity}</Descriptions.Item>}
								{chemicalAttribute?.specificity && <Descriptions.Item label={<b>Specificity</b>}>{chemicalAttribute?.specificity}</Descriptions.Item>}
								{chemicalAttribute?.preparation && <Descriptions.Item label={<b>Preparation</b>}>{chemicalAttribute?.preparation}</Descriptions.Item>}
								{chemicalAttribute?.instructions && <Descriptions.Item label={<b>Instructions</b>}>{chemicalAttribute?.instructions}</Descriptions.Item>}
								{chemicalAttribute?.formulation && <Descriptions.Item label={<b>Formulation</b>}>{chemicalAttribute?.formulation}</Descriptions.Item>}
								{chemicalAttribute?.label && <Descriptions.Item label={<b>Label</b>}>{chemicalAttribute?.label}</Descriptions.Item>}
								{chemicalAttribute?.ELISA && <Descriptions.Item label={<b>ELISA</b>}>{chemicalAttribute?.ELISA}</Descriptions.Item>}
								{chemicalAttribute?.westernBlot && <Descriptions.Item label={<b>Western Blot</b>}>{chemicalAttribute?.westernBlot}</Descriptions.Item>}
								{chemicalAttribute?.flowCytometry && <Descriptions.Item label={<b>Flow Cytometry</b>}>{chemicalAttribute?.flowCytometry}</Descriptions.Item>}
								{chemicalAttribute?.immunoprecipitation && <Descriptions.Item label={<b>Immuno Precipitation</b>}>{chemicalAttribute?.immunoprecipitation}</Descriptions.Item>}
								{chemicalAttribute?.reactivity && <Descriptions.Item label={<b>Reactivity</b>}>{chemicalAttribute?.reactivity}</Descriptions.Item>}
								{chemicalAttribute?.isotype && <Descriptions.Item label={<b>Isotype</b>}>{chemicalAttribute?.isotype}</Descriptions.Item>}
								{chemicalAttribute?.buffer && <Descriptions.Item label={<b>Buffer</b>}>{chemicalAttribute?.buffer}</Descriptions.Item>}
								{chemicalAttribute?.reference && <Descriptions.Item label={<b>Reference</b>}>{chemicalAttribute?.reference}</Descriptions.Item>}

							</Descriptions> : ""
					}

				</Col>
			</Row>
		</>
	)
}


