import { Row, Col, Input, Space, Breadcrumb, Divider, Collapse } from "antd";
import React, { useEffect } from "react";
import { Tag, Button, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { useState } from "react";
import service from "../service/product_varient";
import wishListService from "../service/wishlist";
import orderService from "../service/order";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart, decrementWithSave, incrementWithSave } from "../redux/cart";
import { Link } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
const { Content } = Layout;

const QuickOrder = () => {
	const { Search } = Input;
	const [varients, setVarients] = useState();
	const cartData = useSelector((state) => state.cart.value || []);
	const [data, setData] = useState();
	const [search, setSearch] = useState("");
	const [searchProWish, setSearchProWish] = useState("");
	const [collapse, setCollapse] = useState("");
	const [wishListData, setWishListData] = useState([]);
	const [purchaseData, setPurchaseData] = useState([]);
	const [currentTab, setCurrentTab] = useState("purchase-history");
	const dispatch = useDispatch();

	const list = () => {
		service.list({ isAll: 1 }).then((res) => {
			setData(res.data);
		}).catch((err) => {
		});
	};

	const { token: { colorBgContainer } } = theme.useToken();

	const wishlistProVarientData = () => {
		wishListService.wishlistProVarient({ isAll: 1 }).then((res) => {
			setWishListData(res.data);
		}).catch((err) => {
			// message.warning("Error in data loading! Try again!!");
		});
	};
	const orderProductVarientData = () => {
		orderService.orderedProdVarientList({ isAll: 1 }).then((res) => {
			setPurchaseData(res.data)
			// console.log("this is purchase product ", res.data)
		}).catch((err) => {
			// message.warning("Error in data loading! Try again!!");
		});
	};
	const handleRemove = (data) => {
		service.remove({ ...data }).then((res) => list());
	};


	useEffect(() => {
		list();
		dispatch(fetchCart());
	}, []);
	useEffect(() => {
		if (search === "") {
			setVarients([]);
			return;
		}
		// setVarients(data?.filter((v) => (v.catId.match(new RegExp(search, "i")) && v.productDetails.isEUD)));
		setVarients(data?.filter((v) => (v.catId.match(new RegExp(search, "i")))));
	}, [search]);

	useEffect(() => {
		if (searchProWish !== "" && currentTab === 'purchase-history' ) {
			setPurchaseData(purchaseData?.filter((v) => (v.catId.match(new RegExp(searchProWish, "i")))));
		}else if(searchProWish !== "" && currentTab === 'wish-list'){
			setWishListData(wishListData?.filter((v) => (v.catId.match(new RegExp(searchProWish, "i")))));
		}
		else{
			orderProductVarientData()
			wishlistProVarientData()
		}
	}, [searchProWish]);

	const handleMenu = (key) => {
		setCollapse(key)
	}

	// console.log("this is cartdata", cartData)

	return (
		<>
			<Row>
				<Breadcrumb style={{ padding: '15px' }}>
					<Breadcrumb.Item>
						<Link to={"/"} style={{ fontSize: "500" }}>
							Home
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={"/quickorder"} style={{ fontSize: "500" }}>
							Quick Order
						</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<Divider style={{ margin: 0 }} />
			</Row>
			<Row>
				<Col xs={{ span: 0 }} sm={{ span: 12 }} style={{ padding: "25px" }}>
					<h2>Enter the Product CAT No. which item you would like to Order</h2>
					<Divider />
					<Row>
						<Col span={24}>
							<Search
								placeholder="Enter the Product CAT No. which item you would like to Order"
								enterButton="Search"
								size="large"
								style={{ width: "100%", background: "grey" }}
								block
								onChange={(e) => setSearch(e.target.value)}
							/>
						</Col>
					</Row>
					{varients?.length > 0 ? (
						varients?.map((v, i) => (
							<Row key={i} style={{ margin: "10px 0px" }}>
								<Col span={14} style={{ paddingTop: '20px' }}>
									<Row>
										<Col span={7}>
											<img
												alt="example"
												style={{ height: "100px", maxWidth: "120px" }}
												src={v.productDetails.thumbImg}
											/>
										</Col>
										<Col span={12}>
											<h2 style={{ marginTop: 0, marginBottom: 0 }}>
												{v?.productDetails?.name}
											</h2>
											<span>({v?.productDetails?.subName})</span>
											{v?.offerDetails?.discountValue ? (
												<div style={{ marginTop: 5 }}>
													<Tag color="yellow">
														Disc {v?.offerDetails?.discountValue}%
													</Tag>
												</div>
											) : null}
										</Col>
									</Row>
								</Col>
								<Col span={6} style={{ paddingTop: '20px' }}>
									<Space direction="horizontal">
										<Button
											style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
											onClick={() => {
												dispatch(
													decrementWithSave({
														productVariantId: v._id,
														dispatch,
														type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
													})
												);
											
											}}
										>
											-
										</Button>
										<Button>
											{cartData?.find(
												(vv) =>
													vv.productVariantId.toString() === v._id?.toString()
											)?.quantity || 0}
										</Button>
										<Button
											style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
											onClick={() => {
												dispatch(
													incrementWithSave({
														productVariantId: v._id,
														dispatch,
														type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
													})
												);
											}}
										>
											+
										</Button>
									</Space>
								</Col>
								<Col span={4}>
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<h3>
											₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
										</h3>
									</div>
								</Col>
							</Row>
						))
					) : (
						<div
							style={{
								textAlign: "center",
								color: "lightgray",
								fontSize: "20px",
								margin: "2rem 0",
							}}
						>
							Type on search to list items
						</div>
					)}
				</Col>
				<Col xs={{ span: 0 }} sm={{ span: 12 }} style={{ padding1: "25px" }}>
					<Menu
						style={{ borderBottom: "none",background:'white' }}
						theme="light"
						mode="horizontal"
						defaultSelectedKeys={["purchase-history"]}
						items={[
							{ key: "purchase-history", label: <h1 style={{margin:13,color: currentTab === "purchase-history" ? 'purple':'black'}}>Purchase</h1> },
							{ key: "wish-list", label: <h1 style={{margin:13 ,color: currentTab === "wish-list" ? 'purple':'black' }}>Wish List</h1> }
						]}
						onClick={(e) => setCurrentTab(e.key)}
					/>
					<Divider style={{ marginTop: "12px" }} />
					<Content>
						<div
							className="site-layout-content11"
							style1={{ background: colorBgContainer }}
						>
							<Space size={"large"}>
								<Search
									placeholder="Search"
									enterButton="Search"
									size="large"
									style={{ width: "700px", background: "grey" }}
									onChange={(e) => setSearchProWish(e.target.value)}
								/>
							</Space>
						</div>
					</Content>
					{currentTab === "wish-list" && wishListData.length > 0 ? (
						wishListData.map((v, i) => (
							<Row key={i} style={{ margin: "10px 0px" }}>
								<Col span={10} style={{ paddingTop: '20px' }}>
									<Row>
										<Col span={10}>
											<img
												alt="example"
												style={{ height: "100px", maxWidth: "120px" }}
												src={v.productDetails.thumbImg}
											/>
										</Col>
										<Col span={14}>
											<span style={{ color: 'grey' }}>Cat No.  {v?.catId}</span>
											<h2 style={{ marginTop: 0, marginBottom: 0 }}>
												{v?.productDetails?.name}
											</h2>
											<span>({v?.productDetails?.subName})</span>
											{v?.offerDetails?.discountValue ? (
												<div style={{ marginTop: 5 }}>
													<Tag color="yellow">
														Disc {v?.offerDetails?.discountValue}%
													</Tag>
												</div>
											) : null}
										</Col>
									</Row>
								</Col>
								<Col span={6} style={{ paddingTop: '20px' }}>
									<Space direction="horizontal">
										<Button
											style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
											onClick={() => {
												dispatch(
													decrementWithSave({
														productVariantId: v._id,
														dispatch,
														type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
													})
												);
											}}
										>
											-
										</Button>
										<Button>
											{cartData?.find(
												(vv) =>
													vv.productVariantId === v._id
											)?.quantity || 0}
										</Button>
										<Button
											style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
											onClick={() => {
												dispatch(
													incrementWithSave({
														productVariantId: v._id,
														dispatch,
														type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
													})
												);
											}}
										>
											+
										</Button>
									</Space>
								</Col>
								<Col span={4}>
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<h3>
											₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
										</h3>
									</div>
								</Col>
							</Row>
						))
					) : currentTab === "wish-list" && (
						<div
							style={{
								color: "lightgray",
								fontSize: "20px",
								margin: "2rem 14rem",
							}}
						>
							Your Wishlist is empty!!
						</div>
					)}
					{currentTab === "purchase-history" && purchaseData.length > 0 ? (
						purchaseData.map((v, i) => (
							<Row key={i} style={{ margin: "10px 0px" }}>
								<Col span={10} style={{ paddingTop: '20px' }}>
									<Row>
										<Col span={10}>
											<img
												alt="example"
												style={{ height: "100px", maxWidth: "120px" }}
												src={v.productDetails.thumbImg}
											/>
										</Col>
										<Col span={14}>
											<span style={{ color: 'grey' }}>Cat No.  {v?.catId}</span>
											<h2 style={{ marginTop: 0, marginBottom: 0 }}>
												{v?.productDetails?.name}
											</h2>
											<span>({v?.productDetails?.subName})</span>
											{v?.offerDetails?.discountValue ? (
												<div style={{ marginTop: 5 }}>
													<Tag color="yellow">
														Disc {v?.offerDetails?.discountValue}%
													</Tag>
												</div>
											) : null}
										</Col>
									</Row>
								</Col>
								<Col span={6} style={{ paddingTop: '20px' }}>
									<Space direction="horizontal">
										<Button
											style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
											onClick={() => {
												dispatch(
													decrementWithSave({
														productVariantId: v._id,
														dispatch,
														type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
													})
												);
											}}
										>
											-
										</Button>
										<Button>
											{cartData?.find(
												(vv) =>
													vv.productVariantId === v._id
											)?.quantity || 0}
										</Button>
										<Button
											style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
											onClick={() => {
												dispatch(
													incrementWithSave({
														productVariantId: v._id,
														dispatch,
														type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
													})
												);
											}}
										>
											+
										</Button>
									</Space>
								</Col>
								<Col span={4}>
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<h3>
											₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
										</h3>
									</div>
								</Col>
							</Row>
						))
					) : currentTab === "purchase-history" && (
						<div
							style={{
								color: "lightgray",
								fontSize: "20px",
								margin: "2rem 8rem",
							}}
						>
							Currently you don't have any purchase history!!
						</div>
					)}
				</Col>

				{/* mobile responsive */}
				<Col xs={{ span: 24 }} sm={{ span: 0 }} >
					<Layout>
						<Header style={{
							color: '#391085',
							fontSize: "22px",
							fontWeight: "700",
							height: 70,
							paddingInline: 20,
							lineHeight: '70px',
							backgroundColor: '#f0f0f0',
						}}>
							Quick Order
						</Header>
					</Layout>
					<Col style={{ textAlign: 'center', paddingTop: '20px' }} span={24}>
						<span style={{ fontSize: '20px', fontWeight: 500 }}>Enter the Product CAT No. which item you would like to Order</span>
						<hr style={{ backgroundColor: "lightgrey", border: 'none', height: '1px', marginTop: '10px', }} />
					</Col>
					<Col span={24} style={{ padding: '20px' }}>
						<Input placeholder="Enter the product CAT No." size='large' enterButton="Search" onChange={(e) => setSearch(e.target.value)} />
						<Button style={{ width: '100%', marginTop: '14px' }} size='large' type="primary" >
							Search
						</Button>
					</Col>
					<Col style={{ padding: '20px' }}>

						{varients?.length > 0 ? (
							varients?.map((v, i) => (
								<Row key={i} style={{ margin: "10px 0px" }}>
									<Col span={11}>
										<img
											alt="example"
											style={{ height: "120px", maxWidth: "120px" }}
											src={v.productDetails.thumbImg}
										/>
									</Col>
									<Col span={10}>
										<Space direction="vertical">
											<h3 style={{ marginTop: 0, marginBottom: 0 }}>
												{v?.productDetails?.name}
											</h3>
											<span style={{ color: 'gray' }}>({v?.productDetails?.subName})</span>
											{v?.offerDetails?.discountValue ? (
												<div style={{ marginTop: 0 }}>
													<Tag color="yellow">
														Disc {v?.offerDetails?.discountValue}%
													</Tag>
												</div>
											) : null}
											<div>
												<span style={{ fontSize: '16px', fontWeight: 'bold' }}>
													₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
												</span>
											</div>
											<Space direction="horizontal">
												<Button
													style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
													onClick={() => {
														dispatch(
															decrementWithSave({
																productVariantId: v._id,
																dispatch,
																type: "EUDcart",
															})
														);
													}}
												>
													-
												</Button>
												<Button>
													{cartData?.find(
														(vv) =>
															vv.productVariantId.toString() === v._id?.toString()
													)?.quantity || 0}
												</Button>
												<Button
													style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
													onClick={() => {
														dispatch(
															incrementWithSave({
																productVariantId: v._id,
																dispatch,
																type: "EUDcart",
															})
														);
													}}
												>
													+
												</Button>
											</Space>
										</Space>
									</Col>
									<Divider style={{ margin: 10 }} />
								</Row>
							))
						) : (
							<div
								style={{
									textAlign: "center",
									color: "lightgray",
									fontSize: "20px",
									marginBottom: "10px",
								}}
							>
								Type on search to list items
							</div>
						)}
					</Col>
					<Col span={24}>
						<Collapse onChange={handleMenu} accordion>
							<Collapse.Panel style={collapse === '1' ? { background: 'transparent linear-gradient(202deg, #78539C 0%, #6D3085 50%, #393185 100%) 0% 0% no-repeat padding-box ' } : { background: 'white' }}
								showArrow={false} header={<span style={collapse === '1' ? { marginLeft: '10px', fontSize: '16px', fontWeight: 500, color: 'white' } : { marginLeft: '10px', fontSize: '16px', fontWeight: 500, color: 'purple' }}>Purchase History</span>} key="1">
								{purchaseData.length > 0 ? (
									purchaseData.map((v, i) => (
										<Row key={i} style={{ margin: "10px 0px" }}>
											<Col span={11}>
												<img
													alt="example"
													style={{ height: "120px", maxWidth: "120px" }}
													src={v.productDetails.thumbImg}
												/>
											</Col>
											<Col span={10}>
												<Space direction="vertical">
													<h3 style={{ marginTop: 0, marginBottom: 0 }}>
														{v?.productDetails?.name}
													</h3>
													<span style={{ color: 'gray' }}>({v?.productDetails?.subName})</span>
													{v?.offerDetails?.discountValue ? (
														<div style={{ marginTop: 0 }}>
															<Tag color="yellow">
																Disc {v?.offerDetails?.discountValue}%
															</Tag>
														</div>
													) : null}
													<div>
														<span style={{ fontSize: '16px', fontWeight: 'bold' }}>
															₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
														</span>
													</div>
													<Space direction="horizontal">
														<Button
															style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
															onClick={() => {
																dispatch(
																	decrementWithSave({
																		productVariantId: v._id,
																		dispatch,
																		type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
																	})
																);
															}}
														>
															-
														</Button>
														<Button>
															{cartData?.find(
																(vv) =>
																	vv.productVariantId.toString() === v._id?.toString()
															)?.quantity || 0}
														</Button>
														<Button
															style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
															onClick={() => {
																dispatch(
																	incrementWithSave({
																		productVariantId: v._id,
																		dispatch,
																		type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
																	})
																);
															}}
														>
															+
														</Button>
													</Space>
												</Space>
											</Col>
											<Divider style={{ margin: 10 }} />
										</Row>
									))
								) : (
									<div
										style={{
											color: "lightgray",
											fontSize: "20px",
											textAlign:'center'
										}}
									>
										Currently you don't have any purchase history!!
									</div>
								)}
							</Collapse.Panel>
							<Collapse.Panel style={collapse === '2' ? { background: 'transparent linear-gradient(202deg, #78539C 0%, #6D3085 50%, #393185 100%) 0% 0% no-repeat padding-box ' } : { background: 'white' }}
								showArrow={false} header={<span style={collapse === '2' ? { marginLeft: '10px', fontSize: '16px', fontWeight: 500, color: 'white' } : { marginLeft: '10px', fontSize: '16px', fontWeight: 500, color: 'purple' }}>Wish List</span>} key="2">
								{wishListData.length > 0 ? (
									wishListData.map((v, i) => (
										<Row key={i} style={{ margin: "10px 0px" }}>
											<Col span={11}>
												<img
													alt="example"
													style={{ height: "120px", maxWidth: "120px" }}
													src={v.productDetails.thumbImg}
												/>
											</Col>
											<Col span={10}>
												<Space direction="vertical">
													<h3 style={{ marginTop: 0, marginBottom: 0 }}>
														{v?.productDetails?.name}
													</h3>
													<span style={{ color: 'gray' }}>({v?.productDetails?.subName})</span>
													{v?.offerDetails?.discountValue ? (
														<div style={{ marginTop: 0 }}>
															<Tag color="yellow">
																Disc {v?.offerDetails?.discountValue}%
															</Tag>
														</div>
													) : null}
													<div>
														<span style={{ fontSize: '16px', fontWeight: 'bold' }}>
															₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
														</span>
													</div>
													<Space direction="horizontal">
														<Button
															style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
															onClick={() => {
																dispatch(
																	decrementWithSave({
																		productVariantId: v._id,
																		dispatch,
																		type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
																	})
																);
															}}
														>
															-
														</Button>
														<Button>
															{cartData?.find(
																(vv) =>
																	vv.productVariantId.toString() === v._id?.toString()
															)?.quantity || 0}
														</Button>
														<Button
															style={{ background: "linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)", color: "white", fontWeight: "bolder" }}
															onClick={() => {
																dispatch(
																	incrementWithSave({
																		productVariantId: v._id,
																		dispatch,
																		type: v.productDetails.isEUD ? 'EUDcart' : 'myCart'
																	})
																);
															}}
														>
															+
														</Button>
													</Space>
												</Space>
											</Col>
											<Divider style={{ margin: 10 }} />
										</Row>
									))
								) : (
									<div
										style={{
											color: "lightgray",
											fontSize: "20px",
											textAlign:'center'
										}}
									>
										Your Wishlist is empty!!
									</div>
								)}
							</Collapse.Panel>

						</Collapse>
					</Col>
				</Col>

			</Row>
		</>
	);
};

export default QuickOrder;
