import React, { useEffect, useState } from "react";
import { Divider, Avatar, Typography, Row, Col } from 'antd';
import { Link, useHref } from 'react-router-dom'
import { UserOutlined, ShoppingCartOutlined, LogoutOutlined } from "@ant-design/icons";
import { Menu, theme } from "antd";
import { logout } from "../redux/user";
import { useDispatch } from "react-redux";
import service from "../service/user";

function Side() {
    const { token: { colorBgContainer } } = theme.useToken();
    const dispatch = useDispatch();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [data,setData] =useState({})

    const href = useHref();

    useEffect(() => {
        const hrefArr = href.split('/');
        setSelectedKeys([hrefArr[hrefArr.length - 1]])
    }, [href])

    useEffect(() => {
        console.log('selectedKeys', selectedKeys);
    }, [selectedKeys])

    const detailUser = () => {
        service.detailUser().then((res) => {
            setData(res.data);
        })
    }
  
    useEffect(() => {
        detailUser()
    }, [])

    return (
        <div style={{ background: colorBgContainer, boxShadow: "10px 10px 30px #00000029", maxWidth1: 380 }}>
            <Row className="app-gradient">
                <Col span={6}>
                    <Avatar size={64} icon={<UserOutlined />} style={{ margin: 15 }} />
                </Col>
                <Col span={18}>
                    <Typography.Title level={5} style={{ color: 'white', marginTop: 15 }}>Hello</Typography.Title>
                    <Typography.Title level={4} style={{ color: 'white', marginTop: 8 }}>{data?.name}</Typography.Title>
                </Col>
            </Row>
            <Menu
                mode="inline"
                selectedKeys={selectedKeys}
                defaultOpenKeys={["account-setting", "my-stuff"]}
                style={{ paddingBottom: '20px', background: "white" }}
                items={[
                    {
                        key: "account-setting",
                        label: <>
                            <Divider />
                            <h1 style={{color: "black"}} className="text-white-hover"> <UserOutlined /> Account Setting</h1>
                            <Divider />
                        </>,
                        children: [
                            {
                                key: "profile", label: <Link to={"/profile"} className="color-inherit" style={{ color: 'black' }}>Profile Information</Link>
                            },
                            {
                                key: "address", label: <Link to={"/address"} className="color-inherit" style={{ color: 'black' }}> Manage Address</Link>
                            }
                        ]
                    },
                    {
                        key: "my-stuff",
                        label: <h1 style={{color: "black"}} className="text-white-hover"> <ShoppingCartOutlined /> My Stuff</h1>,
                        children: [
                            { key: "coupon", label: <Link to={"/coupon"} className="color-inherit" style={{ color: 'black' }}>My Coupon</Link> },
                            { key: "wishlist", label: <Link to={"/wishlist"} className="color-inherit" style={{ color: 'black' }}>My Wishes</Link> },
                        ]
                    },
                    {

                        key: "myorder", label: <>
                            <Divider />
                            <Link to={"/myorder"} className="text-white-hover"> <h1> <ShoppingCartOutlined /> My Orders</h1> </Link>
                            <Divider />
                        </>
                    },
                    {
                        key: "wish-list",
                        label: <h1 onClick={() => { window.localStorage.clear(); dispatch(logout()); }}><LogoutOutlined /><Link to={"/"}> Logout</Link></h1>
                    },
                ]}
            />
        </div>
    )
}

export default Side;
