import { Row, Col, Divider, Image, Typography, Button, Breadcrumb, Layout, Space } from "antd";
import {
  HeartOutlined,
  HeartFilled,
  ShareAltOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import blogServices from "../service/blog";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { blogAction } from "../redux/blog";
import { Header } from "antd/es/layout/layout";
import { TwitterShareButton } from "react-share";
const config = {
  amitLabUrl: "https://amplieto.com"
}

const NewsBlog = () => {
  const [blogList, setBlogList] = useState([]);
  const [activeLike, setActiveLike] = useState(false);
  const dispatch = useDispatch();
  const blogLikeState = useSelector((state) => state.blog.blogState);

  const listBlog = () => {
    blogServices
      .listBlog({ page: 1, limit: 4 })
      .then((res) => {
        setBlogList(res.data?.map((v) => ({ ...v, key: v._id })));
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    listBlog();
  }, []);

  const handler = (data) => {
    if (blogLikeState.find((item) => item === data)) {
      setActiveLike(false);
      return dispatch(blogAction.disLike(data));

    }
    setActiveLike(true);
    dispatch(blogAction.like(data));
  };

  return (
    <>
      <Row style={{ paddingLeft: '20px', paddingTop: '20px' }}>
        <Breadcrumb style={{ marginLeft: "20px" }}>
          <Breadcrumb.Item>
            <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              Blogs
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{ marginBottom: '0px', marginTop: '15px' }} />
      </Row>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 0 }}>
          <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
              <Header style={{
                color: '#391085',
                fontSize: "22px",
                fontWeight: "700",
                height: 70,
                paddingInline: 40,
                lineHeight: '70px',
                backgroundColor: '#f0f0f0',
              }}>
                Blogs
              </Header>
            </Layout>
          </Space>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 24 }}>
          <div
            style={{
              height: "180px",
              background:
                "transparent url('https://img.freepik.com/free-photo/gray-abstract-wireframe-technology-background_53876-101941.jpg",
            }}
          >
            <Typography.Title
              style={{ padding: "34px", fontSize: "63px", color: "#6c4d98" }}
            >
              Blogs
            </Typography.Title>
          </div>
        </Col>

          {/* mobile */}
        <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: '34px' }}>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "30px",
            }}
          >
            <span style={{ fontSize: "30px" }}>Know our </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: 700,
                color: "#6c4d98",
              }}
            >
              {" "}
              Blogs
            </span>
          </div>

          <Row style={{ marginTop: "15px", }}>
            {blogList?.map((val, i) => (
              <Row key={i}>
                <Col span={24}>
                  <Image src={val?.img} style={{ width: '100%', marginLeft: 0, marginBottom: '10px' }} preview={false} />
                </Col>
                <Space direction="vertical" size={1}>
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "22px",
                      borderLeft: "solid 5px purple",
                      paddingLeft: "9px",
                    }}
                  >
                    {val.title}
                  </span>
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "grey",
                      paddingLeft: '18px'
                    }}
                  >
                    {moment(val?.date).format("MMM L")}
                  </span>
                </Space>
                <div style={{ fontWeight: 400, fontSize: "14px", marginTop: '20px' }}>
                  {val.description.substring(0, 200)}...
                </div>
                <Col
                  span={24}
                  style={{
                    marginTop: "22px",
                    display: 'flex',
                    justifyContent: "space-between",
                  }}
                >
                  <Link to={"/blog/" + val.slug}>
                    <Button
                      icon={<CaretRightOutlined />}
                      style={{ color: "purple" }}
                    >
                      Read More
                    </Button>
                  </Link>

                  <Space direction="horizontal" size={10}>

                    <TwitterShareButton title={val?.description} url={config.amitLabUrl}>
                      <span><ShareAltOutlined style={{ fontSize: '1.5rem', fontWeight: 600, color: 'purple' }} /></span>
                    </TwitterShareButton>
                  </Space>
                </Col>
                <Divider dashed />
              </Row>
            ))}
          </Row>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 24 }}>
          <div
            style={{
              textAlignLast: "center",
              marginTop: "40px",
              marginBottom: "60px",
            }}
          >
            <span style={{ fontSize: "60px" }}>Know our </span>
            <span
              style={{
                fontSize: "60px",
                fontWeight: 700,
                color: "#6c4d98",
                textAlign: "center",
              }}
            >
              {" "}
              Blogs
            </span>
          </div>
          <div style={{ marginTop: "25px" }}>
            {blogList?.map((val, i) => (
              <Fragment key={i}>
                <div style={{ display: "flex",paddingLeft:'20px' }}>
                  <Image src={val?.img} width={500} height={300} preview={false} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                      paddingLeft: "50px",
                      justifyContent: "space-between",
                      padding: "40px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          flex: 1,
                          fontWeight: 900,
                          fontSize: "25px",
                          borderLeft: "solid 8px red",
                          paddingLeft: "9px",
                          marginBottom: "10px",
                        }}
                      >
                        {val.title}
                      </span>
                      <span
                        style={{
                          marginLeft: "140px",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "grey",
                        }}
                      >
                        {moment(val?.date).format("MMM L")}
                      </span>
                    </div>
                    <div style={{ fontWeight: 500, fontSize: "25px" }}>
                      {val.description.substring(0, 200)}...
                    </div>
                    <span></span>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <Link to={"/blog/" + val.slug}>
                        <Button
                          icon={<CaretRightOutlined />}
                          style={{ color: "purple" }}
                        >
                          Read More
                        </Button>
                      </Link>
                      <div>
                      <TwitterShareButton title={val?.description} url={config.amitLabUrl}>
                      <span><ShareAltOutlined style={{ fontSize: '1.5rem', fontWeight: 600, color: 'purple' }} /></span>
                    </TwitterShareButton>
                      </div>
                    </span>
                  </div>
                </div>
                <Divider dashed />
              </Fragment>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewsBlog;
