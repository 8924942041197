import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Card, Image, Row, Col } from "antd";
import Typography from "antd/es/typography/Typography";
import { Link } from "react-router-dom";
import { addProductIds, removeProductId } from "../redux/wishlist";
import { useDispatch, useSelector } from "react-redux";
import commonObj from "../commonObj";

export default function ProductCard(data) {
	const cartData = useSelector(state => state.wishlist.value);
	const userInfo = useSelector(state => state.user);
	const dispatch = useDispatch();

	return (
		<>

			<Row>
				<Col xs={{ span: 0 }} sm={{ span: 24 }}>
					<Card style={{ width: "24vw", height: 560, marginTop: '20px' }} hoverable>
						<div style={{ position: "relative" }}>
							{!cartData?.includes(data._id) && (
								<span style={{ position: "absolute", color: "purple", right: -22, top: -18, zIndex: 1 }} >
									<HeartOutlined
										style={{ fontSize: 50 }}
										onClick={() => { userInfo.isLoggedin ? dispatch(addProductIds(data._id)) : commonObj?.openLogin() }}
									/>
								</span>
							)}
							{cartData?.includes(data._id) && (
								<span style={{ position: "absolute", color: "purple", right: -22, top: -18, zIndex: 1 }} >
									<HeartFilled
										style={{ fontSize: 50 }}
										onClick={() => { dispatch(removeProductId(data._id)) }}
									/>
								</span>
							)}
						</div>

						<Link to={"/product/" + (data?.allSlugs?.type || data?.allSlugs?.slug5) + "/" + data.slug}>

							<Row justify="center">
								<Col>
									<Image
										alt="example"
										preview={false}
										src={data.thumbImgUrl}
										style={{ width: "20vw", height: "20vw" }}
									/>
								</Col>
							</Row>
							<Row justify="center">
								<Col span={24}>
									<Typography.Title
									level={1}
										style={{ textAlign: "center", margin: 0, marginTop: 20,fontSize:'24px' }}
									>
									
										{data.name?.length > 120 ? data.name.slice(0, 120)+'...'  : data.name }
									</Typography.Title>
									{data.subName ? (
										<Typography.Title
											level={3}
											style={{ textAlign: "center", fontWeight: 100, margin: 0 }}
										>
											({data.subName})
										</Typography.Title>
									) : null}
									{data.startingPrice ? (
										<Typography.Title
											level={4}
											type="secondary"
											style={{ textAlign: "center", lineHeight: "28px" }}
										>
											{
												userInfo.isLoggedin ? `Starts from - ₹ ${data.startingPrice}` : ""
											}

										</Typography.Title>
									) : null}
								</Col>
							</Row>
						</Link>
					</Card>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 0 }}>
					<Card style={{ maxWidth: 225, minHeight: 320, marginTop: '20px' }} hoverable>
						<div style={{ position: "relative" }}>
							{!cartData?.includes(data._id) && (
								<span style={{ position: "absolute", color: "purple", right: -22, top: -18, zIndex: 1000 }}>
									<HeartOutlined
										style={{ fontSize: 30 }}
										onClick={() => { userInfo.isLoggedin ? dispatch(addProductIds(data._id)) : commonObj?.openLogin() }}
									/>
								</span>
							)}
							{cartData?.includes(data._id) && (
								<span style={{ position: "absolute", color: "purple", right: -22, top: -18, zIndex: 1000 }} >
									<HeartFilled
										style={{ fontSize: 30 }}
										onClick={() => { dispatch(removeProductId(data._id)) }}
									/>
								</span>
							)}
						</div>
						<Link to={"/product/" + data?.allSlugs?.type + "/" + data.slug}>
							<Row justify="center">
								<Col>
									<Image
										alt="example"
										preview={false}
										src={data.thumbImgUrl}
										style={{ width: 120, height: 120 }}
									/>
								</Col>
							</Row>
							<Row justify="center">
								<Col span={24} style={{marginTop: 20}}>
									<Typography.Title
										level={4}
										style={{ textAlign: "center", margin: 0 }}
									>
									{data.name?.length > 25 ? data.name.slice(0, 25)+'...'  : data.name }
									</Typography.Title>
									{data.subName ? (
										<Typography.Title
											level={5}
											style={{ textAlign: "center", fontWeight: 100, margin: 0 }}
										>
											({data.subName})
										</Typography.Title>
									) : null}
									{data.startingPrice ? (
										<Typography.Title
											type='secondary'
											style={{ fontSize: '14px' }}
										>
											{
												userInfo.isLoggedin ? `Starts from - ₹${data.startingPrice}` : ""
											}

										</Typography.Title>
									) : null}
								</Col>
							</Row>
						</Link>
					</Card>
				</Col>
			</Row>

		</>
	);
}
