import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import productService from "../service/product";
import { message, Row, Col, Radio, Spin, Divider } from "antd";
import Nodata from "../components/Nodata";
import Pagination from "../components/Pagination";
import ProductCard from "../components/ProductCard";
const SearchPage = () => {
	const { searchKey } = useParams();
	const [data, setData] = useState();
	const [ajaxRequesting, setAjaxRequesting] = useState(false);
	const [qData, setQData] = useState({ page: 1, limit: 20 , key: searchKey});
	const list = () => {
		productService
			.list(qData)
			.then((res) => {
				setData(res.data || []);
				setQData({
					...qData,
					limit: res.extra.limit,
					page: res.extra.page,
					total: res.extra.total,
				});
			})
			.catch((err) => {
				message.warning("Error in data loading! Try again!!");
			})
			.finally(() => {
				setAjaxRequesting(false);
			});
	};

	useEffect(() => {
		list();
	}, [qData.page, qData.limit, qData.key]);

	useEffect(() => {
		setQData({ ...qData, page: 1 });
	}, [qData.filter]);

	useEffect(()=> {
		setQData({...qData,key: searchKey, page: 1})
	}, [searchKey])

	return (
		<Row style={{ marginTop: 20 }}>
			<Col xs={{span:0}} sm={{span:24}}>
				<Spin tip="Loading" size="large" spinning={ajaxRequesting}>
					{data?.length ? (
						<Row
							gutter={[20, 20]}
							style={{ marginLeft: 0, marginRight: 0 }}
							justify="space-evenly"
						>
							{data.map((v) => (
								<Col key={v._id} span={6}>
									<ProductCard {...{ ...v }} />
								</Col>
							))}
							<Col span={24}>
								<Divider />
								<Pagination {...{ qData, setQData }} />
								<Divider />
							</Col>
						</Row>
					) : (
						<Nodata />
					)}
				</Spin>
			</Col>
			{/* mobile responsive */}
			<Col xs={{span:24}} sm={{span:0}}>
				<Spin tip="Loading" size="large" spinning={ajaxRequesting}>
					{data?.length ? (
						<Row
							gutter={[20, 20]}
							style={{ marginLeft: 0, marginRight: 0 }}
							justify="space-evenly"
						>
							{data.map((v) => (
								<Col key={v._id} span={12}>
									<ProductCard {...{ ...v }} />
								</Col>
							))}
							<Col span={24}>
								<Divider />
								<Pagination {...{ qData, setQData }} />
								<Divider />
							</Col>
						</Row>
					) : (
						<Nodata />
					)}
				</Spin>
			</Col>
		</Row>
	);
};

export default SearchPage;
