import axios from "../config.js/axios";

export default class user {

    static sendOtp(data) {
        return axios.post('send-otp', data);
    }

    static verifyOtp(data) {
        return axios.post('validate-otp', data);
    }
    static acceptTc() {
        return axios.post('/acceptTc');
    }
    static detailUser(data) {
        return axios.get('/details');
    }
    static updateUser(data) {
        return axios.post('/update-user', data);
    }

    static googleLogin(data) {
        return axios.post('/login-google',  data );
    }


    //// address list 
    static listAddress(data) {
        return axios.get('/address/list', { params: data });
    }
    static saveAddress(data) {
        return axios.post('/address/save', data);
    }
    static deleteAddress(id) {
        return axios.delete('/address/delete/' + id );
    }

    static validateToken(data) {
        return axios.post("/validate-token", data);
    }
}