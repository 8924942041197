import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, Input, Modal, Row, Col, Image, Typography, message, Tabs, Tooltip, Divider } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined, ShoppingCartOutlined, HeartFilled, HourglassOutlined, GoogleOutlined} from "@ant-design/icons";
import { useEffect, useState } from "react";
import loginImg from "../../Assets/login.jpg";
import userService from "../../service/user";
import { changeDetails } from "../../redux/user";
import util from "../../config.js/util";
import DropDownCart from "../../components/DropDownCart";
import commonObj from "../../commonObj";
import { signInWithGoogle } from "./firebaseLogin";

export default function User() {
	const user = useSelector((state) => state.user);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const dispatch = useDispatch();

	const toolitems = [
		{
			key: "1",
			label: <h2>My Cart</h2>,
			children: <>{<DropDownCart type="myCart" />}</>,
		},

		{
			key: "2",
			label: <h2>EUD CART</h2>,
			children: <>{<DropDownCart type="EUDcart" />}</>,
		},
		{
			key: "3",
			label: (
				<Link to="/shoppingcart">

					<>
						<h2>View all</h2>
					</>
				</Link>
			),
		},
	];

	useEffect(()=> {
		if(!commonObj.openLogin){
			commonObj.openLogin = function(){
				setShowLoginModal(true);
			}
		}
	
		if(!commonObj.closeLogin){
			commonObj.closeLogin = function(){
				setShowLoginModal(false);
			}
		}
	}, []);


	const items = [
		{
			key: "my-profile",
			icon: <UserOutlined style={{ height: "35px", fontSize: "25px", color: "#3F3588", }} />,

			label: <Link to="/profile" ><div style={{ height: "35px", padding: "6px", width: "231px", fontWeight: "600", fontSize: "18px", }}>My Profile </div></Link>,
			style: { borderBottom: "solid 0.5px #e1dada", }
		},
		{
			key: "orders",
			icon: <ShoppingCartOutlined style={{ fontSize: "25px", color: "#3F3588" }} />,
			label: <Link to="/myorder" ><div style={{ height: "35px", padding: "6px", width: "231px", fontWeight: "600", fontSize: "18px", }}> Orders</div></Link>,
			style: { borderBottom: "solid 0.5px #e1dada" }
		},
		{
			key: "wishlist",
			icon: <HeartFilled style={{ fontSize: "25px", color: "#3F3588" }} />,
			label: <Link to="/wishlist" ><div style={{ height: "35px", padding: "6px", width: "231px", fontWeight: "600", fontSize: "18px", }}>Wishlist</div></Link>,
			style: { borderBottom: "solid 0.5px #e1dada" }
		},
		{
			key: "coupon",
			icon: <HourglassOutlined style={{ fontSize: "25px", color: "#3F3588" }} />,
			label: <Link to="/coupon" > <div style={{ height: "38px", padding: "6px", width: "231px", fontWeight: "600", fontSize: "18px", }}>Coupon  </div></Link>,

		},
	];
	return (
		<>
			{user.isLoggedin ? (
				<>
					<Row>
						<Col xs={{ span: 0 }} sm={{ span: 24 }}>
							<Row>
								<Col span={12}>
									<Dropdown menu={{ items }} overlayStyle={{ borderBottom: "solid 8px #3F3588", borderRadius: "15px" }} placement="bottomRight">
										<Button className="app-gradient"
											style={{width: "100px" }}
										>

											{user.name || "You"}
										</Button>

									</Dropdown>
								</Col>
								<Link to={"/shoppingcart"}>
								<Col span={12}>
								<Tooltip
									color="white"
									autoAdjustOverflow={true}
									align={"middle"}
									overlayInnerStyle={{
										width: "480px",
										right: "90%",
										position: "relative",
									}}
									placement="topRight"
									title={<Tabs items={toolitems} destroyInactiveTabPane></Tabs>}
								>
									<Button
									style={{ }}
										className="app-gradient"
										icon={<ShoppingCartOutlined />}
									>
										Cart
									</Button>
								</Tooltip>
							</Col>
								</Link>
							</Row>
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 0 }}>
							<Row>
								<Col span={12}>
									<Dropdown menu={{ items }}>
										<Button type="primary" icon={<UserOutlined />} />
									</Dropdown>
								</Col>
								<Col span={12}>
								<Link to={"/shoppingcart"}>
									<Button
										type="primary"
										icon={<ShoppingCartOutlined />}
										style11={{ marginLeft: 5 }}
									/>
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</>
			) : (
				<Row>
					<Col   xs={{ span: 0 }} sm={{ span: 24 }}>
					<Button
						style={{ width: "100px" }}
						className="app-gradient"
						onClick={() => {
							setShowLoginModal(true);
						}}
					>
						Login
					</Button>
					<Button
					
						className="app-gradient"
						icon={<ShoppingCartOutlined />}
						onClick={() => {
							setShowLoginModal(true);
						}}
					>
						Cart
					</Button>
					<LoginModal {...{ showLoginModal, setShowLoginModal, dispatch }} />
					</Col>
					<Col   xs={{ span: 24 }} sm={{ span: 0 }}>
					<Button
						style={{marginRight:"5px",marginLeft:"8px"}}
						className="app-gradient"
						icon={ <UserOutlined/>}
						onClick={() => {
							setShowLoginModal(true);
						}}
					>
						
					</Button>
					<Button
						className="app-gradient"
						icon={<ShoppingCartOutlined />}
						onClick={() => {
							setShowLoginModal(true);
						}}
					>
						
					</Button>

					</Col>
				</Row>
			)}
		</>
	);
}

const LoginModal = ({ showLoginModal, setShowLoginModal, dispatch }) => {
	const [data, setData] = useState({
		otpSent: false,
		phone: "",
		otp: "",
	});

	const sendOtp = () => {
		userService
			.sendOtp({ phone: data.phone })
			.then((res) => {
				message.info(res.message);
				setData({ ...data, otpSent: true });
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	const verifyOtp = () => {
		userService
			.verifyOtp({ otp: data.otp, phone: data.phone })
			.then((res) => {
				message.info(res.message);
				setData({ ...data, otpSent: false });
				setShowLoginModal(false);
				util.setUserData(res.data);
				userService
					.validateToken()
					.then((res) => {
						message.success(res.message);
						dispatch(
							changeDetails({ isLoggedin: true, name: res.data?.user?.name })
						);
					})
					.catch((err) => {
						window.localStorage.clear();
						message.error("Unauthorized. Login Again!!", "error");
					})
					.finally(() => { });
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	return (
		<Modal
			open={showLoginModal}
			width={700}
			onCancel={() => setShowLoginModal(false)}
			footer={null}
			maskClosable={true || !data.otpSent}
			closeIcon={<></>}
			bodystyle={{ padding: "0px" }}
		>
			<Row gutter={[24, 8]}>
				<Col
					className="app-gradient"
					style={{ paddingTop: "23px" }}
					xs={{ span: 24 }}
					sm={{ span: 10 }}
				>
					<Typography.Title level={1} style={{ color: "white", margin: 0 }}>
						Login
					</Typography.Title>
					<Typography.Title level={4} style={{ color: "white", marginBottom: "74px" }}>
						Get access to your Order Wishlist and Recommendation
					</Typography.Title>
					<Row>
						<Col xs={{ span: 0 }} sm={{ span: 24 }}>
							<Image src={loginImg} preview={false} />
						</Col>
					</Row>
				</Col>

				<Col xs={{ span: 24 }} sm={{ span: 14 }}>
					{!data.otpSent ? (
						<div style={{ display: "block", marginTop: "33px" }}>
							<Input
								size="large"
								placeholder="Phone"
								className="phone-number-input"
								value={data.phone}
								onChange={(e) => {
									setData({ ...data, phone: util.handleInteger(e.target.value) });
								}}
							/>
							<Button
								className="app-gradient"
								type="primary"
								block
								size="large"
								onClick={sendOtp}
								htmlType="button"
							>
								Request OTP
							</Button>

							<Divider>OR</Divider>


							<Button
								className=""
								type="primary"
								block
								size="large"
								onClick={signInWithGoogle}
								htmlType="button"
							><GoogleOutlined />
							 Login With Google
							</Button>


						</div>


					) : (
						<div style={{ display: "block", marginTop: 50 }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									flexDirection: "column",
									alignItems: "center",
									marginBottom: "39px",
									fontSize: "20px",
									fontWeight: "bold",
								}}
							>
								<div>Please enter the OTP sent to</div>
								<div strong>
									{data.phone}
									<Button
										type="text"
										className="primary-color"
										onClick={(e) => {
											setData({ ...data, otpSent: false });
										}}
									>
										Change
									</Button>
								</div>
							</div>
							<Input
								size="large"
								placeholder="OTP"
								className="validate-otp-input"
								value={data.otp}
								onChange={(e) => {
									setData({ ...data, otp: util.handleInteger(e.target.value, 4) });
								}}
							/>
							<Button
								className="app-gradient"
								type="primary"
								block
								size="large"
								onClick={verifyOtp}
							>
								Verify
							</Button>
						</div>
					)}
				</Col>
			</Row>
		</Modal>
	);
};
