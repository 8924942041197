import React, { useEffect, useState } from "react";
import { Typography, Card, Row, Col, Space } from 'antd';
import moment from 'moment'

export default function CouponCard  ({ val }) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 5000);
        }
    }, [copied]);

    return <>
        {/* <Card > */}
            <Row style={{border: "1px solid gray", padding: "20px 40px"}}>
                <Col span={12}>
                    <Card
                        style={{
                            backgroundImage: "url(" + "./coupon.png" + ")",
                            height: '180px',
                            width: '420px',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            border: "1px solid #ebebeb",
                            borderRadius: 14
                        }}
                    >
                        <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 10, color: "purple", textAlign: 'right' }}>
                            Valid Till {moment(val.expiryDate).format(" Do MMM, YYYY")}
                        </Typography.Title>
                        <Typography.Title level={2} style={{ marginTop: 10, marginBottom: 20, marginLeft: 0, color: "purple" }}> {val.title} </Typography.Title>
                        <Typography.Text level={5}
                            style={{ marginLeft: 10, marginRight: 20, fontSize: 18, color: '#656565' }}
                        >
                            {val.code}
                            <span
                                style={{ float: 'right', paddingRight: 20, color: 'purple', cursor: 'pointer' }}
                                onClick={() => {
                                    navigator.clipboard.writeText(val.code);
                                    setCopied(true);
                                }}
                            >
                                {
                                    copied
                                        ? "Copied"
                                        : "Copy"
                                }
                            </span>
                        </Typography.Text>
                    </Card>
                </Col>
                {/* <Col span={8}>
                    <Space direction='vertical'>
                        <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 10, marginLeft: 0, color: "purple" }}> {val.title} </Typography.Title>
                        <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 10, marginLeft: 0, color: "gray" }}>{val.description}</Typography.Title>
                        <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 10, color: "purple" }}>Valid Till {moment(val.expiryDate).format(" Do MMM, YYYY")}</Typography.Title>
                    </Space>
                </Col> */}
            </Row>
        {/* </Card> */}
    </>
};