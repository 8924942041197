import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Space,
    Layout,
    message,
    Image,
    Card,
} from "antd";
import { Link } from "react-router-dom";
import galleryService from "../../service/gallery"

const { Header } = Layout;

const headerStyle = {
    color: '#391085',
    fontSize: "xx-large",
    fontWeight: "700",
    height: 120,
    paddingInline: 50,
    lineHeight: '120px',
    backgroundColor: '#f0f0f0',
};

const Gallery = () => {

    const [galleryLists, setGalleryList] = useState([]);


    const listGallerys = () => {
        galleryService
            .listGallery()
            .then((res) => {
                setGalleryList(res.data?.map((v) => ({ ...v, key: v._id })));
            })
            .catch((err) => {
            });
    };

    useEffect(() => {
        listGallerys();
    }, []);


    return (
        <React.Fragment>
            <Row style={{ padding: "20px" }}>
                <Breadcrumb style={{ marginLeft: "20px" }}>
                    <Breadcrumb.Item>
                        <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
                            Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={"/gallery"} style={{ fontSize: "500", fontWeight: "bolder" }}>
                            Gallery
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>

            <Col xs={{ span: 24 }} sm={{ span: 0 }} >
                <Layout>
                    <Header style={{
                        color: '#391085',
                        fontSize: "22px",
                        fontWeight: "700",
                        height: 70,
                        paddingInline: 20,
                        lineHeight: '70px',
                        backgroundColor: '#f0f0f0',
                    }}>
                        Gallery
                    </Header>
                </Layout>
            </Col>
            <Col xs={{ span: 0 }} sm={{ span: 24 }} style={{ width: '100%' }} >
                <Layout>
                    <Header style={headerStyle}>
                        Gallery
                    </Header>
                </Layout>
            </Col>

            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 20 }} style={{margin:"0 auto"}}>
                    <Row gutter={[0, 20]} justify="space-around" style={{ marginTop: 50, marginBottom: 50 }}>
                        {galleryLists?.map((val, i) => (
                            <Col key={i} gutter={[48, 24]} >
                                <Image src={val?.image?.url} width={350} height={350} alt="Our Picture"
                                    style={{
                                        boxShadow: '10px 10px 30px #00000029',
                                        border: "8px solid white"
                                    }}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <Row style={{display:'flex',justifyContent:'center',paddingTop:'20px',paddingBottom:'20px'}}>
                       
                        {galleryLists?.map((val, i) => (
                            <Col key={i} style={{padding:'5px'}} >
                                <Image src={val?.image?.url}  alt="Our Picture"
                                    style={{
                                        boxShadow: '10px 10px 30px #00000029',
                                        border: "8px solid white",
                                        height:184,
                                        width:184,
                                       
                                    }}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Gallery;