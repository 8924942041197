/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Typography, Layout, Breadcrumb, Divider, } from "antd";
import { useEffect } from "react";
import service from "../service/aboutus";
import serviceTeam from "../service/team";
import { Header } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
const AboutUs = () => {
  const [data, setData] = useState();
  const [team, setTeam] = useState();
  const list = () => {
    service
      .list()
      .then((res) => setData(res.data))
      .catch((err) => { });

    serviceTeam
      .list()
      .then((res) => setTeam(res.data))
      .catch((err) => { });
  };
  useEffect(() => {
    list();
  }, []);
  return (
    <Row>
      <Row style={{ padding: "10px" }}>
        <Breadcrumb style={{ marginLeft: "20px" }}>
          <Breadcrumb.Item>
            <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={"/aboutus"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              About Us
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Col xs={{ span: 0 }} sm={{ span: 24 }}>
        <Col span={24}>
          <div
            style={{
              height: "180px",
              background:
                "transparent url('https://img.freepik.com/free-photo/gray-abstract-wireframe-technology-background_53876-101941.jpg",
              objectFit: "cover",
            }}
          >
            <Typography.Title
              style={{ padding: "34px", fontSize: "63px", color: "#6c4d98" }}
            >
              About us
            </Typography.Title>
          </div>
        </Col>
        <div style={{ padding: "2rem", display: "flex" }}>
          <Col span={8}>
            {data?.map((item, i) =>
              item?.columnNo === 1 && item?.type === "image" ? (
                <img
                  src={item?.img?.url}
                  alt="sample"
                  style={{ width: "400px" }}
                  key={i}
                />
              ) : null
            )}
          </Col>
          {data?.map((item, i) =>
            item?.columnNo === 2 && item?.type === "text" ? (
              <Col span={16} key={i}>
                <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                  {" "}
                  <span style={{ color: "#6C4D98" }}>
                    {item?.title ? item?.title.split(" ")[0] : ""}
                  </span>{" "}
                  {item.title ? item?.title
                    .split(" ")
                    .slice(1)
                    .map((v) => v + " "): "" }
                </span>
                <p p style={{ fontSize: "20px" }}>
                  {item?.description}
                </p>
              </Col>
            ) : null
          )}
        </div>
        <div style={{ padding: "2rem", display: "flex" }}>
          {data?.map((item, i) =>
            item?.columnNo === 3 && item?.type === "text" ? (
              <Col span={16} key={i}>
                <span style={{ fontSize: "25px", fontWeight: "bold" }}>
                  {" "}
                  <span style={{ color: "#6C4D98" }}>
                    {item?.title.split(" ")[0]}
                  </span>{" "}
                  {item?.title
                    .split(" ")
                    .slice(1)
                    .map((v) => v + " ")}
                </span>
                <p p style={{ fontSize: "20px" }}>
                  {item?.description}
                </p>
              </Col>
            ) : null
          )}
          <Col span={8}>
            {data?.map((item, i) =>
              item?.columnNo === 4 && item?.type === "image" ? (
                <img
                  src={item?.img?.url}
                  alt="sample"
                  style={{ width: "400px" }}
                  key={i}
                />
              ) : null
            )}
          </Col>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Col
            span={24}
            style={{
              textAlign: "center",
              margin: "2rem 0",
            }}
          >
            <span style={{ fontSize: "45px", fontWeight: "600" }}>
              With{" "}
              <span style={{ color: "#6C4D98" }}>
                Year of Marketing Experience
              </span>{" "}
              we strive to become a{" "}
              <span style={{ color: "#6C4D98" }}>First Choice</span>
            </span>
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "center",
              margin: "2rem 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: "600",
                display: "block",
                width: "700px",
              }}
            >
              Meet an exceptional team of technology advisors, growth
              facilitators, and subject matter experts with proven experience
            </span>
          </Col>
        </div>
        <div style={{ padding: "2rem", display: "flex" }}>
          <Col span={8}>
            <img
              src={
                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
              }
              alt="sample"
              style={{ width: "300px" }}
            />
            <div style={{ fontSize: "35px", fontWeight: "600" }}>
              Aspire to Inspire
            </div>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Director & Founder CEO
            </span>
          </Col>
          <Col span={16}>
            <span
              style={{
                fontSize: "38px",
                fontWeight: "bold",
                display: "block",
                width: "700px",
              }}
            >
              We don't believe in{" "}
              <span style={{ color: "#013D78" }}>Awards</span>,{" "}
              <span style={{ color: "red" }}>Client's Satisfaction</span> is our
              best <span style={{ color: "#013D78" }}>gift</span>
            </span>
            <p style={{ fontSize: "20px" }}>
              We are believers of change! A change driven by technology and
              innovation. We help businesses and individuals in adapting as well
              as adopting digital transformation. Our aim is to change people's
              lives and improve businesses with our progressive and innovative
              technology solutions. We are believers of change! A change driven
              by technology and innovation. We help businesses and individuals
              in adapting as well as adopting digital transformation. Our aim is
              to change people's lives and improve businesses with our
              progressive and innovative technology solutions.
            </p>
          </Col>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Col
            span={24}
            style={{
              textAlign: "center",
              margin: "2rem 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "45px",
                fontWeight: "600",
                display: "block",
                width: "700px",
              }}
            >
              <span style={{ color: "#6C4D98", fontWeight: "700" }}>
                Lets Meet
              </span>{" "}
              Our Team
            </span>
          </Col>
          <Row>
            {team?.map((v) => (
              <Col span={6} style={{ margin: "2rem 0" }} key={v._id}>
                <img src={v.img?.url} alt="Team-member" style={{ width: "200px" }} />
                <div style={{ fontSize: "25px", fontWeight: "600" }}>
                  {v?.name}
                </div>
                <span style={{ fontSize: "18px" }}>
                  {v?.designation}
                </span>
              </Col>
            ))}
          </Row>
        </div>
      </Col>

      {/* mobile */}
      <Col xs={{ span: 24 }} sm={{ span: 0 }}>
        <Layout>
          <Header style={{
            color: '#391085',
            fontSize: "22px",
            fontWeight: "700",
            height: 70,
            paddingInline: 28,
            lineHeight: '70px',
            backgroundColor: '#f0f0f0',
          }}>
            About Us
          </Header>
        </Layout>
        <Row style={{ padding: '20px' }}>
          <Col span={24}>
            {data?.map((item, i) =>
              item?.columnNo === 1 && item?.type === "image" ? (
                <img
                  src={item?.img?.url}
                  alt="sample"
                  style={{ maxWidth: '100%', marginBottom: '20px' }}
                  key={i}
                />
              ) : null
            )}
          </Col>
          <div style={{ marginBottom: '20px' }}>
            {data?.map((item, i) =>
              item?.columnNo === 5 && item?.type === "text" ? (
                <Col span={24} key={i}>
                  <span style={{ fontSize: "20px", fontWeight: "bold", marginLeft: 2 }}>
                    {" "}
                    <span style={{ color: "#6C4D98" }}>
                      {item?.title.split(" ")[0]}
                    </span>{" "}
                    {item?.title
                      .split(" ")
                      .slice(1)
                      .map((v) => v + " ")}
                  </span>
                  <p style={{ fontSize: "15px", margin: 4, fontWeight: 500 }}>
                    {item?.description}
                  </p>
                </Col>
              ) : null
            )}
          </div>
          <div style={{ marginBottom: '20px' }}>
            {data?.map((item, i) =>
              item?.columnNo === 2 && item?.type === "image" ? (
                <Col span={24} key={i}>
                  <span style={{ fontSize: "20px", fontWeight: "bold", marginLeft: 2 }}>
                    {" "}
                    <span style={{ color: "#6C4D98" }}>
                      {item?.title.split(" ")[0]}
                    </span>{" "}
                    {item?.title
                      .split(" ")
                      .slice(1)
                      .map((v) => v + " ")}
                  </span>
                  <p style={{ fontSize: "15px", margin: 4, fontWeight: 500 }}>
                    {item?.description}
                  </p>
                  <div>
                    <img
                      src={item?.img?.url}
                      alt="sample"
                      style={{ maxWidth: '100%', marginBottom: '20px', marginTop: '30px' }}
                      key={i}
                    />
                  </div>
                </Col>
              ) : null
            )}
          </div>
          <div style={{ background: '#f0f0f0' }}>
            <Col
              span={24}
              style={{
                textAlign: "center",
                margin: "2rem 0",
              }}
            >
              <span style={{ fontSize: "20px", fontWeight: "600" }}>
                With{" "}
                <span style={{ color: "#6C4D98" }}>
                  Year of Marketing Experience
                </span>{" "}
                we strive to become a{" "}
                <span style={{ color: "#6C4D98" }}>First Choice</span>
              </span>
              <hr style={{ width: '220px', backgroundColor: "#6C4D98", border: 'none', height: '5px', borderRadius: '5px', marginTop: '10px', marginBottom: '-16px' }} />
            </Col>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginLeft: '12px',
                marginRight: '12px'
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Meet an exceptional team of technology advisors, growth
                facilitators, and subject matter experts with proven experience
              </span>
            </Col>
            {team?.map((item, i) =>
              item?.designation === "CEO" ? (
                <Col span={24} key={i} style={{ textAlign: 'center' }}>
                  <div style={{ marginTop: '25px' }}>
                    <img
                      src={
                        item?.img.url
                      }
                      alt="sample"
                      style={{
                        width: "190px", height: '200px', borderRadius: '15px', boxShadow: '10px 10px 45px gray'

                      }}
                    />
                  </div>
                  <div style={{ fontSize: "22px", fontWeight: "600", marginTop: '20px' }}>
                    Aspire to Inspire
                  </div>
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>
                    {item?.name} ({item?.designation})
                  </span>
                </Col>
              ) : null
            )}
            {team?.map((item, i) =>
              item?.designation === "Director" ? (
                <Col span={24} key={i} style={{ textAlign: 'center' }}>
                  <div style={{ marginTop: '25px' }}>
                    <img
                      src={
                        item?.img.url
                      }
                      alt="sample"
                      style={{
                        width: "190px", height: '200px', borderRadius: '15px', boxShadow: '10px 10px 45px gray'

                      }}
                    />
                  </div>
                  <div style={{ fontSize: "22px", fontWeight: "600", marginTop: '20px' }}>
                    Aspire to Inspire
                  </div>
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>
                    {item?.name} ({item?.designation})
                  </span>
                </Col>
              ) : null
            )}

            <Col span={24} style={{ textAlign: 'center', marginTop: '30px', marginBottom: '50px' }}>
              <p style={{ fontSize: "15px", fontWeight: 500, }}>
                We are believers of change! A change driven by technology and
                innovation. We help businesses and individuals in adapting as well
                as adopting digital transformation. Our aim is to change people's
                lives and improve businesses with our progressive and innovative
                technology solutions. We are believers of change! A change driven
                by technology and innovation. We help businesses and individuals
                in adapting as well as adopting digital transformation. Our aim is
                to change people's lives and improve businesses with our
                progressive and innovative technology solutions.
              </p>
            </Col>
          </div>
          <Col
            span={24}
            style={{
              textAlign: "center",
              margin: "2rem 0",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              <span style={{ color: "#6C4D98", fontWeight: "700" }}>
                Lets Meet
              </span>{" "}
              Our Team
            </span>
          </Col>
          <Row gutter={20}>
            {team?.map((item, i) =>
              item?.designation !== "CEO" && item?.designation !== "Director" ? (
                <Col span={12} key={i} style={{ textAlign: 'center', }}>
                  <div style={{ marginTop: '25px',paddingTop:'20px',paddingBottom:'20px' }}>
                    <img
                      src={
                        item?.img?.url
                      }
                      alt="sample"
                      style={{
                        width: "100%",height:'130px', borderRadius: '15px', boxShadow: '10px 10px 45px gray'

                      }}
                    />
                  </div>
                  <div style={{ fontSize: "18px", fontWeight: "600", }}>
                    {item?.name}
                  </div>
                  <span style={{ fontSize: "14px", fontWeight: "600" }}>
                    {item?.designation}
                  </span>
                </Col>
              ) : null
            )}
          </Row>
           <Col span={24}>
          {data?.map((item, i) =>
            item?.columnNo === 6 && item?.type === "image" ? (
              <img
                src={item?.img?.url}
                alt="sample"
                style={{ maxWidth: '100%', marginBottom: '20px',marginTop:'40px' }}
                key={i}
              />
            ) : null
          )}
        </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AboutUs;
