/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Row, Col, Card, Breadcrumb, Divider, Tag, Radio, Space, Button, message, Descriptions, Typography, Select, Form, Input, Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import service from "../../service/user";
import cartService from "../../service/cart";
import couponService from "../../service/coupon";
import orderService from "../../service/order";
import displayRazorpay from "../../config.js/razorpay";
import OrderSuccess from "./OrderSuccess";
import UploadFile from "../../config.js/Upload";
import EodFormat from "../../components/EodPdf";


export default function PlaceOrder() {
	const [address, setAddress] = useState([]);
	const [cartTotalValue, setCartTotalValue] = useState({});
	const [data, setData] = useState({ code: window.localStorage.getItem("coupon"), type: localStorage.getItem('cartType') });
	const [fileList, setFileList] = useState([]);
	const [paymentDetails, setPaymentDetails] = useState({});
	const [ajaxRequesting, setAjaxRequesting] = useState(false);

	const addressModalRef = useRef();

	const listAddress = () => {
		service.listAddress().then((res) => {
			setAddress(res.data);
			setData({ ...data, addressId: res?.data?.[0]?._id });
		});
	};

	const calculatePrice = () => {
		if (data.code) {
			couponService.validateCoupon({ code: data.code, type: localStorage.getItem('cartType') }).then((res) => {
				setCartTotalValue({ ...res.data });
			}).catch((err) => {
				message.error("Can not apply coupon");
				window.localStorage.removeItem("coupon");
				cartService.calculatePrice({ ...data }).then((res) => {
					setCartTotalValue(res.data);
				}).catch((err) => { })
			});
		} else {
			cartService.calculatePrice({ ...data }).then((res) => {
				setCartTotalValue(res.data);
			}).catch((err) => {
				window.localStorage.removeItem("coupon");
			})
		}
	}

	const placeOrder = () => {
		if (data.type === "EUDcart" && !data.file) {
			message.error("Please Upload the certificate");
		} else {
			setAjaxRequesting(true);
			orderService.placeOrder(data).then(res => {
				if (res.data.amount !== 0) {
					displayRazorpay(res.data, setPaymentDetails);
				} else {
					setPaymentDetails({ razorpayPaymentId: "pseudoId" })
				}
			}).catch(err => {
				message.error(err.message)
			})
				.finally(() => {
					setAjaxRequesting(false);
				})
		}
	}
	useEffect(() => {
		listAddress();
		calculatePrice();
	}, []);
	useEffect(() => {
		setData({ ...data, file: fileList[0] });
	}, [fileList]);



	return (
		<>
			<Row style={{ padding: "20px" }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
							Home
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link
							to={"/cart"}
							style={{ fontSize: "500", fontWeight: "bolder" }}
						>
							Shopping Cart
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link
							to={"/placeorder"}
							style={{ color: "black", fontSize: "500", fontWeight: "bolder" }}
						>
							Place Order
						</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
			</Row>

			<Spin spinning={ajaxRequesting}>
				<Row >
					<Col xs={{ span: 0 }} sm={{ span: 16 }} style={{ paddingLeft: "50px", }}>
						<Col>
							<Row
								style={{
									padding: "13px",
									background:
										"linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)",
								}}
							>
								<span style={{ color: "white", fontSize: 18, }} >
									Choose Your Delivery Address
								</span>
							</Row>
							<Radio.Group style={{ width: "100%" }} value={data.addressId} onChange={e => { setData({ ...data, addressId: e.target.value }); }}>
								{address.map((v) => (
									<Row gutter={[12, 12]} key={v._id}>
										<Col span={24}>
											<Card hoverable key={v._id} >
												<Row>
													<Col span={22}>
														<Radio value={v._id} checked={data.addressId === v._id} >
															<Space size={20} direction="horizontal">
																<Typography.Title level={4} style={{ margin: 0 }}>
																	{v.name} {v.phone}
																</Typography.Title>
																{
																	v.tag
																		? <Tag color="grey">{v.tag?.toUpperCase()}</Tag>
																		: null
																}
															</Space>
														</Radio>
													</Col>
													<Col span={2}>
														<Button style={{ color: "purple", fontSize: "16px" }} type="text" onClick={() => { addressModalRef.current.openForm(v) }}>
															Edit
														</Button>
													</Col>
												</Row>
												<Row style={{ padding: "20px" }}>
													<Col span={16}>
														<Space size={0} direction="vertical">
															<Typography.Title level={5} style={{ margin: 0, marginBottom: 20 }}>
																{v.address}, {v.city} , {v.state} - {v.pin}
															</Typography.Title>
															<Button type="primary" size="large" onClick={() => { setData({ ...data, addressId: v._id }) }}>Deliver Here</Button>
														</Space>
													</Col>
												</Row>
											</Card>
										</Col>
									</Row>
								))}
							</Radio.Group>
						</Col>
						<Card
							hoverable
							style={{
								boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
								border: "none",
								marginTop: "20px",
								marginBottom: "20px",
							}}
						>

							<Space size={20} direction="horizontal">
								<PlusOutlined style={{ color: "purple", fontSize: "1.5rem" }} />

								<span
									style={{
										fontSize: "20px",
										fontWeight: "bold",
										color: "purple",
									}}
									onClick={() => addressModalRef.current.openForm()}
								>
									Add a New Address
								</span>
							</Space>

						</Card>
						{data.type === "EUDcart" && <Col span={24}>
							<p></p>
						</Col>}
						{data.type === "EUDcart" && <Row style={{ padding: "20px" }}>
							<Col span={16}>
								<Space size={0} direction="vertical">
									<Typography.Title level={5} style={{ margin: 0, marginBottom: 20 }}>
										*Note: Certification for EUD product is madatory to upload before placing order, Please Upload the document in PDF or in png or in jpeg file Format*
									</Typography.Title>
									<EodFormat />
									<UploadFile {...{ fileList, setFileList }} count={1} />
								</Space>
							</Col>
						</Row>}
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: '15px' }}>
						<Col>
							<Row
								style={{
									padding: "13px",
									background:
										"linear-gradient(90deg, rgba(121,79,153,1) 22%, rgba(150,79,153,1) 58%, rgba(97,79,153,1) 90%)",
								}}
							>
								<span style={{ color: "white", fontSize: 18, }} >
									Choose Your Delivery Address
								</span>
							</Row>
							<Radio.Group style={{ width: "100%" }} value={data.addressId} onChange={e => { setData({ ...data, addressId: e.target.value }); }}>
								{address.map((v) => (
									<Row gutter={[12, 12]} key={v._id}>
										<Col span={24}>
											<Card hoverable key={v._id} >
												<Row>
													<Col span={18}>
														<Radio value={v._id} checked={data.addressId === v._id} >
															<span style={{ margin: 0, fontSize: '15px', fontWeight: 'bold' }}>
																{v.name} {v.phone}
															</span>

														</Radio>
													</Col>
													<Col span={4}>
														{
															v.tag
																? <Tag color="grey">{v.tag?.charAt(0).toUpperCase() + v.tag?.slice(1)}</Tag>
																: null
														}
													</Col>
													<Col span={2}>
														<Button style={{ color: "purple", fontSize: "16px", marginTop: '-5px' }} type="text" onClick={() => { addressModalRef.current.openForm(v) }}>
															Edit
														</Button>
													</Col>
												</Row>
												<Row style={{ paddingLeft: "20px", marginTop: '12px' }}>
													<Col span={24}>
														<Space size={10} direction="vertical">
															<span style={{ fontSize: '14px', fontWeight: 'bold' }}>
																{v.address}, {v.city} , {v.state} - {v.pin}
															</span>
															<Button type="primary" onClick={() => { setData({ ...data, addressId: v._id }) }}>Deliver Here</Button>
														</Space>
													</Col>
												</Row>
											</Card>
										</Col>
									</Row>
								))}
							</Radio.Group>
						</Col>
						<Card
							hoverable
							style={{
								boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
								border: "none",
								marginTop: "20px",
								marginBottom: "20px",
							}}
						>

							<Space size={20} direction="horizontal">
								<PlusOutlined style={{ color: "purple", fontSize: "1.5rem" }} />

								<span
									style={{
										fontSize: "20px",
										fontWeight: "bold",
										color: "purple",
									}}
									onClick={() => addressModalRef.current.openForm()}
								>
									Add a New Address
								</span>
							</Space>

						</Card>
						{data.type === "EUDcart" && <Col span={24}>
							<p></p>
						</Col>}
						{data.type === "EUDcart" && <Row style={{ padding: "20px" }}>
							<Col span={16}>
								<Space size={0} direction="vertical">
									<Typography.Title level={5} style={{ margin: 0, marginBottom: 20 }}>
										*Note: Certification for EUD product is madatory to upload before placing order, Please Upload the document in PDF or in png or in jpeg file Format*
									</Typography.Title>
									<EodFormat />
									<UploadFile {...{ fileList, setFileList }} count={1} />
								</Space>
							</Col>
						</Row>}
					</Col>

					<Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: '15px' }}>
						<Col
							style={{
								background: "rgb(245 245 245)",
								paddingRight: "20px",
								paddingLeft: "20px",
								paddingBottom: "20px",
								maxHeight: '620px',
								marginBottom: '20px'
							}}
						>

							<Row>
								<h3 style={{ paddingTop: '10px' }}>PRICE DETAILS</h3>
							</Row>
							<Divider style={{ marginTop: 0 }} />
							<Descriptions
								colon={false}
								contentStyle={{
									placeContent: " end ",
									fontSize: "20px",
									fontWeight: "500",
								}}
								labelStyle={{
									width: "70%",
									fontSize: "16px",
									fontWeight: "500",
									alignItems: " center",
								}}
							>
								<Descriptions.Item span={4} label={"Price "} >
									<Typography.Text
										style={{ fontSize: "18px", fontWeight: "500" }}
									>
										₹{cartTotalValue?.price}
									</Typography.Text>
								</Descriptions.Item>

								<Descriptions.Item span={4} label={"Discount"}>
									<Typography.Text
										style={{ fontSize: "18px", fontWeight: "500" }}
										type="success"
									>
										{" "}
										−₹{cartTotalValue?.discountPrice || 0}
									</Typography.Text>
								</Descriptions.Item>
								{
									cartTotalValue?.couponDiscountPrice
										? <Descriptions.Item span={4} label={"Coupon Discount"}>
											<Typography.Text
												style={{ fontSize: "18px", fontWeight: "500" }}
												type="success"
											>
												{" "}
												− ₹{cartTotalValue?.couponDiscountPrice || 0}
											</Typography.Text>
										</Descriptions.Item>
										: null
								}

								<Descriptions.Item span={4} label={"Delivery Charges"}>
									<Typography.Text style={{ fontSize: "18px", fontWeight: "500" }} type="success" >
										{" "}
										₹{cartTotalValue?.deliveryCharges?.data}
									</Typography.Text>
								</Descriptions.Item>
								<Descriptions.Item span={4} label={"Total GST"}>
									<Typography.Text style={{ fontSize: "18px", fontWeight: "500" }} type="success" >
										₹{cartTotalValue?.gst}
									</Typography.Text>
								</Descriptions.Item>
								{cartTotalValue?.dangerousChemCharge ? <Descriptions.Item span={4} label={"Total Dangerous Chemical Charges"}>
									<Typography.Text style={{ fontSize: "18px", fontWeight: "500" }} type="success" >
										₹{cartTotalValue?.dangerousChemCharge}
									</Typography.Text>
								</Descriptions.Item> : null}
							</Descriptions>

							<Descriptions
								style={{
									borderTop: "solid #f2efefb0",
									borderBottom: "solid #f2efef73",
								}}
								contentStyle={{
									placeContent: " end ",
									fontSize: "20px",
									fontWeight: "500",
								}}
								labelStyle={{
									width: "70%",
									fontSize: "18px",
									fontWeight: "bold",
									alignItems: " center",
								}}
							>
								<Descriptions.Item label="Total Amount" colon={false}>
									<b style={{ fontSize: '18px' }}>₹{(cartTotalValue?.finalPrice) || 0}</b>
								</Descriptions.Item>
							</Descriptions>
						</Col>
						<Button className="app-gradient" block size="large" onClick={placeOrder}>
							Place Order
						</Button>
					</Col>
					<Col xs={{ span: 0 }} sm={{ span: 7 }} style={{ marginLeft: "35px", }}>
						<Col
							style={{
								background: "rgb(245 245 245)",
								paddingRight: "20px",
								paddingLeft: "30px",
								marginBottom: "30px",
								paddingBottom: "20px",
							}}
						>

							<Row>
								<h3>PRICE DETAILS</h3>
							</Row>
							<Divider style={{ marginTop: 0 }} />
							<Descriptions
								colon={false}
								contentStyle={{
									placeContent: " end ",
									fontSize: "23px",
									fontWeight: "500",
								}}
								labelStyle={{
									width: "70%",
									fontSize: "16px",
									fontWeight: "500",
									alignItems: " center",
								}}
							>
								<Descriptions.Item span={4} label={"Price "} >
									₹{cartTotalValue?.price}
								</Descriptions.Item>

								<Descriptions.Item span={4} label={"Discount"}>
									<Typography.Text
										style={{ fontSize: "23px", fontWeight: "500" }}
										type="success"
									>
										{" "}
										− ₹{cartTotalValue?.discountPrice || 0}
									</Typography.Text>
								</Descriptions.Item>
								{
									cartTotalValue?.couponDiscountPrice
										? <Descriptions.Item span={4} label={"Coupon Discount"}>
											<Typography.Text
												style={{ fontSize: "23px", fontWeight: "500" }}
												type="success"
											>
												{" "}
												− ₹{cartTotalValue?.couponDiscountPrice || 0}
											</Typography.Text>
										</Descriptions.Item>
										: null
								}

								<Descriptions.Item span={4} label={"Delivery Charges"}>
									<Typography.Text style={{ fontSize: "23px", fontWeight: "500" }} type="success" >
										{" "}
										₹{cartTotalValue?.deliveryCharges?.data}
									</Typography.Text>
								</Descriptions.Item>
								<Descriptions.Item span={4} label={"Total GST"}>
									<Typography.Text style={{ fontSize: "23px", fontWeight: "500" }} type="success" >
										₹{cartTotalValue?.gst}
									</Typography.Text>
								</Descriptions.Item>
								{cartTotalValue?.dangerousChemCharge ? <Descriptions.Item span={4} label={"Total Dangerous Chemical Charges"}>
									<Typography.Text style={{ fontSize: "23px", fontWeight: "500" }} type="success" >
										₹{cartTotalValue?.dangerousChemCharge}
									</Typography.Text>
								</Descriptions.Item> : null}
							</Descriptions>

							<Descriptions
								style={{
									borderTop: "solid #f2efefb0",
									borderBottom: "solid #f2efef73",
								}}
								contentStyle={{
									placeContent: " end ",
									fontSize: "23px",
									fontWeight: "500",
								}}
								labelStyle={{
									width: "70%",
									fontSize: "18px",
									fontWeight: "bold",
									alignItems: " center",
								}}
							>
								<Descriptions.Item label="Total Amount" colon={false}>
									<b>₹ {(cartTotalValue?.finalPrice) || 0}</b>
								</Descriptions.Item>
							</Descriptions>
						</Col>
						<Button className="app-gradient" block size="large" onClick={placeOrder}>
							Place Order
						</Button>
					</Col>
				</Row>
			</Spin>

			{
				paymentDetails?.razorpayPaymentId
					? <OrderSuccess />
					: null
			}
			<AddressModal ref={addressModalRef} {...{ listAddress }} />


		</>
	);
}


const AddressModal = forwardRef((props, ref) => {
	const { listAddress } = props;

	const [showAddressModal, setShowAddressModal] = useState(false);
	const [data, setData] = useState({});


	useImperativeHandle(ref, () => ({
		openForm(dt) {
			setShowAddressModal(true);
			setData(
				dt
					? { ...dt }
					: {}
			);

		}
	}));

	const handleChange = (value) => {
		setData({ ...data, ...value });
	}
	const handleSave = () => {
		service.saveAddress(data).then((res) => {
			message.info(res.message);
			setData({})
			listAddress();
			setShowAddressModal(false);
		}).catch(err => {
			if (typeof err.message === 'object') {
				let dt = err.message[Object.keys(err.message)[0]];
				message.error(dt);
			} else {
				message.error(err.message);
			}
		})
	}
	return (

		<Modal open={showAddressModal} width={700} onCancel={() => setShowAddressModal(false)}
			title={data._id ? 'Edit Address' : 'Add Address'}
			footer={[
				<Button key="cancel" onClick={() => { setShowAddressModal(false); }}>Cancel</Button>,
				<Button key="save" type="primary" onClick={handleSave} >Save</Button>,
			]}
			closeIcon={<></>}
		>
			<Col span={24}>
				<Form layout="vertical">
					<Row gutter={[12, 2]}>

						<Col span={12}>
							<Form.Item label="Name" required>
								<Input placeholder='Name' value={data.name} onChange={e => { handleChange({ name: e.target.value }) }} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Phone" required>
								<Input placeholder='Phone' value={data.phone} onChange={e => { handleChange({ phone: e.target.value }) }} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="City" required>
								<Input placeholder='City' value={data.city} onChange={e => { handleChange({ city: e.target.value }) }} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="State" required>
								<Input placeholder='State' value={data.state} onChange={e => { handleChange({ state: e.target.value }) }} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="PinCode" required>
								<Input placeholder='Pincode' value={data.pin} onChange={e => { handleChange({ pin: e.target.value }) }} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Tag" required>
								<Select value={data.tag} onChange={e => { handleChange({ tag: e }) }} >
									<Select.Option value={'home'}>Home</Select.Option>
									<Select.Option value={'office'}>Office</Select.Option>
									<Select.Option value={'other'}>Other</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Address" required>
								<Input.TextArea placeholder="Address" value={data.address} onChange={e => { handleChange({ address: e.target.value }) }} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>
		</Modal>
	)
});