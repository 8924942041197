import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import { Breadcrumb, Divider, Typography, Card, Row, Col, Modal, Steps, Button, Tag, Collapse, Avatar, Input, Space, Descriptions } from 'antd';
import Search from "antd/es/input/Search";
// import { FrownFilled } from "@ant-design/icons";

import { theme } from "antd";
import Side from "../../components/Side";
import service from "../../service/order"
import Pagination from "../../components/Pagination";
import moment from 'moment'
import { Link } from "react-router-dom";
import Nodata from "../../components/Nodata";
import { useNavigate } from "react-router-dom";
import { UserOutlined, ShoppingCartOutlined } from "@ant-design/icons";


const MyOrder = () => {
    const [data, setData] = useState([])
    const [qData, setQData] = useState({ page: 1, limit: 5, });

    const orderDetailModal = useRef();
    const mobileReponsiveOrderDetailModal = useRef();

    const navigate = useNavigate()

    const {
        token: { colorBgContainer }
    } = theme.useToken();

    const list = () => {
        service.listOrder(qData).then(res => {
            setData(res.data)
            setQData({ ...qData, limit: res.extra.limit, page: res.extra.page, total: res.extra.total });
        });
    }

    useEffect(() => {
        list();
    }, [qData.page, qData.limit,]);


    const handleMenu = (key) => {
        key ? navigate(key === '/account-setting' ? '/profile' : '/' + key ? '/wishlist' : '/' + key) : navigate('/myorder')
        // navigate( '/'+ key) 
    }


    return (
        <>
            <Breadcrumb style={{ margin: '20px' }}>
                <Breadcrumb.Item> <Link to={"/"}>Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>My Order</Breadcrumb.Item>
            </Breadcrumb>
            <Divider />

            <DetaiModal ref={orderDetailModal} />
            <MobileResponsiveDetaiModal ref={mobileReponsiveOrderDetailModal} />


            <Row style={{ padding: '8px' }} justify="space-around">
                <Col xs={{ span: 0 }} sm={{ span: 20 }}>
                    <Row gutter={[80]}>
                        <Col span={8}><Side /></Col>
                        <Col span={16}>
                            <Search
                                placeholder="Search Your Order"
                                size='large' enterButton="Search"
                                style={{ width1: "1000px", color: "grey", boxShadow: "10px 10px 30px #00000029", }}
                                value={qData.key}
                                onChange={e => { setQData({ ...qData, key: e.target.value }) }}
                                onSearch={list}
                            />
                            {
                                data.length === 0 ?
                                    <>
                                        <Typography.Title level={1} style={{ marginTop: 0, marginBottom: 20, marginLeft: -100, fontSize: "xxx-large" }}>
                                            <Nodata text="You have't ordered yet" />
                                        </Typography.Title>
                                    </>
                                    :
                                    data?.map((val, i) => (
                                        <Card
                                            onClick={() => orderDetailModal.current.openForm(val)}
                                            hoverable
                                            style={{
                                                // width: 1000,
                                                marginBottom: 10,
                                                marginTop: 20,
                                                border: "none",
                                                boxShadow: "10px 10px 30px #00000029"
                                            }}
                                            key={i}
                                        >
                                            <Row>
                                                <Col span={10}>
                                                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 20, marginLeft: 0, color: "gray" }}> Order Id: </Typography.Title>
                                                    <Typography.Title level={3} style={{ marginTop: -20, marginBottom: 20, marginLeft: 0, color: "black" }}><b>{val.orderId}</b></Typography.Title>
                                                    <Typography.Title level={5}>
                                                        {val.status === "payment-success" ? <Tag color="blue">Payment Success</Tag> : val.status === "initiated" ? <Tag color="cyan">Initiated</Tag> : val.status === "delivered" ? <Tag color="green">Delivered</Tag> : val.status === "shipped" ? <Tag color="blue-inverse">Shipped</Tag> : val.status === "failed" ? <Tag color="red">Failed</Tag> : null}
                                                    </Typography.Title>
                                                    <Descriptions title={null} size="small">
                                                        <Descriptions.Item label="On Credit"> {val?.priceDetails?.isCredit ? "YES" : "NO"}</Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col span={14}>
                                                    <Descriptions title="Payment Details" bordered size="small" column={1}>
                                                        <Descriptions.Item label="Price"> ₹ {val?.priceDetails?.price}</Descriptions.Item>
                                                        <Descriptions.Item label="Discount Price"> ₹ {val?.priceDetails?.discountPrice}</Descriptions.Item>
                                                        <Descriptions.Item label="Coupon Discount Price"> ₹ {val?.priceDetails?.couponDiscountPrice||0}</Descriptions.Item>
                                                        <Descriptions.Item label="Gst"> ₹ {val?.priceDetails?.gst||0}</Descriptions.Item>
                                                        <Descriptions.Item label="Delivery Price"> ₹ {val?.priceDetails?.deliveryCharges?.date||0}</Descriptions.Item>
                                                        <Descriptions.Item label="Dangerous Product Charge"> ₹ {val?.priceDetails?.dangerousChemCharge||0}</Descriptions.Item>
                                                        <Descriptions.Item label={<b>Final Price</b>}> <b>₹ {val?.priceDetails?.finalPrice}</b></Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                            <Row>
                                <Col span={24}>
                                    <Divider />
                                    <Pagination {...{ qData, setQData }} />
                                    <Divider />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {/* mobile view  */}
                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <Row style={{ width: '100%', background: 'transparent linear-gradient(268deg, #3E3185 0%, #774F9A 26%, #6B2F85 74%, #393185 100%) 0% 0% no-repeat padding-box' }}>
                        <Col span={6} style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                            <Avatar size={60} icon={<UserOutlined />} />
                        </Col>
                        <Col span={18}>
                            <Typography.Title level={5} style={{ color: 'white', marginTop: 8 }}>Hello</Typography.Title>
                            <Typography.Title level={4} style={{ color: 'white', marginTop: 8 }}>Amit Lab Equipment</Typography.Title>
                        </Col>
                    </Row>
                    <Collapse expandIconPosition={'end'} activeKey={['/myorder']} accordion onChange={handleMenu}>
                        <Collapse.Panel
                            header={<div>
                                <ShoppingCartOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Order </span>
                            </div>} key="/myorder">
                            <Row>
                                <Col span={24}>
                                    <Input onChange={e => { setQData({ ...qData, key: e.target.value }) }} value={qData.key} placeholder="Search Your Product" size='large' enterButton="Search" style={{ background: "#e8e8e8", border: '0px' }} />
                                    <Button onClick={list} style={{ width: '100%', marginTop: '18px' }} size='large' type="primary" >
                                        Search
                                    </Button>
                                    {
                                        data.length === 0 ?
                                            <>
                                                <Typography.Title level={1} style={{ marginTop: 0, marginBottom: 20, marginLeft: -100, fontSize: "xxx-large" }}><Nodata text="You have't Ordered Yet" /></Typography.Title>
                                            </>
                                            :
                                            data?.map((val, i) => (
                                                <Card
                                                    onClick={() => mobileReponsiveOrderDetailModal.current.openForm(val)}
                                                    hoverable
                                                    style={{
                                                        // width: 1000,
                                                        marginBottom: 10,
                                                        marginTop: 10,
                                                        border: "1px solid lightgray",
                                                        boxShadow: "10px 10px 30px #00000029"
                                                    }}
                                                    key={i}
                                                >
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <span style={{ fontWeight: 600, fontSize: '15px', color: "gray" }}> Order Id: </span>
                                                                {val.status === "payment-success" ? <Tag color="blue">Payment Success</Tag> : val.status === "initiated" ? <Tag color="cyan">Initiated</Tag> : val.status === "delivered" ? <Tag color="green">Delivered</Tag> : val.status === "shipped" ? <Tag color="blue-inverse">Shipped</Tag> : val.status === "failed" ? <Tag color="red">Failed</Tag> : null}
                                                            </div>
                                                            <Typography.Title style={{ marginTop: 10, marginLeft: 0, color: "black", fontSize: '15px' }}><b>{val.orderId}</b></Typography.Title>
                                                            <Typography.Title level={4} style={{ marginTop: -5, marginBottom: 20, marginLeft: 0, color: "black" }}><b> ₹ {val?.priceDetails?.finalPrice}</b></Typography.Title>
                                                        </Col>
                                                    </Row>
                                                </Card>

                                            ))}
                                </Col>
                            </Row>
                        </Collapse.Panel>
                        <Collapse.Panel header={<div>
                            <UserOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>Account Setting </span>
                        </div>} key="/account-setting">
                            <Collapse bordered={false} expandIconPosition={'end'} accordion onChange={(key) => {
                                navigate(key ? '/' + key : '/address')
                            }}>
                                <Collapse.Panel header={
                                    <span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Profile Information</span>
                                } key="/profile">
                                    <p>hello world2</p>
                                </Collapse.Panel>
                                <Collapse.Panel
                                    header={<span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Manage Address</span>} key="/address">
                                    <p>hello world2</p>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>
                        <Collapse.Panel header={<div>
                            <UserOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Stuff </span>
                        </div>} key="/mystuff">
                            <Collapse bordered={false} expandIconPosition={'end'} accordion onChange={(key) => {
                                navigate(key ? '/' + key : '/wishlist')
                            }}>
                                <Collapse.Panel header={
                                    <span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>My Wishlist</span>
                                } key="/wishlist">
                                    <p>hello world2</p>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>
        </>

    )


}


const DetaiModal = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [address, setAddress] = useState()
    const [order, setOrder] = useState()
    const [id, setId] = useState(null)


    const getDetails = (id) => {
        service.detailOrder({ orderId: id }).then(res => {
            setData(res.data || [])
            // setId(id)
        })
    }
    useEffect(() => {
        getDetails(id);
    }, [id]);

    useImperativeHandle(ref, () => ({
        openForm(val) {
            // console.clear();
            // console.log(val)
            setOpen(true);
            setId(val._id);
            setAddress((val.addressDetails) ? JSON.parse(val.addressDetails) : {})
            setOrder(val.orderStatusDetails)
        }
    }));

    return (
        <>
            <Modal
                // title="Modal 1000px width"
                centered
                className="app-modal-without-footer-body-overflow"
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1500}
                footer={null}
            >
                {/* <Breadcrumb style={{ marginLeft: "20px" }}>
                    <Breadcrumb.Item> <Link to={"/"}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Profile</Breadcrumb.Item>
                </Breadcrumb>
                <Divider /> */}
                <Card style={{ border: "none", boxShadow: "10px 10px 30px #00000029" }}
                    hoverable
                >
                    <Steps
                        style={{ color: "green" }}
                        current={order?.length - 1 || 0}
                        status="finish"
                        items={[
                            {
                                title: order?.[0]?.status || 'Initiated',
                                description: order?.[0]?.date ? moment(order?.[0]?.date)?.format("YYYY-MM-DD") : "",
                            },
                            {
                                title: order?.[1]?.status || 'Payment Successfull',
                                description: order?.[1]?.date ? moment(order?.[1]?.date)?.format("YYYY-MM-DD") : "",
                            },
                            {
                                title: order?.[2]?.status || 'Shipped',
                                description: order?.[2]?.date ? moment(order?.[2]?.date)?.format("YYYY-MM-DD") : "",
                            },
                            {
                                title: order?.[3]?.status || 'Delivered',
                                description: order?.[3]?.date ? moment(order?.[3]?.date)?.format("YYYY-MM-DD") : "",
                            },
                        ]}
                    />
                </Card >
                <Card hoverable style={{ marginTop: 20, marginBottom: 70, marginLeft: 0, color: "gray", border: "none", boxShadow: "10px 10px 30px #00000029", }}>
                    <Row>
                        <Col span={16} style={{ marginLeft: 20 }}>
                            <Typography.Title level={5} style={{ marginTop: 5, marginBottom: 20, marginLeft: 0, color: "black" }}><b> Delivery Address 1</b> </Typography.Title>
                            <Typography.Title level={4} style={{ marginTop: 20, marginBottom: 10, marginLeft: 0, color: "black" }}><b> {address?.name}</b></Typography.Title>
                            <Typography.Title level={4} style={{ marginTop: 10, marginBottom: 20, marginLeft: 0, color: "gray" }}> {address?.address} {address?.city} </Typography.Title>
                            <Typography.Title level={4} style={{ marginTop: -18, marginBottom: 20, marginLeft: 0, color: "gray" }}> {address?.state} :- {address?.pin}</Typography.Title>
                            <Typography.Title level={4} style={{ marginTop: 20, marginBottom: 20, marginLeft: 0, color: "black" }}><b> Contact Number :-</b> {address?.phone} </Typography.Title>
                        </Col>
                        {(order?.[0]?.status === "delivered" || order?.[1]?.status === "delivered" || order?.[2]?.status === "delivered" || order?.[3]?.status === "delivered") && <Col span={4}>
                            <a href={`/invoice/${id}`} target="_blank" style={{ fontWeight: "600", textDecoration: "underline", color: "darkblue" }}>Download Invoice</a>

                        </Col>}
                        {/* <Col span={6}>
                            <Button size="large" style={{ marginTop: 20, marginBottom: 20, marginLeft: 250, color: "#531dab" }}><b>Download Invoice</b></Button>
                        </Col> */}
                    </Row>
                </Card>
                {
                    data?.map((val, i) => (
                        <Card
                            style={{
                                width: 1500,
                                margin: 10,
                                border: "none",
                                marginLeft: 20,
                                // boxShadow: "10px 10px 30px #00000029"
                            }}
                            key={i}
                        >
                            <Row>
                                <Col span={6}>
                                    <img src={val.productDetails.thumbImg} width="150px" height="150px" style={{ marginTop: -75 }} />
                                </Col>
                                <Col span={6}>
                                    <Typography.Title level={3} style={{ marginTop: -50, marginBottom: 20, marginLeft: -100, color: "#595959" }}><b>{val.productDetails.name}</b> </Typography.Title>
                                    <Typography.Title level={4} style={{ marginTop: -0, marginBottom: 20, marginLeft: -100, color: "#8c8c8c" }}><b>({val.productVariantDetails.catId})</b> </Typography.Title>
                                </Col>
                                <Col span={12}>
                                    <Typography.Title level={3} style={{ marginTop: -50, marginBottom: 0, marginLeft: 400, color: "black", }}>
                                        {
                                            val?.withDiscountPrice
                                                ? <>
                                                    <del style={{ fontSize: 16, fontWeight: 500 }}> ₹ {val?.price?.toFixed(2)}</del>
                                                    <b> ₹ {val?.withDiscountPrice?.toFixed(2)}</b>
                                                </>
                                                : <>
                                                    <b> ₹ {val?.price?.toFixed(2)}</b>
                                                </>
                                        }
                                    </Typography.Title>
                                </Col>
                                <Divider />
                            </Row>
                        </Card>
                    ))}


            </Modal>
        </>
    );
})
const MobileResponsiveDetaiModal = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [address, setAddress] = useState()
    const [order, setOrder] = useState()
    const [id, setId] = useState(null)


    const getDetails = (id) => {
        service.detailOrder({ orderId: id }).then(res => {
            setData(res.data || [])
        })
    }
    useEffect(() => {
        getDetails(id)
    }, [id]);

    useImperativeHandle(ref, () => ({
        openForm(val) {
            setOpen(true);
            setId(val?._id);
            setAddress((val.addressDetails) ? JSON.parse(val.addressDetails) : {})
            setOrder(val.orderStatusDetails)
        }
    }));

    return (
        <>
            <Modal
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Card style={{ border: "none", boxShadow: "10px 10px 30px #00000029" }}>
                    <Steps
                        style={{ color: "green" }}
                        current={order?.length - 1 || 0}
                        status="finish"
                        items={[
                            {
                                title: order?.[0]?.status || 'Initiated',
                                description: order?.[0]?.date ? moment(order?.[0]?.date)?.format("YYYY-MM-DD") : "",
                            },
                            {
                                title: order?.[1]?.status || 'Payment Successfull',
                                description: order?.[1]?.date ? moment(order?.[1]?.date)?.format("YYYY-MM-DD") : "",
                            },
                            {
                                title: order?.[2]?.status || 'Shipped',
                                description: order?.[2]?.date ? moment(order?.[2]?.date)?.format("YYYY-MM-DD") : "",
                            },
                            {
                                title: order?.[3]?.status || 'Delivered',
                                description: order?.[3]?.date ? moment(order?.[3]?.date)?.format("YYYY-MM-DD") : "",
                            },
                        ]}
                    />
                </Card >
                <Card style={{ marginTop: 20, marginLeft: 0, color: "gray", border: "none", boxShadow: "10px 10px 30px #00000029" }}>
                    <Row>
                        <Col span={24} style={{ padding: '10px' }}>
                            <Typography.Title level={4} style={{ marginTop: 5, marginBottom: 20, color: "black" }}><b> Delivery Address 2</b> </Typography.Title>
                            <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 10, color: "black" }}><b> {address?.name}</b></Typography.Title>
                            <Typography.Title style={{ marginTop: 10, marginBottom: 20, color: "gray", fontSize: '15px' }}> {address?.address} {address?.city} </Typography.Title>
                            <Typography.Title level={5} style={{ marginTop: -18, color: "gray" }}> {address?.state} : {address?.pin}</Typography.Title>
                            <Typography.Title level={5} style={{ marginTop: 10, marginBottom: 20, color: "black" }}><b> Phone Number :</b> </Typography.Title>
                            <Typography.Title level={5} style={{ marginTop: -18, color: "gray" }}> {address?.phone} </Typography.Title>

                            {(order?.[0]?.status === "delivered" || order?.[1]?.status === "delivered" || order?.[2]?.status === "delivered" || order?.[3]?.status === "delivered") && <a href={`/invoice/${id}`} target="_blank" style={{ fontWeight: "600", textDecoration: "underline", color: "darkblue" }}><Button size="large" style={{ marginTop: 20, marginBottom: 10, color: "#531dab", width: '100%' }}><b>Download Invoice</b></Button></a>}
                        </Col>
                    </Row>
                </Card>
                <Card
                    style={{
                        border: "none",
                        boxShadow: "10px 10px 30px #00000029",
                        marginTop: 20
                    }}
                >
                    {
                        data?.map((val, i) => (
                            <Row style={{ padding: 0 }} key={i}>
                                <Col span={6}>
                                    <img
                                        alt="example"
                                        style={{ height: "85px", maxWidth: "85px", marginTop: '10px' }}
                                        src={val.productDetails.thumbImg}
                                    />
                                </Col>
                                <Col span={12} style={{ textAlign: 'center', marginTop: '35px' }}>
                                    {/* <Space direction="vertical" size={0}> */}
                                    <span style={{ fontSize: '14px', fontWeight: 600 }}>
                                        {val.productDetails.name}
                                    </span>
                                    {/* </Space> */}
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical" size={0} >
                                        <h3 style={{ marginTop: '35px', marginBottom: '16px' }}>
                                            {
                                                val?.withDiscountPrice
                                                    ? <>
                                                        <del style={{ fontSize: 16, fontWeight: 500 }}> ₹ {val?.price?.toFixed(2)}</del>
                                                        <b> ₹ {val?.withDiscountPrice?.toFixed(2)}</b>
                                                    </>
                                                    : <>
                                                        <b>  {"₹" + val?.price?.toFixed(2)}</b>
                                                    </>
                                            }
                                        </h3>
                                    </Space>
                                </Col>
                                <Divider style={{ margin: 10 }} />
                            </Row>
                        ))}
                </Card >


            </Modal >
        </>
    );
})



export default MyOrder;

