import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import service from "../service/application"
import parse from 'html-react-parser';
import { Col, Row } from "antd";
import Nodata from "./Nodata";

const WebInfo = () => {
    const { key } = useParams()

    const [data, setData] = useState("")

    const fetchData = () => {
        service.list(key).then((res) => (
            setData(res.data)
        ))
    }

    useEffect(() => {
        fetchData()
    }, [key])
    return <>
        {
            <Row justify='center'>
                <Col span={16} >
                    { data?.length ?  parse(data) : <Nodata/> } 
                </Col>
            </Row>
        }
    </>;
};

export default WebInfo;
