/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Breadcrumb,
  Tabs,
  Divider,
  Tag,
  Space,
  Input,
  Button,
  Descriptions,
  Spin,
  Badge,
  Checkbox,
  message,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DeleteFilled } from "@ant-design/icons";
import cart, {
  fetchCart,
  incrementWithSave,
  decrementWithSave,
  removeWithSave,
} from "../../redux/cart";
import cartService from "../../service/cart";
import couponService from "../../service/coupon";
import getquoteService from "../../service/get-qoute";
import serviceUser from "../../service/user";

const { Text } = Typography;

export default function ShoppingCart() {
  const [userName,setUserName]=useState("")
  const [cartType, setCartType] = useState("myCart");
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.value || []);
  const cartDataLoadingStatus = useSelector((state) => state.cart.status || []);
  const [calculatingPrice, setCalculatingPrice] = useState(false);
  const [coupon, setCoupon] = useState({ title: "" });
  const [cartTotalValue, setCartTotalValue] = useState({});
  const [applyCouponRequest, setApplyCouponRequest] = useState({
    status: false,
    message: "",
  });
  const [check, setCheck] = useState(true);

  console.log("");

  const detailUser = () => {
    serviceUser.detailUser().then((res) => {
      setUserName(res?.data);
    })
}

useEffect(() => {
    detailUser()
}, [])

  const getQuote=()=>{
    getquoteService.save({
      cart:cartData,
      total:cartTotalValue?.finalPrice,
      type:cartData?.[0].type,
      user:userName?.name,
      email:userName?.email,
      phone:userName?.phone

    }).then(()=>{
      message.success("We will get back to you");
    }).catch((err)=>{
      message("error")
    })
  }

  const calculatePrice = () => {
    setCalculatingPrice(true);
    cartService
      .calculatePrice({ type: cartType })
      .then((res) => {
        setCartTotalValue(res.data);
      })
      .catch((err) => { })
      .finally(() => {
        setCalculatingPrice(false);
      });
  };

  const submitCoupon = () => {
    setApplyCouponRequest({ status: true });
    couponService
      .validateCoupon({
        code: coupon.title,
        type: localStorage.getItem("cartType"),
      })
      .then((res) => {
        setCartTotalValue({ ...res.data });
        window.localStorage.setItem("coupon", coupon.title);
        setApplyCouponRequest({
          status: false,
          message: res.message,
          type: "success",
        });
      })
      .catch((err) => {
        window.localStorage.removeItem("coupon");
        setApplyCouponRequest({
          status: false,
          message: err.message,
          type: "fail",
        });
        calculatePrice();
      });
  };
  useEffect(() => {
    // dispatch(fetchCart());
    setCheck(true);
    couponService
      .validateCoupon({
        code: window.localStorage.getItem("coupon"),
        type: localStorage.getItem("cartType"),
      })
      .then((res) => {
        window.localStorage.setItem("coupon", coupon);
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    if (!coupon.title) {
      submitCoupon();
    }
  }, [coupon.title]);

  const submtCoupon = () => {
    setApplyCouponRequest({ status: true });
    couponService
      .validateCoupon({
        code: coupon.title,
        type: localStorage.getItem("cartType"),
      })
      .then((res) => {
        setCartTotalValue({ ...res.data });
        window.localStorage.setItem("coupon", coupon.title);
        setApplyCouponRequest({
          status: false,
          message: res.message,
          type: "success",
        });
      })
      .catch((err) => {
        window.localStorage.removeItem("coupon");
        if (coupon.title) {
          setApplyCouponRequest({
            status: false,
            message: err.message,
            type: "fail",
          });
        } else {
          setApplyCouponRequest({ status: false, message: "", type: "fail" });
        }
        calculatePrice();
      });
  };

  useEffect(() => {
    if (!coupon.title) {
      submtCoupon();
    }
  }, [coupon.title]);

  useEffect(() => {
    calculatePrice();
  }, [cartDataLoadingStatus]);

  useEffect(() => {
    dispatch(fetchCart({ type: cartType }));
    localStorage.setItem("cartType", cartType);
  }, [cartType]);


  return (
    <>
      <Row style={{ padding: "20px" }}>
        <Breadcrumb style={{ marginLeft: "20px" }}>
          <Breadcrumb.Item>
            <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={"/cart"}
              style={{ color: "black", fontSize: "500", fontWeight: "bolder" }}
            >
              Shopping Cart
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Col xs={{ span: 0 }} sm={{ span: 16 }}>
          <Card
            hoverable
            style={{
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              border: "none",
              margin: "20 0",
            }}
          >
            <Tabs
              size="large"
              defaultActiveKey="1"
              items={[
                {
                  label: "My Cart",
                  key: "1",
                  children: (
                    <Cart type="myCart" {...{ cartDataLoadingStatus }} />
                  ),
                },
                {
                  label: (
                    <>
                      EUD Cart{" "}
                      <span style={{ zoom: 2 }}>
                        <Badge status="processing" text=" " color="red" />
                      </span>{" "}
                    </>
                  ),
                  key: "2",
                  children: (
                    <Cart type="EUDcart" {...{ cartDataLoadingStatus }} />
                  ),
                },
              ]}
              onChange={(key) => {
                if (key === "2") {
                  setCartType("EUDcart");
                } else {
                  setCartType("myCart");
                }
              }}
            />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 0 }}>
          <Card
            hoverable
            style={{
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              border: "none",
              margin: "20 0",
            }}
          >
            <Tabs
              size="large"
              defaultActiveKey="1"
              items={[
                {
                  label: "My Cart",
                  key: "1",
                  children: (
                    <MobileResponsiveCart
                      type="myCart"
                      {...{ cartDataLoadingStatus }}
                    />
                  ),
                },
                {
                  label: "EDU Cart",
                  key: "2",
                  children: (
                    <MobileResponsiveCart
                      type="EUDcart"
                      {...{ cartDataLoadingStatus }}
                    />
                  ),
                },
              ]}
              onChange={(key) => {
                if (key === "2") {
                  setCartType("EUDcart");
                } else {
                  setCartType("myCart");
                }
              }}
            />
          </Card>
        </Col>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 7 }}
          style={{
            background: "rgb(245 245 245)",
            marginLeft: "35px",
            paddingRight: "20px",
            paddingLeft: "30px",
            paddingBottom: "20px",
            maxHeight: "650px",
          }}
        >
          <Spin spinning={calculatingPrice}>
            <Row>
              <h3>PRICE DETAILS</h3>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Descriptions
              colon={false}
              contentStyle={{
                placeContent: " end ",
                fontSize: "23px",
                fontWeight: "500",
              }}
              labelStyle={{
                width: "70%",
                fontSize: "16px",
                fontWeight: "500",
                alignItems: " center",
              }}
            >
              <Descriptions.Item
                span={4}
                label={"Price (" + cartData.length + ")"}
              >
                ₹{cartTotalValue?.price}
              </Descriptions.Item>

              {cartTotalValue.discountPrice ? <Descriptions.Item span={4} label={"Discount"}>
                <Text
                  style={{ fontSize: "23px", fontWeight: "500" }}
                  type="success"
                >
                  {" "}
                  − ₹{cartTotalValue?.discountPrice || 0}
                </Text>
              </Descriptions.Item> : null}
              {cartTotalValue?.couponDiscountPrice ? (
                <Descriptions.Item span={4} label={"Coupon Discount"}>
                  <Text
                    style={{ fontSize: "23px", fontWeight: "500" }}
                    type="success"
                  >
                    {" "}
                    − ₹{cartTotalValue?.couponDiscountPrice || 0}
                  </Text>
                </Descriptions.Item>
              ) : null}

              <Descriptions.Item span={4} label={"Delivery Charges"}>
                <Text
                  style={{ fontSize: "23px", fontWeight: "500" }}
                  type="success"
                >
                  {" "}
                  ₹{cartTotalValue?.deliveryCharges?.data}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item span={4} label={"Total GST"}>
                <Typography.Text style={{ fontSize: "23px", fontWeight: "500" }} type="success" >
                  ₹{cartTotalValue?.gst}
                </Typography.Text>
              </Descriptions.Item>
              {cartTotalValue?.dangerousChemCharge ? <Descriptions.Item span={4} label={"Total Dangerous Chemical Charges"}>
                <Typography.Text style={{ fontSize: "23px", fontWeight: "500" }} type="success" >
                  ₹{cartTotalValue?.dangerousChemCharge}
                </Typography.Text>
              </Descriptions.Item> : null}
            </Descriptions>

            <Descriptions
              style={{
                borderTop: "solid #f2efefb0",
                borderBottom: "solid #f2efef73",
              }}
              contentStyle={{
                placeContent: " end ",
                fontSize: "23px",
                fontWeight: "500",
              }}
              labelStyle={{
                width: "70%",
                fontSize: "18px",
                fontWeight: "bold",
                alignItems: " center",
              }}
            >
              <Descriptions.Item label="Total Amount" colon={false}>
                <b>₹ {cartTotalValue?.finalPrice || 0}</b>
              </Descriptions.Item>
            </Descriptions>

            <Divider />
            <Row justify="space-between" gutter={[12, 12]}>
              <Col span={16}>
                <Input
                  size="large"
                  placeholder="Apply Coupon"
                  onChange={(e) => setCoupon({ title: e.target.value })}
                />
              </Col>
              <Col span={8}>
                <Button
                  size="large"
                  className="app-gradient"
                  onClick={submtCoupon}
                  block
                  disabled={!coupon.title?.length || applyCouponRequest.status}
                  loading={applyCouponRequest.status}
                  style={{ color: "white" }}
                >
                  Apply Coupon
                </Button>
              </Col>
              <Col span={24}>
                {applyCouponRequest?.message?.length ? (
                  <Typography.Text
                    type={
                      applyCouponRequest.type === "fail" ? "danger" : "success"
                    }
                  >
                    {applyCouponRequest.message}
                  </Typography.Text>
                ) : null}
              </Col>
              <Col span={24}>
              <Link >
                <Button size="large" disabled={check} block onClick={getQuote}>
                  {" "}
                  
                  Get Quote{" "}
                  
                </Button>
                </Link>
                 
              </Col>
              <Col span={24}>
                <Link to={"/PlaceOrder"}>
                  <Button size="large" type="primary" disabled={check} block>
                    Place Order
                  </Button>
                </Link>
              </Col>
              <Col span={24} style={{ padding: 0 }}>
                <span style={{ fontSize: "12px", paddingRight: "7px" }}>
                  {" "}
                  <Checkbox onChange={(e) => setCheck(!e.target.checked)} />
                </span>
                <span style={{ fontSize: "12px" }}>
                  {" "}
                  All Chemicals are ment for research use/lab use only. We
                  refuse all liabilities for accidents arising out of handling
                  or storage of the materials ordered. Buyer shall need to
                  submit GSTIN/Company ID/institute ID and perpose use of
                  chemicals to support@amplieto.com
                </span>
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 0 }}
          style={{
            background: "rgb(245 245 245)",
            paddingRight: "20px",
            paddingLeft: "20px",
            paddingBottom: "20px",
            maxHeight: "620px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Spin spinning={calculatingPrice}>
            <Row>
              <h3 style={{ paddingTop: "10px" }}>PRICE DETAILS</h3>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Descriptions
              colon={false}
              contentStyle={{
                placeContent: " end ",
                fontSize: "20px",
                fontWeight: "500",
              }}
              labelStyle={{
                width: "70%",
                fontSize: "16px",
                fontWeight: "500",
                alignItems: " center",
              }}
            >
              <Descriptions.Item
                span={3}
                label={"Price (" + cartData.length + ")"}
              >
                <Text style={{ fontSize: "18px", fontWeight: "500" }}>
                  ₹{cartTotalValue?.price}
                </Text>
              </Descriptions.Item>

              <Descriptions.Item span={5} label={"Discount"}>
                <Text
                  style={{ fontSize: "18px", fontWeight: "500" }}
                  type="success"
                >
                  −₹{cartTotalValue?.discountPrice || 0}
                </Text>
              </Descriptions.Item>
              {cartTotalValue?.couponDiscountPrice ? (
                <Descriptions.Item span={4} label={"Coupon Discount"}>
                  <Text
                    style={{ fontSize: "18px", fontWeight: "500" }}
                    type="success"
                  >
                    {" "}
                    −₹{cartTotalValue?.couponDiscountPrice || 0}
                  </Text>
                </Descriptions.Item>
              ) : null}

              <Descriptions.Item span={4} label={"Delivery Charges"}>
                <Text
                  style={{ fontSize: "18px", fontWeight: "500" }}
                  type="success"
                >
                  {" "}
                  ₹{cartTotalValue?.deliveryCharges?.data}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item span={4} label={"Total GST"}>
                <Typography.Text style={{ fontSize: "18px", fontWeight: "500" }} type="success" >
                  ₹{cartTotalValue?.gst}
                </Typography.Text>
              </Descriptions.Item>
            </Descriptions>

            <Descriptions
              style={{
                borderTop: "solid #f2efefb0",
                borderBottom: "solid #f2efef73",
              }}
              contentStyle={{
                placeContent: " end ",
                fontSize: "23px",
                fontWeight: "500",
              }}
              labelStyle={{
                width: "70%",
                fontSize: "18px",
                fontWeight: "bold",
                alignItems: " center",
              }}
            >
              <Descriptions.Item label="Total Amount" colon={false}>
                <b style={{ fontSize: "18px" }}>
                  ₹ {cartTotalValue?.finalPrice || 0}
                </b>
              </Descriptions.Item>
            </Descriptions>

            <Divider />
            <Row justify="space-between" gutter={[12, 12]}>
              <Col span={14}>
                <Input
                  size="large"
                  placeholder="Apply Coupon"
                  onChange={(e) => setCoupon({ title: e.target.value })}
                />
              </Col>
              <Col span={10}>
                <Button
                  size="large"
                  className="app-gradient"
                  onClick={submtCoupon}
                  block
                  disabled={!coupon.title?.length || applyCouponRequest.status}
                  loading={applyCouponRequest.status}
                  style={{ color: "white", fontSize: "13px" }}
                >
                  Apply Coupon
                </Button>
              </Col>
              <Col span={24}>
                {applyCouponRequest?.message?.length ? (
                  <Typography.Text
                    type={
                      applyCouponRequest.type === "fail" ? "danger" : "success"
                    }
                  >
                    {applyCouponRequest.message}
                  </Typography.Text>
                ) : null}
              </Col>
              <Col span={24}>
              <Link >
                <Button size="large" disabled={check} block onClick={getQuote}>
                  {" "}
                  Get Quote{" "}
                </Button>
            </Link>
              </Col>
              <Col span={24}>
                <Link to={"/PlaceOrder"}>
                  <Button size="large" type="primary" disabled={check} block>
                    Place Order
                  </Button>
                </Link>
              </Col>
              <Col span={24} style={{ padding: 0, textAlign: "center" }}>
                <span style={{ fontSize: "12px" }}>
                  {" "}
                  <Checkbox onChange={(e) => setCheck(!e.target.checked)} />
                  All Chemicals are ment for research use/lab use only.
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>
                  We refuse all liabilities for accidents arising out of
                  handling or storage of the materials ordered. Buyer shall need
                  to submit GSTIN/Company ID/institute ID and perpose use of
                  chemicals to support@amplieto.com
                </span>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row >
    </>
  );
}

const Cart = ({ type = "myCart", data, cartDataLoadingStatus }) => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.value || []);

  return (
    <>
      <Row>
        <Col span={10}>
          {" "}
          <span style={{ fontSize: "20px", fontWeight: 500 }}>Product</span>
        </Col>
        <Col span={8}>
          {" "}
          <span style={{ fontSize: "20px", fontWeight: 500 }}>Quantity</span>
        </Col>
        <Col span={6}>
          {" "}
          <span style={{ fontSize: "20px", fontWeight: 500 }}>Total</span>
        </Col>
      </Row>
      {cartData?.length > 0 &&
        cartData?.map((v, i) => (
          <Fragment key={i}>
            <Divider style={{ marginTop: 5 }} />
            <Row>
              <Col span={10}>
                <Row>
                  <Col span={9}>
                    <img
                      alt="example"
                      style={{ height: "100px", maxWidth: "120px" }}
                      src={v.thumbImgUrl}
                    />
                  </Col>
                  <Col span={10}>
                    <span>Chemicals</span>
                    <h2 style={{ marginTop: 0, marginBottom: 0 }}>
                      {v.productName}
                    </h2>
                    {v?.productSubName && <span style={{ display: "block" }}>({v.productSubName})</span>}
                    <Space size={[0, 8]} wrap>

                      {v.offerPercentage ? (
                        <div style={{ marginTop: 5 }}>
                          <Tag color="yellow">Disc {v.offerPercentage}%</Tag>
                        </div>
                      ) : null}
                      {v.gst ? (
                        <div style={{ marginTop: 5 }}>
                          <Tag color="green">GST {v.gst}%</Tag>
                        </div>
                      ) : null}
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Space direction="horizontal">
                  <Button
                    onClick={() => {
                      dispatch(
                        decrementWithSave({
                          productVariantId: v.productVariantId,
                          dispatch,
                          type: v.type,
                        })
                      );
                    }}
                  >
                    -
                  </Button>
                  <Button>{v?.quantity}</Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        incrementWithSave({
                          productVariantId: v.productVariantId,
                          dispatch,
                          type: v.type,
                        })
                      );
                    }}
                  >
                    +
                  </Button>
                </Space>
              </Col>
              <Col span={6}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3
                  style={{ display: "flex", justifyContent: "space-between",flexDirection:"column", gap:"10px" }}
                  >
                    ₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
                    
                   {v?.extraCharge ? <span style={{color:"limegreen"}}>+₹{v?.extraCharge} (dangerous chemical charges)</span> : null}
                  </h3>
                  <DeleteFilled
                    style={{
                      color: "grey",
                      fontSize: "1.5rem",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      dispatch(
                        removeWithSave({
                          productVariantId: v.productVariantId,
                          _id: v._id,
                          dispatch,
                          type: v.type,
                        })
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Divider />
          </Fragment>
        ))}
    </>
  );
};

const MobileResponsiveCart = ({
  type = "myCart",
  data,
  cartDataLoadingStatus,
}) => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.value || []);

  return (
    <>
      {cartData?.length > 0 &&
        cartData?.map((v, i) => (
          <Fragment key={i}>
            <Row>
              <Col span={18}>
                <Row>
                  <Col span={10}>
                    <img
                      alt="example"
                      style={{
                        height: "90px",
                        maxWidth: "90px",
                        marginTop: "10px",
                      }}
                      src={v.thumbImgUrl}
                    />
                  </Col>
                  <Col span={14} style={{ marginTop: "13px" }}>
                    <Space direction="vertical" size={0}>
                      <span style={{ fontSize: "13px", color: "lightgrey" }}>
                        Chemicals
                      </span>
                      <span style={{ fontSize: "16px" }}>{v.productName}</span>
                      <span style={{ fontSize: "13px", color: "lightgrey" }}>
                        ({v.productSubName})
                      </span>
                      {v.offerPercentage ? (
                        <div style={{ marginTop: 2 }}>
                          <Tag color="yellow">Disc {v.offerPercentage}%</Tag>
                        </div>
                      ) : null}
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={4}>
                <Space direction="vertical" size={0}>
                  <DeleteFilled
                    style={{
                      color: "grey",
                      fontSize: "1.2rem",
                      marginLeft: "64px",
                    }}
                    onClick={() => {
                      dispatch(
                        removeWithSave({
                          productVariantId: v.productVariantId,
                          _id: v._id,
                          dispatch,
                          type: v.type,
                        })
                      );
                    }}
                  />
                  {/* <div> */}
                  <h3 style={{ marginTop: "10px", marginBottom: "16px" }}>
                    ₹{v?.withDiscountPrice?.toFixed(2) || v.price?.toFixed(2)}
                  </h3>
                  {/* </div> */}
                  <Space direction="horizontal">
                    <Button
                      size="small"
                      onClick={() => {
                        dispatch(
                          decrementWithSave({
                            productVariantId: v.productVariantId,
                            dispatch,
                            type: v.type,
                          })
                        );
                      }}
                    >
                      -
                    </Button>
                    <Button size="small">{v?.quantity}</Button>
                    <Button
                      size="small"
                      onClick={() => {
                        dispatch(
                          incrementWithSave({
                            productVariantId: v.productVariantId,
                            dispatch,
                            type: v.type,
                          })
                        );
                      }}
                    >
                      +
                    </Button>
                  </Space>
                </Space>
              </Col>
            </Row>
            <Divider />
          </Fragment>
        ))}
    </>
  );
};
