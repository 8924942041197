import React, { useEffect, useState } from "react";
import { Breadcrumb, Divider, Row, Col, Input, message, Popconfirm, Button, Image, Collapse, Form, Typography, Avatar } from 'antd';
import { UserOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Layout, theme } from "antd";
import Side from "../../components/Side";
import service from '../../service/user';
import UploadFile from "../../components/UploadFile";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const Profile = () => {
    const [data, setData] = useState({})
    const [route, setRoute] = useState('/profile')
    const [edit, setEdit] = useState(false);
    const [fileList, setFileList] = useState([]);

    const navigate = useNavigate()

    const detailUser = () => {
        service.detailUser().then((res) => {
            setData(res.data);
            setFileList(res.data?.businessCard?.url ? [res.data?.businessCard] : [])
        })
    }
  
    useEffect(() => {
        detailUser()
    }, [])

    const handleSave = () => {
        service.updateUser(data).then((res) => {
            message.info("Profile Updated");
            setEdit(false);
            detailUser();
        }).catch(err => {
            if (typeof err.message === 'object') {
                let dt = err.message[Object.keys(err.message)[0]];
                message.info(dt);
            } else {
                message.info(err.message);
            }
        })
    }

    const handleChange = (value) => {
        setData({ ...data, ...value });
    }


    const handleDeactivate = () => {
        service.updateUser({ ...data, status: 'inactive' }).then((res) => {
            message.info(res.message);
            window.localStorage.clear(); window.location.reload();
        }).catch(err => {
            if (typeof err.message === 'object') {
                let dt = err.message[Object.keys(err.message)[0]];
                message.info(dt);
            } else {
                message.info(err.message);
            }
        })
    }


    useEffect(() => {
        handleChange({ businessCard: fileList?.[fileList?.length - 1] });
    }, [fileList]);

    const {
        token: { colorBgContainer }
    } = theme.useToken();


    const handleMenu = (key) => {
        key ? navigate(key === '/account-setting' ? '/profile' : key === '/wishlist' ? '/wishlist' : key === '/mystuff' ? '/wishlist' : '/' + key) : navigate('/profile')
        // navigate( '/'+ key) 

    }

    return (
        <>
            <Breadcrumb style={{ margin: "20px" }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Profile</Breadcrumb.Item>
            </Breadcrumb>
            <Divider />

            <Row style={{ padding: '8px' }} justify="space-around">
                <Col xs={{ span: 0 }} sm={{ span: 20 }}>
                    <Row gutter={[80]}>
                        <Col span={8}><Side /></Col>
                        <Col span={16}>
                            <Row gutter={[30, 30]}>
                                <Col span={12}>
                                    <Input type="text" size="large" disabled={!edit} onChange={e => { handleChange({ name: e.target.value }) }}
                                        value={data.name} placeholder="Name" />
                                </Col>
                                <Col span={12}>
                                    <Input type="email" size="large" disabled={!edit} onChange={e => { handleChange({ email: e.target.value }) }}
                                        value={data.email} placeholder="Email" />
                                </Col>
                                <Col span={12}>
                                    <Input type="text" size="large" disabled={!edit} onChange={e => { handleChange({ company: e.target.value }) }}
                                        value={data.company} placeholder="Company" />
                                </Col>
                                <Col span={12}>
                                    <Input type="number" size="large" disabled={!edit} onChange={e => { handleChange({ landLine: e.target.value }) }}
                                        value={data.landLine} placeholder="Landline" />
                                </Col>
                                <Col span={12}>
                                    <Input type="text" size="large" disabled={!edit} onChange={e => { handleChange({ gst: e.target.value }) }}
                                        value={data.gst} placeholder="GST Number" />
                                </Col>
                                <Col span={12}>
                                    <Input type="text" size="large" disabled={!edit} onChange={e => { handleChange({ department: e.target.value }) }}
                                        value={data?.department} placeholder="Type your department name" />
                                </Col>
                                <Col span={12}>
                                    {
                                        edit
                                            ? <UploadFile {...{ fileList, setFileList }} message="Business card" disabled={!edit} />
                                            : <Image src={fileList?.[0]?.url} />
                                    }
                                </Col>
                                <Col span={24}></Col>
                                <Col span={12}>
                                    {
                                        !edit
                                            ? <Button size="large" onClick={() => setEdit(true)}>Edit Account</Button>
                                            : <>
                                                <Button size="large" type="primary" onClick={handleSave}>Save</Button>
                                                <Button size="large" type="primary" style={{ marginLeft: 20 }} danger onClick={() => { setEdit(false) }}>Cancel</Button>
                                            </>
                                    }
                                </Col>
                                <Col span={24}></Col>
                                <Col span={12}>
                                    <Popconfirm
                                        placement="top"
                                        title="Are you sure to you want to deactivate account ?. Be sure to Deactivate Account."
                                        onConfirm={() => { handleDeactivate() }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button size="large">Deactivate Account</Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>


                {/* mobile view  */}
                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                    <Row style={{ width: '100%', background: 'transparent linear-gradient(268deg, #3E3185 0%, #774F9A 26%, #6B2F85 74%, #393185 100%) 0% 0% no-repeat padding-box' }}>
                        <Col span={6} style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                            <Avatar size={60} icon={<UserOutlined />} />
                        </Col>
                        <Col span={18}>
                            <Typography.Title level={5} style={{ color: 'white', marginTop: 8 }}>Hello</Typography.Title>
                            <Typography.Title level={4} style={{ color: 'white', marginTop: 8 }}>{data?.name}</Typography.Title>
                        </Col>
                    </Row>
                    <Collapse expandIconPosition={'end'} activeKey={['/account-setting']} accordion onChange={handleMenu}>
                        <Collapse.Panel
                            header={<div>
                                <ShoppingCartOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Order </span>
                            </div>}
                            key="/myorder">
                            <p>hello world3</p>
                        </Collapse.Panel>
                        <Collapse.Panel header={<div>
                            <UserOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>Account Setting </span>
                        </div>} key="/account-setting">
                            <Collapse bordered={false} expandIconPosition={'end'} activeKey={['/profile']} accordion onChange={(key) => {
                                navigate(key ? '/' + key : '/profile')
                            }}>
                                <Collapse.Panel header={
                                    <span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Profile Information</span>
                                } key="/profile">
                                    <Row>
                                        <Col span={24}>
                                            <Form layout="vertical">
                                                <Row gutter={[0, 0]}>
                                                    <Col span={24}>
                                                        <Form.Item label="Name" required>
                                                            <Input type="text" disabled={!edit} onChange={e => { handleChange({ name: e.target.value }) }}
                                                                value={data.name} placeholder="Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: -15 }}>
                                                        <Form.Item label="Email" required>
                                                            <Input type="email" disabled={!edit} onChange={e => { handleChange({ email: e.target.value }) }}
                                                                value={data.email} placeholder="Email" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={24} style={{ marginTop: -15 }}>
                                                        <Form.Item label="Company" required>
                                                            <Input type="text" disabled={!edit} onChange={e => { handleChange({ company: e.target.value }) }}
                                                                value={data.company} placeholder="Company" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: -15 }}>
                                                        <Form.Item label="LandLine" required>

                                                            <Input type="number" disabled={!edit} onChange={e => { handleChange({ landLine: e.target.value }) }}
                                                                value={data.landLine} placeholder="Landline" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: -15 }}>
                                                        <Form.Item label="GST Number" required>

                                                            <Input type="text" disabled={!edit} onChange={e => { handleChange({ gst: e.target.value }) }}
                                                                value={data.gst} placeholder="GST Number" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: -15 }}>
                                                        <Form.Item label="Department" required>

                                                            <Input type="text" disabled={!edit} onChange={e => { handleChange({ department: e.target.value }) }}
                                                                value={data?.department} placeholder="Type your department name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: -15 }}>
                                                        <Form.Item label="Business Card" required>

                                                            {
                                                                edit
                                                                    ? <UploadFile {...{ fileList, setFileList }} message="Business card" disabled={!edit} />
                                                                    : <Image src={fileList?.[0]?.url} />
                                                            }
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={24}></Col>
                                                    <Col span={24}>
                                                        {
                                                            !edit
                                                                ? <Button style={{ width: '100%', marginBottom: '15px' }} type="primary" onClick={() => setEdit(true)}>Edit Account</Button>
                                                                : <>
                                                                    <Button style={{ width: '100%', marginBottom: '15px' }} type="primary" onClick={handleSave}>Save</Button>
                                                                    <Button type="primary" style={{ width: '100%', marginBottom: '15px' }} danger onClick={() => { setEdit(false) }}>Cancel</Button>
                                                                </>
                                                        }
                                                        <Popconfirm
                                                            placement="top"
                                                            title="Are you sure to you want to deactivate account ?. Be sure to Deactivate Account."
                                                            onConfirm={() => { handleDeactivate() }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button style={{ width: '100%' }}>Deactivate Account</Button>
                                                        </Popconfirm>
                                                    </Col>

                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                                <Collapse.Panel
                                    header={<span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>Manage Address</span>}
                                    key="/address">
                                    <p>hello world2</p>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>
                        <Collapse.Panel header={<div>
                            <UserOutlined style={{ fontSize: '1.5rem', color: 'purple' }} /> <span style={{ marginLeft: '15px', fontSize: '18px', fontWeight: 500 }}>My Stuff </span>
                        </div>} key="/mystuff">
                            <Collapse bordered={false} expandIconPosition={'end'} accordion onChange={(key) => {
                                navigate(key ? '/' + key : '/wishlist')
                            }}>
                                <Collapse.Panel header={
                                    <span style={{ marginLeft: '60px', fontSize: '14px', fontWeight: 500 }}>My Wishlist</span>
                                } key="/wishlist">
                                    <p>hello world2</p>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>
        </>
    )
}

export default Profile;