/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Row, Col, Affix, Image } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import service from "../service/master";
import { HomeOutlined, DownOutlined } from "@ant-design/icons";
// import chemicalLogo from "../Assets/chemical-logo.jpg"
// import LifeScience from "../Assets/life-science-logo.jpg";
// import Gases from "../Assets/gas-logo.jpg";
// import LabInstrument from "../Assets/lab-instrument-logo.jpg";
// import Labware from "../Assets/lab-ware-logo.jpg";
export default function App() {
	const [current, setCurrent] = useState("mail");

	const [brandData, setBrandData] = useState([]);
	const [chemicalData, setChemicalData] = useState([]);
	const [lifeScienceData, setLifeScienceData] = useState([]);
	const [gasesData, setGasesData] = useState([]);
	const [labWareData, setLabWareData] = useState([]);
	const [labInstrumentsData, setLabInstrumentsData] = useState([]);
	const [qData, setQData] = useState({ status: true, isAll: 1 });
	const [chemicalLogo, SetChemicalLogo] = useState("")
	const [LifeScience, SetLifeScience] = useState("")
	const [Gases, SetGases] = useState("")
	const [LabInstrument, SetLabInstrument] = useState("")
	const [Labware, SetLabware] = useState("")


	const items = [
		{
			label: "",
			key: "home",
			icon: <Link to="/" className="text-white"> <span>&nbsp;</span> <HomeOutlined style={{ fontSize: "24px" }} /></Link>
		},
		{
			label: <Link to="/c/brand" className="text-white" style={{ fontSize: "26px" }}>Brands <DownOutlined /></Link>,
			key: "brand",
			children: brandData,

		},
		{
			label: <div className="category-link">

				{chemicalLogo.length > 0 ? <Image
					alt="Chemical-logo"
					preview={false}
					src={chemicalLogo}
					className="category-logo"
				// style={{ width: 74, marginBottom: '-16px' }}
				/> : null}
				<Link to="/c/chemicals" className="text-white" style={{ fontSize: "26px", color: "white" }}>Chemicals <DownOutlined /></Link>
			</div>,
			key: "chemicals",
			children: chemicalData,
		},
		{
			label:
				<div className="category-link">
					{LifeScience.length > 0 ? <Image
						alt="LifeScience-logo"
						preview={false}
						src={LifeScience}
						className="lifescience-logo"
					// style={{ width: 74, marginBottom: '-16px' }}
					/> : null}
					<Link to="/c/lifeScience" className="text-white" style={{ fontSize: "26px" }}>Life Science <DownOutlined /></Link>
				</div>,
			key: "life-science",
			children: lifeScienceData,
		},
		{
			label: <div className="category-link">
				{Gases.length > 0 ? <Image
					alt="Gas-logo"
					preview={false}
					src={Gases}
					className="gas-logo"
				// style={{ width: 74, marginBottom: '-16px' }}
				/> : null}
				<Link to="/c/gases" className="text-white" style={{ fontSize: "26px" }}>Gases <DownOutlined /></Link>
			</div>,
			key: "gases",
			children: gasesData,
		},
		{
			label:
				<div className="category-link">
					{Labware.length > 0 ? <Image
						alt="labware-logo"
						preview={false}
						src={Labware}
						className="category-logo"
					// style={{ width: 74, marginBottom: '-16px' }}
					/> : null}
					<Link to="/c/labware" className="text-white" style={{ fontSize: "26px" }}>Lab Ware <DownOutlined /></Link>
				</div>,
			key: "labware",
			children: labWareData,
		},
		{
			label:
				<div className="category-link">
					{LabInstrument.length > 0 ? <Image
						alt="labware-logo"
						preview={false}
						src={LabInstrument}
						className="labInstument-logo"
					// style={{ width: 74, marginBottom: '-16px' }}
					/> : null}
					<Link to="/c/labInstument" className="text-white" style={{ fontSize: "26px" }}>Lab Instruments <DownOutlined /></Link>
				</div>,
			key: "lab-intruments",
			children: labInstrumentsData,
		},
		{
			label: <Link to={"/quickorder"} > <div style={{
				color: "#fff!important", background: "#fff", padding: "0rem 1rem", position1: " absolute", zIndex1: "1", border: "solid #774F9A", right1: "-252px", top1: "-8px", borderRadius: "9px ", color: "#774F9A", fontWeight: 500, width: "195px", textAlign: "center", fontSize: "24px"
			}}>Quick Order</div></Link>,
			key: "quick-order",
			style: { marginLeft: '50px' }
		},
	];

	function generateChildren(input) {
		const map = {};
		const result = [];
		for (let i = 0; i < input.length; i++) {
			const item = input[i];
			map[item._id] = item;
			item.children = [];
		}
		for (let i = 0; i < input.length; i++) {
			const item = input[i];
			if (item.parentId) {
				map[item.parentId]?.children.push(item);
			} else {
				result.push(item);
			}
		}
		return result;
	}

	function generateSlug(input, prevslug = "") {
		if (input?.length) {
			for (let i = 0; i < input?.length; i++) {
				input[i].slug = prevslug + "/" + input[i].slug;
				input[i].label = <Link to={input[i].slug} className="color-inherit"><div>{input[i].label}</div></Link>
				if (input?.[i].children?.length) {
					generateSlug(input[i].children, input[i].slug);
				}
			}
		}
	}
	function list() {
		service
			.listBrands(qData)
			.then((res) => {
				// setBrandData(
				const data = res.data.map((item) => ({ _id: item._id, label: item.name, key: item._id, slug: item.slug }))
				// );
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/brand");
					setBrandData(result)

				} catch (error) {
				}
			})
			.catch((err) => { })
			.finally(() => { });

		service
			.listChemicals(qData)
			.then((res) => {
				const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug

				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/chemicals");
					setChemicalData(result)

				} catch (error) {
				}
			})
			.catch((err) => { })
			.finally(() => { });

		service
			.listLifeScience(qData)
			.then((res) => {
				const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/lifeScience");
					setLifeScienceData(result)

				} catch (error) {
				}
			})
			.catch((err) => { })
			.finally(() => { });

		service
			.listGas(qData)
			.then((res) => {
				const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/gases");
					setGasesData(result)

				} catch (error) {
				}
			})
			.catch((err) => { })
			.finally(() => { });

		service
			.listLabware(qData)
			.then((res) => {
				const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/labware");
					setLabWareData(result)

				} catch (error) {
				}
			})
			.catch((err) => { })
			.finally(() => { });

		service
			.listLabinstrument(qData)
			.then((res) => {
				const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/labInstument");
					setLabInstrumentsData(result)

				} catch (error) {
				}
			})
			.catch((err) => { })
			.finally(() => { });
	}


	function categoryImageList() {
		service.categoryImage().then(({ data }) => {
			
			data?.map((v) => {
				if (v?.name === "chemical-logo") {
					SetChemicalLogo(v?.imgUrl)
				} else if (v?.name === "life-science-logo") {
					SetLifeScience(v?.imgUrl)
				} else if (v?.name === "gas-logo") {
					SetGases(v?.imgUrl)
				} else if (v?.name === "lab-ware-logo") {
					SetLabware(v?.imgUrl)
				} else if (v?.name === "lab-instrument-logo") {
					SetLabInstrument(v?.imgUrl)
				}
			})
		})
	}


	useEffect(() => {
		list();
		categoryImageList()
	}, []);



	const onClick = (e) => {
		setCurrent(e.key);
	};
	return (
		<>
			<Affix >
				<Row>
					<Col xs={{ span: 0 }} sm={{ span: 24 }}>
						<Menu
							onClick={onClick}
							selectedKeys={[current]}
							mode="horizontal"
							items={items}
						/>
					</Col>
				</Row>
			</Affix>
		</>
	);
}
