import { Menu } from 'antd';
import { useEffect, useState } from "react";
import service from "../../service/master";
import { Link } from 'react-router-dom';
const App = () => {

  const [brandData, setBrandData] = useState([]);
  const [chemicalData, setChemicalData] = useState([]);
  const [lifeScienceData, setLifeScienceData] = useState([]);
  const [gasesData, setGasesData] = useState([]);
  const [labWareData, setLabWareData] = useState([]);
  const [labInstrumentsData, setLabInstrumentsData] = useState([]);
  const [current, setCurrent] = useState("mail");

  const items = [
    {
      label: "Brands",
      key: "brand",
      children: brandData,
    },
    {
      label: "Chemicals",
      key: "chemicals",
      children: chemicalData,
    },
    {
      label: "Life Science",
      key: "lifeScience",
      children: lifeScienceData,
    },
    {
      label: "Gases",
      key: "gases",
      children: gasesData,
    },
    {
      label: "Labware",
      key: "labware",
      children: labWareData,
    },
    {
      label: "Lab Intruments",
      key: "labIntruments",
      children: labInstrumentsData,
    },
    {
      label:( <Link to={'/quickorder'}><div>Quick Order</div></Link>),
      key: "quick-order",
      // children:""
    },
  ];
  const [qData, setQData] = useState({ status: true,isAll: 1  });

  function generateChildren(input) {
    const map = {};
    const result = [];
    for (let i = 0; i < input.length; i++) {
      const item = input[i];
      map[item.id] = item;
      item.children = [];
    }
    for (let i = 0; i < input.length; i++) {
      const item = input[i];
      if (item.parentId) {
        map[item.parentId]?.children.push(item);
      } else {
        result.push(item);
      }
    }
    return result;
  }

  
	function generateSlug(input, prevslug = "") {
		if (input?.length) {
			for (let i = 0; i < input?.length; i++) {
				input[i].slug = prevslug + "/" + input[i].slug;
				input[i].label = <Link to={input[i].slug}><div>{input[i].label}</div></Link>
				if (input?.[i].children?.length) {
					generateSlug(input[i].children, input[i].slug);
				}
			}
		}
	}

  function list() {
    service
      .listBrands(qData)
      .then((res) => {
        // setBrandData(
        //   res.data.map((item) => ({ label: item.name, key: item._id }))
        // );
        const data = 	res.data.map((item) => ({_id: item._id,parentId: item?.parentId, label: item.name, key: item._id,slug: item.slug }))
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/brand");
					setBrandData(result)

				} catch (error) {
				}
      })
      .catch((err) => { })
      .finally(() => { });

    service
      .listChemicals(qData)
      .then((res) => {
        // const data = res.data.map((item) => ({ label: item.name, key: item._id, parentId: item.parentId }));
        // setChemicalData(generateChildren(data));
        const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug

				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/chemicals");
					setChemicalData(result)
				} catch (error) {
				}
      }
      )
      .catch((err) => { })
      .finally(() => { });

    service
      .listLifeScience(qData)
      .then((res) => {
        // const data = res.data.map((item) => ({ label: item.name, key: item._id, parentId: item.parentId }));
        // setLifeScienceData(generateChildren(data));
        const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/lifescience");
					setLifeScienceData(result)

				} catch (error) {
				}
      }
      )
      .catch((err) => { })
      .finally(() => { });

    service
      .listGas(qData)
      .then((res) => {
        // const data = res.data.map((item) => ({ label: item.name, key: item._id, parentId: item.parentId }));
        // setGasesData(generateChildren(data));
        const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/gas");
					setGasesData(result)

				} catch (error) {
				}
      })
      .catch((err) => { })
      .finally(() => { });

    service
      .listLabware(qData)
      .then((res) => {
        // const data = res.data.map((item) => ({ label: item.name, key: item._id, parentId: item.parentId }));
        // setLabWareData(generateChildren(data));
        const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/labware");
					setLabWareData(result)

				} catch (error) {
				}

      })
      .catch((err) => { })
      .finally(() => { });

    service
      .listLabinstrument(qData)
      .then((res) => {
        // const data = res.data.map((item) => ({ label: item.name, key: item._id, parentId: item.parentId }));
        // setLabInstrumentsData(generateChildren(data));
        const data = res.data.map((item) => ({
					_id: item._id,
					label: item.name,
					key: item._id,
					parentId: item.parentId,
					slug: item.slug
				}));
				const result = generateChildren(data);
				try {
					generateSlug(result, "c/labinstruments");
					setLabInstrumentsData(result)

				} catch (error) {
				}
      })
      .catch((err) => { })
      .finally(() => { });
  }

  useEffect(() => {
    list();
  }, []);
  const onClick = (e) => {
    setCurrent(e.key);
  };
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      style={{
        width: 256,
      }}
      // defaultSelectedKeys={['1']}
      // defaultOpenKeys={['sub1']}
      mode="inline"
      items={items}
    />
  );
};
export default App;