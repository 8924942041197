import { Button } from "antd";


const PDF_FILE_URL="https://amplieto.com/EUD Format.pdf"

const EodFormat=()=>{
const downloadFileAtUrl=(url)=>{
    fetch(url)
    .then((response)=>response.blob())
    .then((blob)=>{
          const blobURL=window.URL.createObjectURL(new Blob([blob]))
          const fileName=url.split("/").pop()
          const aTag=document.createElement("a");
          aTag.href=blobURL;
          aTag.setAttribute("download",fileName);
          document.body.appendChild(aTag)
          aTag.click();
          aTag.remove()
    })

}


    return<>
    <Button onClick={()=>downloadFileAtUrl(PDF_FILE_URL)}>Download Declaration</Button>
    </>
}


export default EodFormat