import axios from "../config.js/axios";
export default class service {

    static baseURL = 'offer';

    static list(data) {
        return axios.get(this.baseURL + '/list', { params: data });
    }
    static offerProduct(slug) {
        return axios.get('offer/details/' + slug);
    }
}