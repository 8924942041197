import axios from "../config.js/axios";
export default class product {
  static list(data) {
    return axios.get("cart/list", { params: data });
  }
  static save(data) {
    return axios.post("cart/save", data);
  }


  static list(data) {
    return axios.get('cart/list', { params: data });
  }
  static save(data) {
    return axios.post('cart/save', data);
  }

  static calculatePrice(data) {
    return axios.get("cart/calculate-price", { params: data });
  }

  static remove(data) {
    return axios.post('cart/remove', data);
  }
}
