import axios from "../config.js/axios";
export default class order {

    static placeOrder(data) {
        return axios.post('order/create', data);
    }
    static successOrder(data) {
        return axios.post('order/success', data);
    }
    static listOrder(data) {
        return axios.get('order/list', { params: data });
    }
    static listOrderHistory() {
        return axios.get('order/history');
    }
    static orderedProdVarientList() {
        return axios.get('order/ordered-prodvarient');
    }
    static detailOrder(data) {
        return axios.get('order/orderDetail/list', { params: data });
    }
}