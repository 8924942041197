/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { Layout, Space, Col, Row, Card, Divider, Input, Button, notification, Spin, Image, Breadcrumb } from 'antd';
import { FacebookFilled, HeartOutlined, InstagramOutlined, ShareAltOutlined, ShoppingCartOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';
import { Link, useParams } from "react-router-dom";
import blogServices from '../service/blog'
import service from "../service/setting";
import serviceSubscribe from "../service/subscribe";
import moment from "moment";
import { TwitterShareButton } from "react-share";
const { Header } = Layout;
const config = {
  amitLabUrl: "https://amplieto.com"
}

const headerStyle = {
  color: '#391085',
  fontSize: "xx-large",
  fontWeight: "700",
  height: 120,
  paddingInline: 50,
  lineHeight: '120px',
  backgroundColor: '#f0f0f0',
};

const NewsEvents = () => {
  const [media, setMedia] = useState({})
  const [subscribeData, setSubscribeData] = useState("")
  const [data, setData] = useState({});
  const [blogList, setBlogList] = useState([]);
  const { slug } = useParams();
  const [ajxRequesting, setAxRequesting] = useState(false)
  const [loading, setLoading] = useState(false)

  const getDetails = () => {
    setAxRequesting(true)
    blogServices.detailBlog(slug).then(res => {
      setData(res.data)
    }).catch(err => {
    }).finally(() => {
      setAxRequesting(false)
    })
  }

  const listBlog = () => {

    blogServices.listBlog({ page: 1, limit: 4, }).then(res => {
      setBlogList(res.data?.map(v => ({ ...v, key: v._id })));
    }).catch(err => {
    })
  }

  const fetchMedia = () => {
    service.listSetting().then((res) => {
      let response = res.data
      let mediaData = {}
      for (let i = 0; i < response.length; i++) {
        if (response[i].type === "instagramUrl" || "facebookUrl" || "linkeinUrl" || "twitterUrl") {
          mediaData[response[i].type] = response[i].data
        }
      }
      setMedia(mediaData)
    })
  }

  const subscribe = () => {
    setLoading(true)
    let obj = {
      email: subscribeData
    }
    serviceSubscribe.save(obj).then((res) => {
      notification.success({
        message: res.message
      })
      setSubscribeData("")
    }).catch(err => {

      notification.error({
        message: err.errors[0].msg,

      })

    }).finally(() => {
      setLoading(false)
    })

  }
  useEffect(() => {
    getDetails()
    listBlog()
    fetchMedia()
  }, [slug])
  return (
    <>
      <Row style={{ paddingLeft: '10px', paddingTop: '10px' }}>
        <Breadcrumb style={{ marginLeft: "10px" }}>
          <Breadcrumb.Item>
            <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              Blogs
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={"/"} style={{ fontSize: "500", fontWeight: "bolder" }}>
              {slug}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{ marginBottom: '0px', marginTop: '15px' }} />
      </Row>
      <Col xs={{ span: 24 }} sm={{ span: 0 }} >

        <Layout>
          <Header style={{
            color: '#391085',
            fontSize: "22px",
            fontWeight: "700",
            height: 70,
            paddingInline: 20,
            lineHeight: '70px',
            backgroundColor: '#f0f0f0',
          }}>
            <ShoppingCartOutlined />
            Blogs
          </Header>
        </Layout>
      </Col>
      <Col xs={{ span: 0 }} sm={{ span: 24 }} style={{ width: '100%' }} >
        <Layout>
          <Header style={headerStyle}>
            <ShoppingCartOutlined />
            Blogs
          </Header>
        </Layout>
      </Col>


      {/*  mobile view */}
      <Row xs={{ span: 24 }} sm={{ span: 0 }} >
        <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: '20px' }} >
          <Row justify={'space-between'} style={{ padding: '10px' }}>
            <space style={{ fontSize: '20px', color: 'purple', fontWeight: 600, }}>{moment(data?.date).format("dddd, MMMM Do YYYY")}</space>
            <Space size={20}>
              <TwitterShareButton title={data?.description} url={config.amitLabUrl}>
                <span><ShareAltOutlined style={{ fontSize: '1.5rem', fontWeight: 600, color: 'purple' }} /></span>
              </TwitterShareButton>
              {/* <span><HeartOutlined style={{ fontSize: '1.5rem', fontWeight: 600, color: 'purple' }} /></span> */}
            </Space>
          </Row>
          <Spin spinning={ajxRequesting}>
            <img src={data.img?.url} alt="loading..." style={{ width: '100%', borderRadius: '15px',objectFit:"cover" }} />
          </Spin>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: '20px' }} >
          <Space direction="vertical" size={1}>
            <span
              style={{
                fontWeight: 700,
                fontSize: "22px",
                borderLeft: "solid 5px purple",
                paddingLeft: "9px",
              }}
            >
              {data.title}
            </span>
            <span
              style={{
                fontWeight: 400,
                fontSize: "12px",
                color: "grey",
                paddingLeft: '18px'
              }}
            >
              {moment(data?.date).format("MMM L")}
            </span>
          </Space>
          <div style={{ fontWeight: 400, fontSize: "14px", marginTop: '20px' }}>

            <div style={{ fontWeight: 400, color: 'grey' }} className="html-text-content" dangerouslySetInnerHTML={{ __html: data?.description }} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 0 }} style={{ padding: '20px' }} >
          <span style={{ fontSize: '24px' }}>Recent Blog</span>
          <div style={{ marginTop: '25px' }}>
            {
              blogList?.map((val, i) => (
                <Fragment key={i} >
                  <div style={{ display: 'flex' }}>
                    <img style={{ width: '100px', height: '100px', flex: 1, borderRadius: '8px' }} src={val?.img} alt="loading.." srcset="" />
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3, paddingLeft: "14px" }}>
                      <Link to={"/blog/" + val.slug}>
                        <span style={{ flex: 1, fontWeight: 500, fontSize: '16px', }}>{val.title}</span>
                        <div style={{ fontWeight: 400, fontSize: "13px", marginTop: '10px' }}>
                          {val.description.substring(0, 100)}...
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}> <span style={{ fontWeight: 500, fontSize: '12px', color: 'grey' }}>{moment(val?.date).format("MMM L")}</span> </div>
                      </Link>
                    </div>
                  </div>
                  <Divider />
                </Fragment>
              ))
            }
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 0 }}>
          <Card style={{ background: 'rgb(245 245 245)', }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <span style={{ fontSize: '20px', fontWeight: 500 }}>YOUR DAILY UPDATES</span>
              <span style={{ fontSize: '16px', fontWeight: 400, color: 'grey', padding: '20px', }}>Subscribe now . We'll make sure you never <br /> miss a thing</span>
            </div>
            <div style={{ padding: '20px' }}>
              <Input style={{ height: '45px', background: 'white', marginBottom: '15px' }} value={subscribeData} onChange={(e) => setSubscribeData(e.target.value)} placeholder="Enter Your email.." />

              <Button style={{ color: 'white', width: '100%', height: '45px', background: "#e84d15" }} loading={loading} onClick={subscribe}>
                Subscribe
              </Button>

            </div>

          </Card>

          <div style={{ marginTop: '50px' }}>
            <Row gutter={15} style={{ marginBottom: '15px' }}>
              <Col span={12}>
                <Link to={media.facebookUrl} target={"_blank"}>
                  <Button icon={<FacebookFilled style={{ fontSize: '1.4rem' }} />} style={{ fontSize: '15px', fontWeight: 'bolder', color: 'white', width: '100%', height: '45px', background: "#3059b0" }}>
                    Fans
                  </Button>
                </Link>
              </Col>
              <Col span={12} >
                <Link to={media.linkeinUrl} target={"_blank"}>
                  <Button style={{ fontSize: '15px', fontWeight: 'bolder', color: 'white', width: '100%', height: '45px', background: "#e52730" }} icon={<LinkedinOutlined style={{ fontSize: '1.4rem' }} />}>
                    Fans
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Link to={media.twitterUrl} target={"_blank"}>
                  <Button to={media.twitterUrl} icon={<TwitterOutlined style={{ fontSize: '1.4rem' }} />} style={{ fontSize: '15px', fontWeight: 'bolder', color: 'white', width: '100%', height: '45px', background: "#55acef" }}>
                    Followers
                  </Button>
                </Link>
              </Col>
              <Col span={12}>
                <Link to={media.instagramUrl} target={"_blank"}>
                  <Button to={media.instagramUrl} icon={<InstagramOutlined style={{ fontSize: '1.4rem' }} />} style={{ color: 'white', fontSize: '15px', fontWeight: 'bolder', width: '100%', height: '45px', background: "#f7793a" }}>
                    Followers
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>

        </Col>
      </Row>
      <Row xs={{ span: 0 }} sm={{ span: 24 }} style={{ padding: '40px 60px 20px 60px' }}>
        <Col xs={{ span: 0 }} sm={{ span: 17 }} style={{ paddingRight: '50px' }}>
          <Row justify={'space-between'} style={{ padding: '0px 0px 10px 10px' }}>
            <space style={{ fontSize: '25px', color: 'purple', fontWeight: 600 }}>{moment(data?.date).format("dddd, MMMM Do YYYY")}</space>
            <Space size={20}>
              <TwitterShareButton title={data?.description} url={config.amitLabUrl}>
                <span><ShareAltOutlined style={{ fontSize: '1.8rem', fontWeight: 600, color: 'purple' }} /></span>
              </TwitterShareButton>
              {/* <span><HeartOutlined style={{ fontSize: '1.8rem', fontWeight: 600, color: 'purple' }} /></span> */}
            </Space>
          </Row>
          <Spin spinning={ajxRequesting}>
            <img src={data.img?.url} alt="loading..." style={{ width: '100%', height: '650px', borderRadius: '15px',objectFit:"fill"}} />
          </Spin>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 7 }}>
          <span style={{ fontSize: '30px' }}>Recent Blog</span>
          <div style={{ marginTop: '25px' }}>
            {
              blogList?.map((val, i) => (
                <Fragment key={i} >
                  <div style={{ display: 'flex' }}>
                    <img style={{ width: '120px', height: '120px', flex: 1, borderRadius: '8px' }} src={val?.img} alt="loading.." srcset="" />
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3, paddingLeft: "14px" }}>
                      <Link to={"/blog/" + val.slug}>
                        <span style={{ flex: 1, fontWeight: 500, fontSize: '16px', }}>{val.title}</span>
                        <div style={{ marginLeft: '140px', fontWeight: 500, fontSize: '16px', color: 'grey' }}>{moment(val?.date).format("MMM L")}</div>
                      </Link>
                    </div>
                  </div>
                  <Divider />
                </Fragment>
              ))
            }
          </div>
        </Col>
      </Row>
      {/* web view */}
      <Row xs={{ span: 0 }} sm={{ span: 24 }} style={{ paddingRight: '60px', marginTop: 0, paddingLeft: '50px', paddingBottom: '20px' }} gutter={[5]}>
        <Col xs={{ span: 0 }} sm={{ span: 17 }} >
          <Card style={{ border: 'none' }}>
            <Space direction="vertical" size={30}>
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{data?.title}</span>
              <span style={{ fontSize: '16px', color: 'grey' }}>
                <div style={{ fontWeight: 600, color: 'grey' }} className="html-text-content" dangerouslySetInnerHTML={{ __html: data?.description }} />
              </span>
            </Space>
          </Card>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 7 }} >
          <Card style={{ background: 'rgb(245 245 245)', }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <span style={{ fontSize: '20px', fontWeight: 500 }}>YOUR DAILY UPDATES</span>
              <span style={{ fontSize: '16px', fontWeight: 400, color: 'grey', padding: '20px', }}>Subscribe now . We'll make sure you never <br /> miss a thing</span>
            </div>
            <div style={{ padding: '20px' }}>
              <Input style={{ height: '45px', background: 'white', marginBottom: '15px' }} value={subscribeData} onChange={(e) => setSubscribeData(e.target.value)} placeholder="Enter Your email.." />
              <Button style={{ color: 'white', width: '100%', height: '45px', background: "#e84d15" }} loading={loading} onClick={subscribe}>
                Subscribe
              </Button>

            </div>

          </Card>

          <div style={{ marginTop: '50px' }}>
            <Row gutter={15} style={{ marginBottom: '15px' }}>
              <Col span={12}>
                <Link to={media.facebookUrl} target={"_blank"}>
                  <Button icon={<FacebookFilled style={{ fontSize: '1.4rem' }} />} style={{ fontSize: '15px', fontWeight: 'bolder', color: 'white', width: '100%', height: '45px', background: "#3059b0" }}>
                    Fans
                  </Button>
                </Link>
              </Col>
              <Col span={12} >
                <Link to={media.linkeinUrl} target={"_blank"}>
                  <Button style={{ fontSize: '15px', fontWeight: 'bolder', color: 'white', width: '100%', height: '45px', background: "#e52730" }} icon={<LinkedinOutlined style={{ fontSize: '1.4rem' }} />}>
                    Fans
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Link to={media.twitterUrl} target={"_blank"}>
                  <Button to={media.twitterUrl} icon={<TwitterOutlined style={{ fontSize: '1.4rem' }} />} style={{ fontSize: '15px', fontWeight: 'bolder', color: 'white', width: '100%', height: '45px', background: "#55acef" }}>
                    Followers
                  </Button>
                </Link>
              </Col>
              <Col span={12}>
                <Link to={media.instagramUrl} target={"_blank"}>
                  <Button to={media.instagramUrl} icon={<InstagramOutlined style={{ fontSize: '1.4rem' }} />} style={{ color: 'white', fontSize: '15px', fontWeight: 'bolder', width: '100%', height: '45px', background: "#f7793a" }}>
                    Followers
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>

        </Col>
      </Row>

    </>
  )
};

export default NewsEvents;