import axios from "../config.js/axios";

export default class master {

    static listBrands(data = {}) {
        return axios.get('../admin/master/brand/list', { params: { ...data, status: 1 } });
    }

    static listChemicals(data = {}) {
        return axios.get('../admin/master/chemical/list', { params: { ...data, status: 1 } });
    }

    static listLifeScience(data = {}) {
        return axios.get('../admin/master/life-science/list', { params: { ...data, status: 1 } });
    }

    static listGas(data = {}) {
        return axios.get('../admin/master/gas/list', { params: { ...data, status: 1 } });
    }

    static listLabware(data = {}) {
        return axios.get('../admin/master/labware/list', { params: { ...data, status: 1 } });
    }

    static listLabinstrument(data = {}) {
        return axios.get('../admin/master/lab-instrument/list', { params: { ...data, status: 1 } });
    }

    static categoryImage() {
        return axios.get('/category-image/list');
    }
}