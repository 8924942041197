import orderService from "../service/order";
export default async function displayRazorpay(data, setPaymentDetails) {
    const options = {
        key: data.key,
        amount: data.amount,
        currency: data.currency,
        name: data.name,
        image: { logo: data.image },
        order_id: data.order_id,
        handler: async function (response) {
            const successData = {
                _id: data._id,
                orderId: response.order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                type: localStorage.getItem('cartType')
            };
            orderService.successOrder(successData).then(res => {
                if (setPaymentDetails) {
                    setPaymentDetails({ ...res.data });
                }
                window.localStorage.removeItem("coupon");
            }).catch(err => {
                if (setPaymentDetails) {
                    setPaymentDetails({ failed: true });
                }
            })
        },
        prefill: data.prefill,
        theme: {
            color: '#61dafb',
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}