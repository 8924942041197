import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import cart from "./cart";
import user from "./user";
import blog from "./blog";
import wishlist from "./wishlist";


export const store = configureStore(
  {
    reducer: {
      cart: cart,
      user: user,
      blog: blog,
      wishlist: wishlist
    },
  },
  applyMiddleware(thunk)
);
