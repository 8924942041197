/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter } from 'react-router-dom';
import { message } from "antd";
import { useDispatch } from 'react-redux';
import { changeDetails } from './redux/user';
import { fetchWishlist } from './redux/wishlist';
import { ConfigProvider } from 'antd';
import Layout from './layout';
import './App.css';
import { useEffect } from 'react';
import userService from './service/user';
// import wishlist from './service/wishlist';

function App() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchWishlist());
	}, []);
	useEffect(() => {
		userService.validateToken().then(res => {
			message.success(res.message);
			dispatch(changeDetails({ isLoggedin: true, name: res.data?.user?.name }));
		}).catch(err => {
			window.localStorage.clear();
			dispatch(changeDetails({ isLoggedin: false, name: "" }));
		}).finally(() => { });
	}, [])

	return (
		<>
			<ConfigProvider theme={{
				token: {
					colorPrimary: "#774F99",
					borderRadius: '0px',
				},
				components: {
					Menu: {
						colorItemTextHoverHorizontal: "red",
						colorItemText: "black",
						colorItemTextHover: "white",
						colorFillContent: "rgba(0, 0, 0, 0.06)",
						colorItemBg: "transparent linear-gradient(268deg, #3E3185 0%, #774F9A 26%, #6B2F85 74%, #393185 100%) 0% 0% no-repeat padding-box",
						colorItemTextSelectedHorizontal: "white",
						radiusItem: 0,
						radiusSubMenuItem: 0,
						colorGroupTitle: "white",
						colorItemBgHover: "#3E3185",
						colorSubItemBg: "rgba(0, 0, 0, 0.02)",
						colorItemBgSelected: "#3E3185",
						colorItemTextSelected: "white",
						colorItemTextHover: "white",
						controlHeightLG: 50
					}
				}
			}} >
				<BrowserRouter basename="/">
					<Layout />

				</BrowserRouter>
			</ConfigProvider>
		</>
	);
}

export default App;
