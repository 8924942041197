/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Table, Tag, Image, Row, Col, Button, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCart, removeWithSave, incrementWithSave, decrementWithSave } from "../redux/cart";
import { Link } from "react-router-dom";

const DropDownCart = ({ type }) => {
	const dispatch = useDispatch();
	const cartData = useSelector((state) => state.cart.value || []);

	useEffect(() => {
		dispatch(fetchCart({ type }));
	}, [type]);

	const columns = [
		{
			dataIndex: "name",
			key: "name",
			render: (text, row) => {
				return (
					<>
						<div style={{ display: "flex" }}>
							<Row style={{width:"380px"}}>
								<Col span={8}>
									<Image width={100} src={row?.thumbImgUrl} preview={false} />
								</Col>
								<Col span={16}>
									<Typography.Title
										level={5}
										style={{ marginTop: 0, marginBottom: 0 }}
									>
										{row?.productName}
									</Typography.Title>
									{row?.productSubName ? <Typography.Text type="secondary">
										({row?.productSubName})
									</Typography.Text> : null}

									<Row gutter={[12, 12]}>
										<Col span={24}>
											<Space size={[0, 8]} wrap>
												{row?.offerPercentage && <Tag color="green" style={{ marginTop: ".5rem" }}>
													{row?.offerPercentage} % Off
												</Tag>}
												{row?.gst && <Tag color="green" style={{ marginTop: ".5rem" }}>
													{row?.gst} % GST
												</Tag>}
											</Space>
										</Col>
										<Col>
											<Button onClick={() => {
												dispatch(
													decrementWithSave({
														productVariantId: row.productVariantId,
														dispatch,
														type: row.type
													})
												);
											}}>
												-
											</Button>
										</Col>
										<Col>
											<Button>
												{cartData?.find((v) => v.productVariantId === row.productVariantId)?.quantity || 0}
											</Button>
										</Col>
										<Col>
											<Button onClick={() => {
												dispatch(
													incrementWithSave({
														productVariantId: row.productVariantId,
														dispatch,
														type: row.type
													})
												);
											}}>
												+
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row
								justify={"end"}
								gutter={[0, 44]}
								style={{ textAlignLast: "end" }}
							>
								<Col span={24}>
									<Button type="text" icon={<DeleteOutlined />}
										onClick={() => {
											dispatch(
												removeWithSave({
													productVariantId: row.productVariantId,
													_id: row._id,
													dispatch,
													type: row.type
												})
											);
										}} />
								</Col>
								<Col span={24}>
									<p style={{ fontWeight: "600" }}>₹ {row?.withDiscountPrice?.toFixed(2) || row.price?.toFixed(2)}</p>
								</Col>
							</Row>
						</div>
					</>
				);
			},
		},
	];


	return (
		<>
			<Table pagination={false} columns={columns} dataSource={cartData?.filter(v=> v?.productName)} />
			<Row gutter={[16, 8]} style={{marginTop: 20}}>
				<Col span={12}>
					<Button size="large" block>
						Get Quote
					</Button>
				</Col>
				<Col span={12}>
				<Link to={"/ShoppingCart"} style={{color: 'white'}}>
					<Button size="large" className="app-gradient" block style={{color: 'white'}}>
						
							Checkout
					</Button>
						</Link>
				</Col>
			</Row>
		</>
	);
};

export default DropDownCart;
