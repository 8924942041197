import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoggedin: false,
        name: '',
    },
    reducers: {
        loggedIn: (state) => {
            state.isLoggedin = true;
        },
        logout: (state) => {
            state.isLoggedin = false;
        },
        changeName: (state, action) => {
            state.name += action.payload;
        },
        changeDetails: (state, action) => {
            state.name = action.payload.name || state.name;
            state.isLoggedin = action.payload.isLoggedin || state.isLoggedin;
        }
    }
});

export const { loggedIn, logout, changeName, changeDetails } = userSlice.actions;

export default userSlice.reducer;